import * as React from 'react';
import './infohub.css'
import Routes from './routes';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const theme = createMuiTheme({
    typography:{
        fontFamily: [
            "-apple-system", 
            "BlinkMacSystemFont", 
            "Segoe UI", 
            "Roboto", 
            "Oxygen", 
            "Ubuntu", 
            "Cantarell", 
            'Open Sans', 
            'Helvetica Neue',
            "sans-serif"
        ].join(",")
    }
})

export default () => (
    <ThemeProvider theme={theme}>
        <Routes />      
    </ThemeProvider>
);
