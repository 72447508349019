import React, { Component } from 'react';
import { FormGroup, Label, Table, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/ai";

class ReportGroup extends Component {

    state = {
        reportGroupId: 0,
        reportGroupName: '',
        reportGroupDescription: '',
        reportGroups: [],
        reportGroupOrder: 0,
        componentDidUpdateCheck: 0,
        saveUpdateDelete: 'Add',
        modal: false
    }

    resetValues = () => {
        this.setState({ reportGroupId: 0 });
        this.setState({ reportGroupName: '' });
        this.setState({ reportGroupDescription: '' });
        this.setState({ reportGroupOrder: 0 });
        this.setState({ componentDidUpdateCheck: 0 });
        this.setState({ saveUpdateDelete: 'Add' });
    }

    handleSubmit = () => {

        const data = {
            reportGroupName: this.state.reportGroupName,
            reportGroupDescription: this.state.reportGroupDescription,
            reportGroupOrder: parseInt(this.state.reportGroupOrder)
        };

        if (this.state.saveUpdateDelete === 'Add') {

            axios.post('/api/reportgroups/add', data)
                .then(response => {                  
                    this.setState({ componentDidUpdateCheck: 1 });
                    this.toggle();
                    NotificationManager.success('Saved Successfully!', '', 2000);
                })
                .catch(error => {
                    console.log(error);
                    NotificationManager.error('An error occured!', '', 2000);
                })
        }

        else if (this.state.saveUpdateDelete === 'Update') {

            axios.post('/api/reportgroups/update/' + this.state.reportGroupId, data)
                .then(response => {
                    this.setState({ componentDidUpdateCheck: 1 });
                    this.toggle();
                    NotificationManager.success('Updated Successfully!', '', 2000);
                })
                .catch(error => {
                    console.log(error);
                    NotificationManager.error('An error occured!', '', 2000);
                })
        }
        else if (this.state.saveUpdateDelete === 'Delete') {

            axios.post('/api/reportgroups/delete/' + this.state.reportGroupId)
                .then(response => {
                    this.setState({ componentDidUpdateCheck: 1 });
                    this.toggle();
                    NotificationManager.success('Deleted Successfully!', '', 2000);
                })
                .catch(error => {
                    console.log(error);
                    NotificationManager.error('An error occured!', '', 2000);
                })
        }
    }

    componentDidMount() {
        axios.get('/api/reportgroups')
            .then(response => {
                this.setState({ reportGroups: response.data });
            });
    }

    componentDidUpdate() {
        if (this.state.componentDidUpdateCheck > 0) {
            axios.get('/api/reportgroups')
                .then(response => {
                    this.setState({ reportGroups: response.data });
                });

            this.setState({ componentDidUpdateCheck: 0 });
        }
    }

    getReportGroup = (reportGroup, action) => {
        this.resetValues();
        this.toggle();
        this.setState({ reportGroupId: reportGroup.reportGroupId })
        this.setState({ reportGroupName: reportGroup.reportGroupName });
        this.setState({ reportGroupDescription: reportGroup.reportGroupDescription });
        this.setState({ reportGroupOrder: reportGroup.reportGroupOrder });
        this.setState({ saveUpdateDelete: action });
    }


    toggle = () => {
        this.setState({ modal: !this.state.modal });
    }

    render() {
        const reportGroupsReturned = this.state.reportGroups.map(reportGroup => {
            return <tr key={reportGroup.reportGroupId}>
                <td>{reportGroup.reportGroupName}</td>
                <td>{reportGroup.reportGroupDescription}</td>
                <td>{reportGroup.reportGroupOrder}</td>
                <td><NavLink to={"/reportgroup"} onClick={() => this.getReportGroup(reportGroup, "Update")} ><FaRegEdit /> </NavLink></td>
                <td><NavLink to={"/reportgroup"} onClick={() => this.getReportGroup(reportGroup, "Delete")} ><FaRegTrashAlt /> </NavLink></td>
            </tr>;

        });

        let classNameHeader = 'color';
        let classNameBtn = 'btn btn-infohub w-100';
        if (this.state.saveUpdateDelete === "Delete") {
            classNameHeader = 'deleteColor';
            classNameBtn = 'btn btn-danger w-100';
        }

        return (
            <div>

                <Modal isOpen={this.state.modal} toggle={this.toggle} >
                    <ModalHeader toggle={this.toggle} className={classNameHeader} >{this.state.saveUpdateDelete + " Report Group"}</ModalHeader>
                    <ModalBody>
                        <AvForm onValidSubmit={this.handleSubmit}>
                            <div className="row">
                                <div className="col-md-12">
                                    <FormGroup>
                                        <Label className="color">Report group name:</Label>
                                        <AvField
                                            name="reportGroupName"
                                            type="text"
                                            className="form-control-sm form-control"
                                            placeholder="Report group name"
                                            value={this.state.reportGroupName}
                                            onChange={(e) => this.setState({ reportGroupName: e.target.value })}
                                            required
                                            errorMessage="Report group name is required" />

                                    </FormGroup>

                                    <FormGroup>
                                        <Label className="color" htmlFor="last_name">Report group description</Label>
                                        <AvField
                                            name="reportGroupDescription"
                                            type="textarea"
                                            className="form-control-sm form-control"
                                            placeholder="Report group description"
                                            value={this.state.reportGroupDescription}
                                            onChange={(e) => this.setState({ reportGroupDescription: e.target.value })}
                                            required
                                            errorMessage="Report group description is required" />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label className="color" htmlFor="last_name">Report group order</Label>
                                        <AvField
                                            name="reportGroupOrder"
                                            type="number"
                                            className="form-control-sm form-control"
                                            placeholder="Report group order"
                                            value={this.state.reportGroupOrder}
                                            onChange={(e) => this.setState({ reportGroupOrder: e.target.value })}
                                            required
                                            errorMessage="Report group order is required" />
                                    </FormGroup>

                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-6">
                                    <button type="submit" className={classNameBtn} >{this.state.saveUpdateDelete}</button>
                                </div>
                                <div className="col-md-6">
                                    <button type="reset" className="btn btn-secondary w-100" onClick={this.toggle} >Cancel</button>
                                </div>

                            </div>
                        </AvForm>
                    </ModalBody>

                </Modal>


                <div className="row">
                    <div className="col-md-10"></div>
                    <div>
                        <button
                            type="submit"
                            style={{
                                backgroundColor: "transparent",
                                outline: "none",
                                border: "none"
                            }}
                            onClick={() => { this.resetValues(); this.toggle(); }} >
                            <AiOutlinePlusCircle className="color" style={{ fontSize: "45px" }} />
                        </button>
                    </div>
                    {/*<div>
                        <button type="submit" className="btn btn-infohub w-100" onClick={() => { this.resetValues(); this.toggle(); }} >Add Report Group</button>
                    </div>*/}
                </div>

                <div className="row">
                    <Table striped>
                        <thead>
                            <tr>
                                <th className="color">Report Group Name</th>
                                <th className="color">Report Group Description</th>
                                <th className="color">Report Group Order</th>
                                <th className="color"></th>
                                <th className="color"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {reportGroupsReturned}

                        </tbody>
                    </Table>
                </div>

            </div>
        )
    }
}

export default ReportGroup;