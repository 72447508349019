export default {
    title: "CHISA WEB",
    nodes: [
        {
            text: "CHISA",
            fx: -600,
            fy: -750,
            note: "Something about CHISA",
            url: ""
        },
        {
            text: "TESTING",
           // url: "/charts/hts/1",
            fx: -600,
            fy: -1000,
            note: "HTS dashboard: Monitors HIV testing and positivity by geographical region, age and over time. It is a service uptake measure that only accounts for the clients who take up a HIV test and their results are known."
        },
        {
            text: "TREATMENT",
            url: "",
            fx: -200,
            fy: -900,
            note: "Something about Prevention",
        },

        {
            text: "PREVENTION",
            note: "",
            url: "",
            fx: -200,
            fy: -550,
            /*nodes: [
                {
                    text: "Report 1",
                    url: "",
                    *//*fx: 83.4768002282915,
                    fy: -96.57462866512333,*//*
                    nodes: [],
                    color: "#02642e"
                },
                {
                    text: "Report 2",
                    url: "",
                    *//*fx: 83.4768002282915,
                    fy: -62.57462866512333,*//*
                    nodes: [],
                    //category: "free book",
                    color: "#02642e"
                },
                {
                    text: "Report 3",
                    url: "",
                    *//*fx: 83.4768002282915,
                    fy: -22.574628665123328,*//*
                    nodes: [],
                    //category: "free book",
                    color: "#02642e"
                }
            ]*/
        },
        {
            text: "RETENTION",
            url: "",
            fx: -1000,
            fy: -550,
            nodes: [],
            //category: "wiki"
        },
        
        {
            text: "Viral Suppression",
            url: "",
            fx: -1000,
            fy: -900,
            nodes: []
        },
        {
            text: "HTS Trends",
            url: "/charts/hts/1",
            fx: -400,
            fy: -1200,
            note: "Something about HTS Trends",
            /*nodes: [
                {
                    text: "Report 1",
                    url: "/charts/hts/1",
                    *//*fx: 291.3247731601375,
                    fy: -546.2370078674815,*//*
                    nodes: [],
                    //category: "github",
                    color: "#f16a73"
                },
                {
                    text: "Report 2",
                    url: "",
                    *//*fx: 291.3247731601375,
                    fy: -506.23700786748157,*//*
                    nodes: [],
                    //category: "github",
                    color: "#f16a73"
                }
            ]*/
        },

        /*{
            text: "HTS Forecast",
            url: "",
            fx: -300,
            fy: -1050,
            note: "Something about HTS Forecast",
            
        },*/

        {
            text: "ART Linkage",
            url: "",
            fx: 200,
            fy: -1050,
            note: "Something about ART Initiation",
            /*nodes: [
                {
                    text: "Report 1",
                    url: "",
                    *//*fx: 291.3247731601375,
                    fy: -546.2370078674815,*//*
                    nodes: [],
                    //category: "github",
                    color: "#CDC884"
                },
                {
                    text: "Report 2",
                    url: "",
                    *//*fx: 291.3247731601375,
                    fy: -506.23700786748157,*//*
                    nodes: [],
                    //category: "github",
                    color: "#CDC884"
                }
            ]*/
        },

        {
            text: "TROA",
            url: "",
            fx: 200,
            fy: -750,
            note: "Something about TROA",
            /*nodes: [
                {
                    text: "Report 1",
                    url: "",
                    *//*fx: 291.3247731601375,
                    fy: -546.2370078674815,*//*
                    nodes: [],
                    //category: "github",
                    color: "#CDC884"
                },
                {
                    text: "Report 2",
                    url: "",
                    *//*fx: 291.3247731601375,
                    fy: -506.23700786748157,*//*
                    nodes: [],
                    //category: "github",
                    color: "#CDC884"
                }
            ]*/
        },

        {
            text: "Cohort Analysis",
            url: "",
            fx: -1400,
            fy: -550,
            note: "Something about Cohort Analysis",
            /*nodes: [
                {
                    text: "Report 1",
                    url: "",
                    *//*fx: 291.3247731601375,
                    fy: -546.2370078674815,*//*
                    nodes: [],
                    //category: "github",
                    color: "#FFB7AE"
                },
                {
                    text: "Report 2",
                    url: "",
                    *//*fx: 291.3247731601375,
                    fy: -506.23700786748157,*//*
                    nodes: [],
                    //category: "github",
                    color: "#FFB7AE"
                }
            ]*/
        },
        {
            text: "VL Monitoring",
            url: "",
            fx: -1400,
            fy: -900,
            note: "Something about VL Uptake",
           /* nodes: [
                {
                    text: "Report 1",
                    url: "",
                    *//*fx: 291.3247731601375,
                    fy: -546.2370078674815,*//*
                    nodes: [],
                    //category: "github",
                    color: "#70C8BE"
                },
                {
                    text: "Report 2",
                    url: "",
                    *//*fx: 291.3247731601375,
                    fy: -506.23700786748157,*//*
                    nodes: [],
                    //category: "github",
                    color: "#70C8BE"
                }
            ]*/
        },
        {
            text: "PrEP",
            url: "",
            fx: 200,
            fy: -550,
            note: "Something about PrEP",
            /*nodes: [
                {
                    text: "Report 1",
                    url: "",
                    fx: 291.3247731601375,
                    fy: -546.2370078674815,
                    nodes: [],
                    //category: "github",
                    color: "#F47e55"
                },
                {
                    text: "Report 2",
                    url: "",
                    fx: 291.3247731601375,
                    fy: -506.23700786748157,
                    nodes: [],
                    //category: "github",
                    color: "#F47e55"
                }
            ]*/
        },

        {
            text: "TB",
            note: "",
            url: "",
            fx: -600,
            fy: -500,
            note: "Something about TB",
            nodes: [],
            
        },
        {
            text: "TB Initiations",
            note: "",
            url: "",
            fx: -800,
            fy: -400,

        },
        {
            text: "TB Outcomes",
            note: "",
            url: "",
            fx: -400,
            fy: -400,

        },
        
    ],
    connections: [
        {
            source: "CHISA",
            target: "TESTING",
            /*curve: {
                x: -43.5535,
                y: 299.545
            }*/
        },
        {
            source: "CHISA",
            target: "PREVENTION",
            /*curve: {
                x: -43.5535,
                y: 299.545
            }*/
        },
        {
            source: "CHISA",
            target: "TREATMENT",
            /*curve: {
                x: -78.1206,
                y: -114.714
            }*/
        },
        {
            source: "CHISA",
            target: "RETENTION",
            /*curve: {
                x: 29.6649,
                y: 80.1111
            }*/
        },
        {
            source: "CHISA",
            target: "Viral Suppression",
            /*curve: {
                x: -103.841,
                y: 49.5548
            }*/
        },
        {
            source: "TESTING",
            target: "HTS Trends",
            /*curve: {
                x: -10.7824,
                y: -90.2671
            }*/
        },
        /*{
            source: "TESTING",
            target: "HTS Forecast",
            
        },*/
        {
            source: "TREATMENT",
            target: "ART Linkage",
            /*curve: {
                x: -238.287,
                y: -54.4818
            }*/
        },
        {
            source: "TREATMENT",
            target: "TROA",
            /*curve: {
                x: -238.287,
                y: -54.4818
            }*/
        },
        {
            source: "RETENTION",
            target: "Cohort Analysis",
            /*curve: {
                x: -238.287,
                y: -54.4818
            }*/
        },
        {
            source: "Viral Suppression",
            target: "VL Monitoring",
            /*curve: {
                x: -238.287,
                y: -54.4818
            }*/
        },
        {
            source: "PREVENTION",
            target: "PrEP",
            /*curve: {
                x: -238.287,
                y: -54.4818
            }*/
        },
        {
            source: "HTS Trends",
            target: "ART Linkage",
            /*curve: {
                x: -238.287,
                y: -54.4818
            }*/
        },
        {
            source: "CHISA",
            target: "TB",
        },
        {
            source: "TB",
            target: "TB Initiations",
        },
        {
            source: "TB",
            target: "TB Outcomes",
        },
        
        
    ]
};
