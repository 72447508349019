import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TiDownloadOutline, TiArrowMaximise } from "react-icons/ti";
import { format } from 'date-fns';
import axios from 'axios';
//import { sign } from 'jsonwebtoken';

class MetabaseChartTemplate extends Component {

    state = {
        key: null,
        url: null,
        provFil: "",
        distFil: "",
        subDistFil: "",
        facFil: "",
    }

    loadDataSourceCredentials = () => {

        axios.get('/api/reports/datasourcecredentials/' + this.props.reportId, {
            params: {
                Province: this.props.provinceFilter,
                District: this.props.districtFilter,
                Sub_District: this.props.subDistrictFilter,
                Facility: this.props.facilityFilter
            }
        })
            .then(response => {
                this.setState({
                    key: response.data[0].value,
                    url: response.data[1].value
                });
                
            });
    }

    downloadUrl = () => {
        this.props.downloadUrl()
    }

    render() {

        if (this.state.provFil != this.props.provinceFilter || this.state.distFil != this.props.districtFilter
            || this.state.subDistFil != this.props.subDistrictFilter || this.state.facFil != this.props.facilityFilter) {
            this.loadDataSourceCredentials();
        }

        this.state.provFil = this.props.provinceFilter;
        this.state.distFil = this.props.districtFilter;
        this.state.subDistFil = this.props.subDistrictFilter;
        this.state.facFil = this.props.facilityFilter;

        if (this.state.key !== null) {
            
            // you will need to install via 'npm install jsonwebtoken' or in your package.json
            var jwt = require("jsonwebtoken");

            var METABASE_SITE_URL = this.state.url;
            var METABASE_SECRET_KEY = this.state.key;

            var filters = {
                "Province": this.props.provinceFilter,
                "District": this.props.districtFilter,
                "Sub_District": this.props.subDistrictFilter,
                "Facility": this.props.facilityFilter
            }

            //add filters dynamically
            if (this.props.startDate)
                filters = { ...filters, "Start_Date": this.props.startDate }

            if (this.props.endDate)
                filters = { ...filters, "End_Date": this.props.endDate }

            if (this.props.cohorts) {
                if (this.props.cohorts.length > 0)
                    filters = { ...filters, "Cohort": this.props.cohorts }
            }

            if (this.props.year)
                filters = { ...filters, "Report_Year": this.props.year }

            var payload = {
                resource: { question: this.props.resourceQuestion },
                params: filters,
                exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
            };
            var token = jwt.sign(payload, METABASE_SECRET_KEY);

            var iframeUrl = METABASE_SITE_URL + "/embed/question/" + token + "#bordered=false&titled=false&hide_parameters=Province,District,Sub_District,Facility,Start_Date,End_Date,Cohort";

            return (
                <div className="card mb-3 metabase-chart">
                    <div className="card-header align-items-center text-truncate">
                        <small> {this.props.title} in {this.props.facilityFilter !== null ?
                            this.props.facilityFilter : this.props.subDistrictFilter !== null ?
                                this.props.subDistrictFilter : this.props.districtFilter !== null ?
                                    this.props.districtFilter : this.props.provinceFilter !== null ?
                                        this.props.provinceFilter : 'the Country'}
                            {this.props.startDate ?
                                ' between ' + format(new Date(this.props.startDate), 'MMM/yy') + ' and ' + format(new Date(this.props.endDate), 'MMM/yy') : ''}

                        </small>
                        {
                            this.props.downloadUrl
                                ? <button onClick={this.downloadUrl} className="border-0 bg-transparent float-right" >
                                    <TiDownloadOutline className="color align-middle float-right" style={{ fontSize: '25px', padding: '0.2rem' }} />
                                  </button>
                            : null
                        }
                        
                    </div>
                    <div className="card-body metabase-chart metabase-card-body">
                        <iframe
                            src={iframeUrl}
                            frameBorder={0}
                            width="100%"
                            height="100%"
                            title={this.props.title}
                        />
                    </div>
                </div>
            )



            /* 
            <div className="card border-success mb-3">
                <div className="card-header align-items-center">
                    <NavLink to={ props.reportRoute} className="align-items-center float-left d-flex">{props.reportName}</NavLink>
    
                    <UncontrolledDropdown className="align-items-center float-right d-flex">
                        <DropdownToggle nav>
                            {pinUnpin === 'Pin Report' &&
                                <TiPinOutline className="color align-middle float-right" style={{ fontSize: '25px' }} />
                            }
    
                            {pinUnpin === 'Unpin Report' &&
                                <TiPin className="color align-middle float-right" style={{ fontSize: '25px' }} />
                            }
                                                  
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem className="form-control-sm" onClick={() => PinReport(props.reportId)}>{pinUnpin}</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
                <div className="card-body">
                    <p className="card-text">{props.reportDesc}</p>
                </div>
                <div className="card-footer"><small>Update on 30/09/2020</small></div>
            </div>
            
            */
        }
        else {
            return (
                <>
                    Loading.....
                </>
                )
        }
    }
}

const mapStateToProps = state => {
    return {
        provinceFilter: state.user.reportFilterProvinceName,
        districtFilter: state.user.reportFilterDistrictName,
        subDistrictFilter: state.user.reportFilterSubDistrictName,
        facilityFilter: state.user.reportFilterFacilityName,
        startDate: state.user.reportFilterStartDate,
        endDate: state.user.reportFilterEndDate,
        cohorts: state.user.reportFilterCohort,
        year: state.user.reportFilterYear,
        reportId: state.user.reportId
    };
};

export default connect(mapStateToProps)(MetabaseChartTemplate);