import React, { Component } from 'react';
import { FormGroup, Label, Table, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { AiOutlineSetting, AiOutlinePlusCircle } from "react-icons/ai";
import * as NAVIGATION_TYPES from '../../../actions/types';

class ReportSource extends Component {

    state = {
        reportSourceId: 0,
        reportSourceName: '',
        reportSources: [],
        componentDidUpdateCheck: 0,
        saveUpdateDelete: 'Add',
        modal: false
    }

    resetValues = () => {
        this.setState({ reportSourceId: 0 });
        this.setState({ reportSourceName: '' });
        this.setState({ componentDidUpdateCheck: 0 });
        this.setState({ saveUpdateDelete: 'Add' });
    }

    handleSubmit = () => {

        const data = {
            reportSourceName: this.state.reportSourceName
        };

        if (this.state.saveUpdateDelete === 'Add') {

            axios.post('/api/reportsources/add', data)
                .then(response => {
                    this.setState({ componentDidUpdateCheck: 1 });
                    this.toggle();
                    NotificationManager.success('Saved Successfully!', '', 2000);
                })
                .catch(error => {
                    console.log(error);
                    NotificationManager.error('An error occured!', '', 2000);
                })
        }

        else if (this.state.saveUpdateDelete === 'Update') {

            axios.post('/api/reportsources/update/' + this.state.reportSourceId, data)
                .then(response => {
                    this.setState({ componentDidUpdateCheck: 1 });
                    this.toggle();
                    NotificationManager.success('Updated Successfully!', '', 2000);
                })
                .catch(error => {
                    console.log(error);
                    NotificationManager.error('An error occured!', '', 2000);
                })
        }
        else if (this.state.saveUpdateDelete === 'Delete') {

            axios.post('/api/reportsources/delete/' + this.state.reportSourceId)
                .then(response => {
                    this.setState({ componentDidUpdateCheck: 1 });
                    this.toggle();
                    NotificationManager.success('Deleted Successfully!', '', 2000);
                })
                .catch(error => {
                    console.log(error);
                    NotificationManager.error('An error occured!', '', 2000);
                })
        }
    }

    componentDidMount() {
        axios.get('/api/reportsources')
            .then(response => {
                this.setState({ reportSources: response.data });
            });
    }

    componentDidUpdate() {
        if (this.state.componentDidUpdateCheck > 0) {
            axios.get('/api/reportsources')
                .then(response => {
                    this.setState({ reportSources: response.data });
                });

            this.setState({ componentDidUpdateCheck: 0 });
        }
    }

    getReportSource = (reportSource, action) => {
        this.resetValues();
        this.toggle();
        this.setState({ reportSourceId: reportSource.reportSourceId })
        this.setState({ reportSourceName: reportSource.reportSourceName });
        this.setState({ saveUpdateDelete: action });
    }

    toggle = () => {
        this.setState({ modal: !this.state.modal });
    }

    setComponentFilterState = (value) => {
        localStorage.setItem('componentFilter', value);
    }


    render() {
        const reportSourcesReturned = this.state.reportSources.map(reportSource => {
            return <tr key={reportSource.reportSourceId}>
                <td>{reportSource.reportSourceName}</td>
                <td><NavLink to={"/reportsourcesetting/" + reportSource.reportSourceId} onClick={() => this.setComponentFilterState(NAVIGATION_TYPES.REPORT_SOURCE_SETTING)} ><AiOutlineSetting style={{ fontSize: "20px" }} /> </NavLink></td>
                <td><NavLink to={"/reportsource"} onClick={() => this.getReportSource(reportSource, "Update")} ><FaRegEdit /> </NavLink></td>
                <td><NavLink to={"/reportsource"} onClick={() => this.getReportSource(reportSource, "Delete")} ><FaRegTrashAlt /> </NavLink></td>
            </tr>;

        });

        let classNameHeader = 'color';
        let classNameBtn = 'btn btn-infohub w-100';
        if (this.state.saveUpdateDelete === "Delete") {
            classNameHeader = 'deleteColor';
            classNameBtn = 'btn btn-danger w-100';
        }

        return (
            <div>
                <Modal isOpen={this.state.modal} toggle={this.toggle} >
                    <ModalHeader toggle={this.toggle} className={classNameHeader} >{this.state.saveUpdateDelete + " Report Source"}</ModalHeader>
                    <ModalBody>
                        <AvForm onValidSubmit={this.handleSubmit}>
                            <div className="row">
                                <div className="col-md-12">
                                    <FormGroup>
                                        <Label className="color">Report source name:</Label>
                                        <AvField
                                            name="reportSourceName"
                                            type="text"
                                            className="form-control-sm form-control"
                                            placeholder="Report source name"
                                            value={this.state.reportSourceName}
                                            onChange={(e) => this.setState({ reportSourceName: e.target.value })}
                                            required
                                            errorMessage="Report source name is required" />

                                    </FormGroup>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-6">
                                    <button type="submit" className={classNameBtn} >{this.state.saveUpdateDelete}</button>
                                </div>
                                <div className="col-md-6">
                                    <button type="reset" className="btn btn-secondary w-100" onClick={this.toggle} >Cancel</button>
                                </div>

                            </div>

                        </AvForm>
                    </ModalBody>

                </Modal>

                <div className="row">
                    <div className="col-md-10"></div>
                    <div>
                        <button
                            type="submit"
                            style={{
                                backgroundColor: "transparent",
                                outline: "none",
                                border: "none"
                            }}
                            onClick={() => { this.resetValues(); this.toggle(); }} >
                            <AiOutlinePlusCircle className="color" style={{ fontSize: "45px" }} />
                        </button>

                        {/*<button type="submit" className="btn btn-infohub w-100" onClick={() => { this.resetValues(); this.toggle(); }} >Add Report Source</button>*/}
                    </div>
                </div>

                <div className="row">
                    <Table striped>
                        <thead>
                            <tr>
                                <th className="color">Report Source Name</th>
                                <th className="color">Settings</th>
                                <th className="color"></th>
                                <th className="color"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {reportSourcesReturned}

                        </tbody>
                    </Table>
                </div>

            </div>
        )
    }
}

export default ReportSource;