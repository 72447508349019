import React, { Component } from 'react';
import MetabaseChartTemplate from '../../../Reports/Charts/MetabaseChartTemplate';
import Layout from '../../../Layout';
import TLDUptakeReportPages from './TLDUPtakeReportPages';
import { DateRangeFilterContext } from '../../../Reports/Filters/DateRangeFilterContext';
import { OrgUnitFilterContext } from '../../../Reports/Filters/OrgUnitFilterContext';
import { DateRangeFilter } from '../../../Reports/Filters/DateRangeFilter';
import { SubmitFilters } from '../../../Reports/Filters/SubmitFilters';
import { OrgUnitFilter } from '../../../Reports/Filters/OrgUnitFilter';
import ChartsDrawer from '../ChartsDrawer';
import ChartsContent from '../ChartsContent';


class TLDUptakeLayout1 extends Component {

    state = {
        componentFilter: localStorage.getItem('componentFilter'),
        defaultStartDate: new Date("2023-04-01"),
        defaultEndDate: new Date("2024-03-31"),
        minDate: new Date("2010-01-01"),
        maxDate: new Date("2024-03-31")
    }

    render() {
        return (
            <Layout>               
                <div className="row" style={{ height: "85vh" }}>
                    <ChartsDrawer>
                        <OrgUnitFilterContext>
                            <DateRangeFilterContext>
                                    <OrgUnitFilter />
                                    <DateRangeFilter defaultStartDate={this.state.defaultStartDate} defaultEndDate={this.state.defaultEndDate} />
                                    <SubmitFilters />
                            </DateRangeFilterContext>
                        </OrgUnitFilterContext>
                        <hr />
                        <TLDUptakeReportPages />
                    </ChartsDrawer>
                    <ChartsContent>
                        <div className="chart-container">
                            <div className="row chart-row-50">
                                <div className="col-md-3">
                                    <MetabaseChartTemplate resourceQuestion={21} title="TLD Uptake by District" />
                                </div>
                                <div className="col-md-3">
                                    <MetabaseChartTemplate resourceQuestion={29} title="Total Uptake among Clients" />
                                </div>
                                <div className="col-md-6">
                                    <MetabaseChartTemplate resourceQuestion={30} title="TLD Uptake by Age Group" />
                                </div>
                            </div>
                            <br></br>
                            <div className="row chart-row-50">
                                <div className="col-md-6">
                                    <MetabaseChartTemplate resourceQuestion={22} title="TLD Uptake Trend" />
                                </div>
                                <div className="col-md-6">
                                    <MetabaseChartTemplate resourceQuestion={23} title="Active Clients on TLD by Sex" />
                                </div>
                            </div>
                            <br></br>
                            <div className="row chart-row-50">
                                <div className="col-md-6">
                                    <MetabaseChartTemplate resourceQuestion={31} title="ART Naïve Clients Starting on TLD" />
                                </div>
                                <div className="col-md-6">
                                    <MetabaseChartTemplate resourceQuestion={32} title="TLD Uptake among Women of Child-Bearing Age" />
                                </div>
                            </div>
                        </div>
                    </ChartsContent>
                </div>
            </Layout>
        )
    }
}

export default TLDUptakeLayout1;