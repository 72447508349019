import React, { Component } from 'react';
import MetabaseChartTemplate from '../../../Reports/Charts/MetabaseChartTemplate';
import Layout from '../../../Layout';
import CAReportPages from './CAReportPages';
import { OrgUnitFilterContext } from '../../../Reports/Filters/OrgUnitFilterContext';
import { CohortFilterContext } from '../../../Reports/Filters/CohortFilterContext';
import { OrgUnitFilter } from '../../../Reports/Filters/OrgUnitFilter';
import { CohortFilter } from '../../../Reports/Filters/CohortFilter';
import { SubmitFilters } from '../../../Reports/Filters/SubmitFilters';
import ChartsDrawer from '../ChartsDrawer';
import ChartsContent from '../ChartsContent';


class CALayout1 extends Component {
    state = {
        componentFilter: localStorage.getItem('componentFilter'),
        defaultCohort: [2020,2019,2018,2017,2016]
    }

    render() {
        return (
            <Layout>
                <div className="row" style={{ height: "85vh" }}>
                    <ChartsDrawer>
                        <OrgUnitFilterContext>
                            <CohortFilterContext>
                                    <OrgUnitFilter />                    
                                <CohortFilter
                                    defaultCohort={this.state.defaultCohort} />
                                    <br />
                                    <SubmitFilters />
                            </CohortFilterContext>
                        </OrgUnitFilterContext>
                        <hr />
                        <CAReportPages />
                    </ChartsDrawer>
                    <ChartsContent>
                        <div className="chart-container">
                            <div className="row chart-row-50">
                                <div className="col-md-12">
                                    <MetabaseChartTemplate resourceQuestion={7} title="Cohorts by % RIC" />
                                </div>
                            </div>
                            <br />
                            <div className="row chart-row-50">
                                <div className="col-md-6">
                                    <MetabaseChartTemplate resourceQuestion={51} title="RIC - Female" />
                                </div>
                                <div className="col-md-6">
                                    <MetabaseChartTemplate resourceQuestion={52} title="RIC - Male" />
                                </div>
                            </div>
                            <br />
                            <div className="row chart-row-50">
                                <div className="col-md-6">
                                    <MetabaseChartTemplate resourceQuestion={53} title="RIC - <15 Years" />
                                </div>
                                <div className="col-md-6">
                                    <MetabaseChartTemplate resourceQuestion={54} title="RIC - >=15 Years" />
                                </div>
                            </div>
                        </div>
                    </ChartsContent>
                </div>
            </Layout>
        )
    }
}

export default CALayout1;