import React, { Component } from 'react';
import Layout from '../../../Layout';
import ExpandedTBOutcomesReportPages from './ExpandedTBOutcomesReportPages';
import { OrgUnitFilterContext } from '../../../Reports/Filters/OrgUnitFilterContext';
import { SubmitFilters } from '../../../Reports/Filters/SubmitFilters';
import { OrgUnitFilter } from '../../../Reports/Filters/OrgUnitFilter';
import ChartsDrawer from '../ChartsDrawer';
import ChartsContent from '../ChartsContent';
import ShinyChartTemplate from '../../../Reports/Charts/ShinyChartTemplate';
import 'react-tabs/style/react-tabs.css';

class ExpandedTBOutcomesLayout1 extends Component {

    state = {
        componentFilter: localStorage.getItem('componentFilter'),
    }

    render() {        
        return (
            <Layout>               
                <div className="row" style={{ height: "85vh" }}>
                    <ChartsDrawer>
                        <OrgUnitFilterContext>
                            <OrgUnitFilter 
                                    provinceDisable={false}
                                    districtDisable = {true}
                                    subDistrictDisable = {true}
                                    facilityDisable = {true}
                            />
                                
                                <SubmitFilters />
                        </OrgUnitFilterContext>
                        <hr />
                        <ExpandedTBOutcomesReportPages />
                    </ChartsDrawer>
                    <ChartsContent>
                        <div className="chart-container" style={{ height: "85vh" }} >
                            <div className="row" style={{ height: "84vh" }}>
                                <div className="col-md-12">
                                        <ShinyChartTemplate resourceQuestion={"epi/tboutcomes/descriptive"} title="Descriptive Characteristics of TB Patients" />
                                </div>
                            </div>
                        </div>
                    </ChartsContent>
                </div>
            </Layout>
        )
    }
}

export default ExpandedTBOutcomesLayout1;