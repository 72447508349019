import React, { Component } from 'react';
import MetabaseChartTemplate from '../../../Reports/Charts/MetabaseChartTemplate';
import Layout from '../../../Layout';
import TROAReportPages from './TROAReportPages';
import { DateRangeFilterContext } from '../../../Reports/Filters/DateRangeFilterContext';
import { OrgUnitFilterContext } from '../../../Reports/Filters/OrgUnitFilterContext';
import { DateRangeFilter } from '../../../Reports/Filters/DateRangeFilter';
import { SubmitFilters } from '../../../Reports/Filters/SubmitFilters';
import { OrgUnitFilter } from '../../../Reports/Filters/OrgUnitFilter';
import ChartsDrawer from '../ChartsDrawer';
import ChartsContent from '../ChartsContent';

class TROALayout extends Component {

    state = {
        componentFilter: localStorage.getItem('componentFilter'),
        defaultStartDate: new Date("2023-04-01"),
        defaultEndDate: new Date("2024-03-31"),
        minDate: new Date("2022-01-01"),
        maxDate: new Date("2024-03-31")
    }

    render() {
        return (
            <Layout>
                <div className="row" style={{ height: "85vh" }}>
                    <ChartsDrawer>
                        <OrgUnitFilterContext>
                            <DateRangeFilterContext>
                                    <OrgUnitFilter />                                    
                                    <DateRangeFilter 
                                        defaultStartDate={this.state.defaultStartDate} 
                                        defaultEndDate={this.state.defaultEndDate} 
                                        minDate={this.state.minDate}
                                        maxDate={this.state.maxDate}
                                    />
                                    <SubmitFilters />
                            </DateRangeFilterContext>
                        </OrgUnitFilterContext>
                        <hr />
                        <TROAReportPages />
                    </ChartsDrawer>
                    <ChartsContent>
                        <div className="chart-container">
                            <div className="row chart-row-50">
                                <div className="col-md-7">
                                    <MetabaseChartTemplate resourceQuestion={26} title="Deduplicated Monthly TROA Trend" />
                                </div>
                                <div className="col-md-5">
                                    <MetabaseChartTemplate resourceQuestion={25} title="TROA by Age Group" />
                                </div>
                            </div>
                            <br />
                            <div className="row chart-row-50">
                                <div className="col-md-6">
                                    <MetabaseChartTemplate resourceQuestion={28} title="TROA (>=15 Years)" />
                                </div>
                                <div className="col-md-6">
                                    <MetabaseChartTemplate resourceQuestion={105} title="TROA (10-24 Years) by Sex and Reporting Month" />
                                </div>
                            </div>
                        </div>
                    </ChartsContent>
                </div>
            </Layout>
        )
    }
}

export default TROALayout;