import TileWMS from 'ol/source/TileWMS';

function geoserver({layer, filter}) {
    return new TileWMS({
        url: 'https://geoserver.chi-sa.org/geoserver/wms',
        params: {'LAYERS': layer, 'CQL_FILTER' : filter, 'TILED': true},
        serverType: 'geoserver',
        transition: 0,
      });
}

export default geoserver;