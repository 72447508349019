import React, { Component } from 'react';
import MetabaseChartTemplate from '../../../Reports/Charts/MetabaseChartTemplate';
import Layout from '../../../Layout';
import TBReportPages from './TBReportPages';
import { DateRangeFilterContext } from '../../../Reports/Filters/DateRangeFilterContext';
import { OrgUnitFilterContext } from '../../../Reports/Filters/OrgUnitFilterContext';
import { DateRangeFilter } from '../../../Reports/Filters/DateRangeFilter';
import { SubmitFilters } from '../../../Reports/Filters/SubmitFilters';
import { OrgUnitFilter } from '../../../Reports/Filters/OrgUnitFilter';
import ChartsDrawer from '../ChartsDrawer';
import ChartsContent from '../ChartsContent';

class TBLayout5 extends Component {
	state = {
		componentFilter: localStorage.getItem('componentFilter'),
        defaultStartDate: new Date("2023-04-01"),
        defaultEndDate: new Date("2024-03-31"),
        minDate: new Date("2010-01-01"),
        maxDate: new Date("2024-03-31")
	};
	render() {
		return (
			<Layout>
				<div className="row" style={{ height: '85vh' }}>
					
						<ChartsDrawer>
							<OrgUnitFilterContext>
								<DateRangeFilterContext>
									<OrgUnitFilter />
									<DateRangeFilter
										defaultStartDate={this.state.defaultStartDate}
										defaultEndDate={this.state.defaultEndDate}
										minDate={this.state.minDate}
										maxDate={this.state.maxDate}
									/>
									<SubmitFilters />
								</DateRangeFilterContext>
							</OrgUnitFilterContext>
							<hr />
							<TBReportPages />
						</ChartsDrawer>
					
					<ChartsContent>
						<div className="chart-container" >
							<div className="row chart-row-50">
								<div className="col-md-4">
									<MetabaseChartTemplate 
                                        resourceQuestion={72} 
                                        title="Total TB registrations"
                                    />
								</div>
								<div className="col-md-4">
											<MetabaseChartTemplate 
											resourceQuestion={140} 
											title="HIV status at TB treatment start" 
											/>
								</div>
								<div className="col-md-4">
									<MetabaseChartTemplate
												resourceQuestion={141}
												title="TB/HIV co-infected, ART status"
											/>
								</div>
							</div>
							<br />
							<div className="row chart-row-50">
                                <div className="col-md-6">
									<MetabaseChartTemplate
												resourceQuestion={147}
												title="TB/HIV co-infected rate by sex"
											/>
								</div>
								<div className="col-md-6">
									<MetabaseChartTemplate
												resourceQuestion={148}
												title="TB/HIV co-infected, on ART rate by sex"
											/>
								</div>
							</div>
							<br />
							<div className="row chart-row-50">
                                <div className="col-md-12">
									<MetabaseChartTemplate
												resourceQuestion={149}
												title="TB/HIV co-infected quarterly summary"
											/>
								</div>
							</div>
						</div>
					</ChartsContent>
				</div>
			</Layout>
		);
	}
}

export default TBLayout5;
