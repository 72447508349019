import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FilterListIcon from '@material-ui/icons/FilterList';
import * as NAVIGATION_TYPES from '../../../actions/types';

const drawerWidth = '25%'

const useStyles = makeStyles((theme) => ({
    hide: {
        display: 'none',
    },
    show: {
        overflowY: 'auto',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
              
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.short,
        }),
        top: 'auto',
        //height: '100%',
        zIndex: 1
        
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.short,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
        top : 'auto',
        height: '100%',
        zIndex: 1
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
}));

export default (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const drawerOpen = useSelector(state => state.user.drawerOpen);

    const handleDrawerToggle = () => {
        dispatch({
            type: NAVIGATION_TYPES.TOGGLE_DRAWER,
            payload: !drawerOpen
        });
    };

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: drawerOpen,
                [classes.drawerClose]: !drawerOpen,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: drawerOpen,
                    [classes.drawerClose]: !drawerOpen,
                })
            }}
        >
            <div className={classes.toolbar}>
                <IconButton onClick={handleDrawerToggle}>
                    {!drawerOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </div>
            {/*<Divider />
            <List>
                {['Filters'].map((text, index) => (
                    <ListItem button key={text}  onClick={handleDrawerToggle}>
                        <ListItemIcon>{<FilterListIcon />}</ListItemIcon>
                        <ListItemText primary={text} /><ChevronLeftIcon />
                    </ListItem>
                ))}
                </List>*/}
            <div className={!drawerOpen ? classes.hide : classes.show} style={{ marginBottom: '15vh' }} >
                {props.children}
            </div>
        </Drawer>
    );
}
