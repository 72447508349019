import React, { Component } from 'react';
import axios from 'axios';
import Layout from '../../Layout';

class HTSYield extends Component {
    state = {
        //key: null,
        url: null
    }


    loadDataSourceCredentials = () => {
        axios.get('/api/reports/datasourcecredentials/1')
            .then(response => {
                this.setState({
                    //key: response.data[0].value,
                    url: response.data[1].value
                });

            });
    }

    componentDidMount() {
        this.loadDataSourceCredentials();
    }

    render() {

        if (this.state.url !== null) {

            var iframeUrl = this.state.url;
            //var iframeUrl = "https://infohub-dev.tiercentralisation.org:7654/hts/yield";

            return (
                <Layout>
                    <div className="w-100" style={{ height: "85vh" }}>
                        <iframe
                            src={iframeUrl}
                            frameBorder={0}
                            width="100%"
                            height="100%"
                            title="pa"
                        />
                    </div>
                </Layout>
            );
        }
        else {
            return (
                <>
                    Loading...
                </>
                )
        }
    }
}

export default HTSYield;