import React, { Component } from 'react';
import MetabaseChartTemplate from '../../../Reports/Charts/MetabaseChartTemplate';
import Layout from '../../../Layout';
import CAReportPages from './CAReportPages';
import { OrgUnitFilterContext } from '../../../Reports/Filters/OrgUnitFilterContext';
import { CohortFilterContext } from '../../../Reports/Filters/CohortFilterContext';
import { OrgUnitFilter } from '../../../Reports/Filters/OrgUnitFilter';
import { CohortFilter } from '../../../Reports/Filters/CohortFilter';
import { SubmitFilters } from '../../../Reports/Filters/SubmitFilters';
import ChartsDrawer from '../ChartsDrawer';
import ChartsContent from '../ChartsContent';

class CALayout2 extends Component {
    state = {
        componentFilter: localStorage.getItem('componentFilter'),
        defaultCohort: [2020,2019,2018,2017,2016]
    }

    render() {
        return (
            <Layout>                
                <div className="row" style={{ height: "85vh" }}>
                    <ChartsDrawer>
                        <OrgUnitFilterContext>
                            <CohortFilterContext>
                                    <OrgUnitFilter />                    
                                    <CohortFilter />
                                    <br />
                                    <SubmitFilters />
                            </CohortFilterContext>
                        </OrgUnitFilterContext>
                        <hr />
                        <CAReportPages />
                    </ChartsDrawer>
                    <ChartsContent>
                        <div className="chart-container">
                            <div className="row chart-row-50">
                                <div className="col-md-12">
                                    <MetabaseChartTemplate resourceQuestion={50} title="Cohorts by % LTP" />
                                </div>
                            </div>
                            <br />
                            <div className="row chart-row-50">
                                <div className="col-md-6">
                                    <MetabaseChartTemplate resourceQuestion={35} title="Cohorts by % RIP" />
                                </div>
                                <div className="col-md-6">
                                    <MetabaseChartTemplate resourceQuestion={37} title="Cohorts by % LTF" />
                                </div>
                            </div>
                            <br />
                            <div className="row chart-row-50">
                                <div className="col-md-4">
                                    <MetabaseChartTemplate resourceQuestion={47} title="Cohorts by Most Recent Outcome" />
                                </div>
                                <div className="col-md-4">
                                    <MetabaseChartTemplate resourceQuestion={48} title="Cohorts by Outcome at 12m" />
                                </div>
                                <div className="col-md-4">
                                    <MetabaseChartTemplate resourceQuestion={49} title="Cohorts by Outcome at 24m" />
                                </div>
                            </div>
                        </div>
                    </ChartsContent>
                </div>
            </Layout>
        )
    }
}

export default CALayout2;