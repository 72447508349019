import React, { useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router";
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import * as ACTION_TYPES from '../actions/types';
import { NavLink } from "react-router-dom";

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © National Department of Health'} {new Date().getFullYear()}
    </Typography>
  );
}

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#02642e',
      //fontFamily: 'Rubik',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#02642e',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#02642e',
        //fontFamily: 'Rubik',
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    //fontFamily: 'Rubik',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    //fontFamily: 'Rubik',
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    color: "white",
    backgroundColor: "#02642e",
    //fontFamily: 'Rubik',
    '&:hover' : {
        color: "#02642e"
    }
  },
  progress: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  progressBar: {
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#02642e"
    },
    backgroundColor: "lightgray",
  }
}));

var jwt = require("jsonwebtoken");

const Login = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [usernameErrorText, setUsernameErrorText] = useState("");
    const [passwordErrorText, setPasswordErrorText] = useState("");
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.charCode === 13 || e.keyCode === 13) {
            dispatch(handleLogin());
        }
    };

    const validate = () => {
        if (!username.trim()) {
            setUsernameError(true);
            setUsernameErrorText("Username is required");
        } else {
            setUsernameError(false);
            setUsernameErrorText("");
        }

        if (!password.trim()) {
            setPasswordError(true);
            setPasswordErrorText("Password is required");
        } else {
            setPasswordError(false);
            setPasswordErrorText("");
        }
    };

    const handleLogin = () => {
        validate();
        return function (dispatch) {
            if (!passwordError && !usernameError) {
                const data = {
                    username: username,
                    password: password,
                };
                //If everything is okay
                if (username.trim() && password.trim()) {
                    setLoading(true);
                    axios.post('/api/users/signin', data)
                        .then(response => {
                            dispatch({
                                type: ACTION_TYPES.AUTHENTICATION,
                                payload: response.data
                            });
                            NotificationManager.success('Login Successful!', '', 2000);
                            props.startTimeout();
                            // store user details and jwt token in local storage to keep user logged in between page refreshes
                            //localStorage.setItem('currentUser', JSON.stringify(response.data));

                            var userData = jwt.decode(response.data);
                            if (userData.userChangePwd === "1") {
                                setLoading(false);
                                history.push("/user/changepwdonlogin");
                            }
                            else {
                                setLoading(false);
                                history.push("/");
                            }
                            return response.data;
                        })
                        .catch(error => {
                            if (error.response.status === 401) {
                                NotificationManager.error("Your account has been disabled. Please contact help@chi-sa.org for assistance", '', 10000);
                            }
                            else {
                                NotificationManager.error("Your username or password is incorrect. Please try again or contact help@chi-sa.org for assistance", '', 10000);
                            }
                            setLoading(false);
                        });
                }
            }
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>

                <div className="col-md-12" style={{ textAlign: 'center', paddingTop: '50px' }}>
                    <img src="images/ndoh_logo.png" width="305" height="122" alt="" />
                </div>
                < br />
                <Paper variant="elevation" elevation={2}>
                    <div style={{ padding: 20 }}>
                        <h6 style={{ color: '#02642e', textAlign: 'center' }}>Sign in to CHISA</h6>
                        <form className={classes.form} >
                            <CssTextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"
                                autoFocus
                                value={username}
                                onChange={(e) => {
                                    setUsername(e.target.value);
                                    //validate();
                                }}
                                onKeyPress={handleKeypress}
                                onKeyUp={validate}
                                error={usernameError}
                                helperText={usernameErrorText}
                            />
                            <CssTextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    //validate();
                                }}
                                value={password}
                                onKeyPress={handleKeypress}
                                onKeyUp={validate}
                                error={passwordError}
                                helperText={passwordErrorText}
                            />

                            <Button
                                fullWidth
                                startIcon={<ExitToAppIcon/>}
                                variant="outlined"
                                size="large"
                                className={classes.submit}
                                onClick={() => dispatch(handleLogin())}
                            >
                                Sign In
                            </Button>
                            <Grid container>
                                <Grid item xs align="center">
                                    <NavLink to="/forgotpassword">Forgot Password?</NavLink>
                                </Grid>
                            </Grid>
                            <div className={classes.progress} hidden={!loading}>
                                <LinearProgress className={classes.progressBar} />
                            </div>
                        </form>
                    </div>
                </Paper>
            </div>
            <Box mt={2}>
                <Copyright />
            </Box>

        </Container>
    );
}
export default connect()(Login);