import React, { Component } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import axios from 'axios';

class SystemSettings extends Component {
    state = {
        timerActive: '',
        timerPeriod: '600',
        timerCountdown: '30',
        timerMessage: ''
    }

    getValues = () => {
        axios.get('/api/systemsettings/Setting/TimerActive')
            .then(response => {
                this.setState({ timerActive: String(response.data) });
            });
        axios.get('/api/systemsettings/Setting/TimerPeriod')
            .then(response => {
                this.setState({ timerPeriod: Number(response.data) });
            });
        axios.get('/api/systemsettings/Setting/TimerCountdown')
            .then(response => {
                this.setState({ timerCountdown: Number(response.data) });
            });
        axios.get('/api/systemsettings/Setting/TimerMessage')
            .then(response => {
                this.setState({ timerMessage: response.data });
            });
    }

    handleSubmit = () => {
        const data = [{ Key: "TimerActive", Value: this.state.timerActive },
        { Key: "TimerPeriod", Value: String(this.state.timerPeriod) },
        { Key: "TimerCountdown", Value: String(this.state.timerCountdown) },
        { Key: "TimerMessage", Value: this.state.timerMessage }];

        axios.post("/api/systemsettings/SaveSetting", data)
            .then(response => {
                NotificationManager.success('Updated Successfully!', '', 2000);
            })
            .catch(error => {
                console.log('ERROR : ' + error.message);
                NotificationManager.error('Error occured!', '', 2000);
            })
    }

    resetValues = () => {
        this.getValues();
    }

    checkTimerCountdown = (value) => {
        return this.state.timerPeriod > value ? true : "Timer Countdown must be less than the Timer Period";
    }

    defaultValues = {
        tmrActive: this.state.timerActive,
        tmrPeriod: this.state.timerPeriod,
        tmrCountdown: this.state.timerCountdown,
        tmrMessage: this.state.timerMessage
    }

    componentDidMount() {
        this.getValues();
    }

    render() {
        return (
            <AvForm onValidSubmit={this.handleSubmit} model={this.defaultValues}>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <FormGroup>
                            <Label className="color" htmlFor="tmrActive">Timer Active:</Label>
                            <div className="form-check">
                                <Input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="tmrActive"
                                    onChange={(e) => {
                                        if (e.target.checked)
                                            this.setState({ timerActive: "true" });
                                        else
                                            this.setState({ timerActive: "false" });
                                    }}
                                    checked={ this.state.timerActive === "true" }
                                />
                            </div>
                            <div className="row"><p/></div>
                        </FormGroup>
                        <FormGroup>
                            <Label className="color" htmlFor="tmrPeriod">Timer Period:</Label>
                            <AvField
                                name="tmrPeriod"
                                type="number"
                                className="form-control-sm form-control col-md-3"
                                placeholder="Timer Period"
                                value={this.state.timerPeriod}
                                max="36000"
                                onChange={(e) => this.setState({ timerPeriod: e.target.valueAsNumber })}
                                autoComplete="off"
                                required
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: 'Timer Period is required!'
                                    }
                                }} />
                        </FormGroup>
                        <FormGroup>
                            <Label className="color" htmlFor="tmrCountdown">Timer Countdown:</Label>
                            <AvField
                                name="tmrCountdown"
                                type="number"
                                className="form-control-sm form-control col-md-3"
                                placeholder="Timer Countdown"
                                value={this.state.timerCountdown}
                                max="36000"
                                onChange={(e) => this.setState({ timerCountdown: e.target.valueAsNumber })}
                                autoComplete="off"
                                required
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: 'Timer Countdown is required!'
                                    },
                                    myValidation: this.checkTimerCountdown
                                }} />
                        </FormGroup>
                        <FormGroup>
                            <Label className="color" htmlFor="tmrMessage">Timer Message:</Label>
                            <AvField
                                name="tmrMessage"
                                type="text"
                                className="form-control-sm form-control"
                                placeholder="Timer Message"
                                value={this.state.timerMessage}
                                maxLength="1000"
                                onChange={(e) => this.setState({ timerMessage: e.target.value })}
                                autoComplete="off"
                            />
                        </FormGroup>

                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-4">
                                <button
                                    type="submit"
                                    className="btn btn-infohub w-100" >Submit</button>
                            </div>
                            <div className="col-md-4">
                                <button
                                    type="reset"
                                    className="btn btn-secondary w-100"
                                    onClick={this.resetValues} >Reset</button>
                            </div>
                        </div>
                    </div>
                </div>
            </AvForm>
        );
    }
}

export default SystemSettings;