import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class TBHIVReportPages extends Component {
    render() {
        return (
            <div className="container">
                <div className="row justify-content-end text-right">
                    <NavLink to="/charts/tbhiv/1" activeClassName="activeNavLink" className="report-page">Overview</NavLink>
                </div> 
            </div>
        )
    }
}

export default TBHIVReportPages;