import { BehaviorSubject } from 'rxjs';
import { url } from "../api";
import { handleResponse } from '../_helpers';
//import configureStore from '../store';
//import * as ACTION_TYPES from "../actions/types";
//import { createBrowserHistory } from 'history';

//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
//const history = createBrowserHistory({ basename: baseUrl });
//const { dispatch } = configureStore(history);
const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser') || '{}'));

export const authentication = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

function login(username: any, password: any, remember: any) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password, remember })
    };

    return fetch(`${url}/api/users/signin`, requestOptions)
        .then(handleResponse)
        .then(user => {
            /*dispatch({
                type: ACTION_TYPES.AUTHENTICATION,
                payload: user
            });*/
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);
            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    /*localStorage.removeItem('currentUser');
    localStorage.removeItem('reportGroupId');
    localStorage.removeItem('componentFilter');
    localStorage.removeItem('userId');
    localStorage.removeItem('updateUserId');*/
    
    localStorage.clear();
    currentUserSubject.next(null);
    console.log('logout executed');
}