function getUrl(){
    if(process.env.NODE_ENV == 'development'){
        return 'https://localhost:5001';
    }
    else if(process.env.NODE_ENV == 'production'){
        if(process.env.REACT_APP_DEPLOYMENT_SLOT == 'dev'){
            return 'https://analytics-dev.chi-sa.org'; //DEV Environment on Azure (dev slot)
        }
        else if(process.env.REACT_APP_DEPLOYMENT_SLOT == 'prod'){
            return 'https://analytics.chi-sa.org'; //Current PROD Environment on Azure (prod slot)
        }
        else if(process.env.REACT_APP_DEPLOYMENT_SLOT == 'stage'){
            return 'https://analytics-stage.chi-sa.org'; //Current PROD Environment on Azure (prod slot)
        }
        else if(process.env.REACT_APP_DEPLOYMENT_SLOT == 'demo'){
            return 'https://analytics-demo.chi-sa.org'; //Current PROD Environment on Azure (prod slot)
        }
        // else return 'https://analytics.chi-sa.org'; //Current PROD Environment on Xneelo
    }
}

export const url = getUrl();