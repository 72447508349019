import React, { Component } from 'react';
import { Button, Table } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { FaRegEdit, FaRegTrashAlt, FaCheck } from "react-icons/fa";
import { FiLock, FiUnlock } from "react-icons/fi";
import { Tooltip } from '@material-ui/core';
import * as NAVIGATION_TYPES from '../actions/types';

class UsersList extends Component {

    state = {
        users: [],
        sortField: 'userName',
        sortFieldPrev: 'email',
        sortOrder: 'Asc'
    }

    fetchUsers = () => {
        axios.get('/api/users')
            .then(response => {
                this.setState({ users: response.data });
            });
    }

    componentDidMount() {
        this.fetchUsers();
    }

    setComponentFilterState = (value, userId) => {
        localStorage.setItem('componentFilter', value);
        localStorage.setItem('updateUserId', userId);
    }

    handleActive = (id) => {
        axios.post(`/api/users/activate/${id}`)
            .then(response => {
                this.fetchUsers();
            });
    };

    handleSort = (sortFirst) => {
        const userSort = JSON.parse(JSON.stringify(this.state.users));
        let sortSecond = '';
        let sortAsc = true;

        if (this.state.sortField !== sortFirst) {
            sortSecond = this.state.sortField;
            this.setState({ sortField: sortFirst });
            this.setState({ sortFieldPrev: sortSecond });
        }
        else {
            sortSecond = this.state.sortFieldPrev;
            if (this.state.sortOrder === 'Asc') {
                sortAsc = false;
            }
        }

        if (sortAsc) {
            userSort.sort((a, b) => (a[sortFirst].toString().toLowerCase() > b[sortFirst].toString().toLowerCase()) ? 1 :
                ((a[sortFirst].toString().toLowerCase() === b[sortFirst].toString().toLowerCase()) ? ((a[sortSecond].toString().toLowerCase() > b[sortSecond].toString().toLowerCase()) ? 1 : -1) : -1))
            this.setState({ sortOrder: 'Asc' });
        }
        else {
            userSort.sort((a, b) => (a[sortFirst].toString().toLowerCase() < b[sortFirst].toString().toLowerCase()) ? 1 :
                ((a[sortFirst].toString().toLowerCase() === b[sortFirst].toString().toLowerCase()) ? ((a[sortSecond].toString().toLowerCase() > b[sortSecond].toString().toLowerCase()) ? 1 : -1) : -1))
            this.setState({ sortOrder: 'Desc' });
        }
        this.setState({ users: userSort });
    }

    render() {

        const usersReturned = this.state.users.map(user => {
            return <tr key={user.id}>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.userName}</td>
                <td>{user.email}</td>
                {user.ldapUser === 1 && <td><FaCheck /></td>}
                {user.ldapUser === 0 && <td></td>}
                <td>{user.active ? "Yes" : "No"}</td>
                <td><NavLink to={"/registereduser/" + user.id} onClick={() => this.setComponentFilterState(NAVIGATION_TYPES.REGISTERED_USER, user.id)}><FaRegEdit /> </NavLink></td>
                <td>
                    <Tooltip title={user.active ? "De-Activate" : "Activate"}>
                        <Button
                            size="sm"
                            color="link"
                            onClick={() => this.handleActive(user.id)}
                        >
                            <span style={{ color: "#02642e" }}>{user.active ? <FiLock /> : <FiUnlock />}</span>
                        </Button>
                    </Tooltip>
                </td>
            </tr>;

        });

        return (
            <div className="div-container">
                <Table striped className="mt-3">
                    <thead>
                        <tr>
                            <th className="color hoverTableHeader" onClick={() => this.handleSort('firstName')}>First Name</th>
                            <th className="color hoverTableHeader" onClick={() => this.handleSort('lastName')}>Last Name</th>
                            <th className="color hoverTableHeader" onClick={() => this.handleSort('userName')}>Username</th>
                            <th className="color hoverTableHeader" onClick={() => this.handleSort('email')}>Email</th>
                            <th className="color hoverTableHeader" onClick={() => this.handleSort('ldapUser')}>LDAP</th>
                            <th className="color hoverTableHeader" onClick={() => this.handleSort('active')}>Active</th>
                            <th className="color"></th>
                            <th className="color"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {usersReturned}
                    </tbody>
                </Table>
            </div>
        );
    }
}

export default UsersList;