import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FaRegFileExcel } from "react-icons/fa";
import axios from 'axios';
import fileDownload from 'js-file-download';
import { NotificationManager } from 'react-notifications';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TiDownloadOutline } from "react-icons/ti";

import MetabaseChartTemplate from '../../../Reports/Charts/MetabaseChartTemplate';
import Layout from '../../../Layout';
import DedupReportPages from './DedupReportPages';
import { OrgUnitFilterContext } from '../../../Reports/Filters/OrgUnitFilterContext';
import { OrgUnitFilter } from '../../../Reports/Filters/OrgUnitFilter';
import { SubmitFilters } from '../../../Reports/Filters/SubmitFilters';
import ChartsDrawer from '../ChartsDrawer';
import ChartsContent from '../ChartsContent';
import { url } from '../../../../api';

import Fab from '@material-ui/core/Fab';
import CloudDownload from '@material-ui/icons/CloudDownload';

const useStyles = makeStyles((theme) => ({
    progress: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    progressBar: {
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: "#02642e"
        },
        backgroundColor: "lightgray",
        
    },
    fab: {
        position:'fixed',
        bottom: theme.spacing(5),
        right: theme.spacing(2),
    }
}));

export const DedupReportLayout1 = () => {
    const classes = useStyles();
    const provinceName = useSelector(state => state.user.reportFilterProvinceName);
    const districtName = useSelector(state => state.user.reportFilterDistrictName);
    const subDistrictName = useSelector(state => state.user.reportFilterSubDistrictName);
    const facilityName = useSelector(state => state.user.reportFilterFacilityName);

    const [loading, setLoading] = useState(false);
    const [percentCompleted, setPercentCompleted] = useState(0);

    const loadFacilityData = () => {
        setLoading(true);
        NotificationManager.success('Processing your request...', '', 3000);
        const fileName = facilityName != null ? facilityName : subDistrictName != null ? subDistrictName : districtName != null ? districtName : provinceName != null ? provinceName : 'National';

        axios.get('/api/dedup/excel', {
            params: {
                province: provinceName,
                district: districtName,
                subDistrict: subDistrictName,
                facility: facilityName
            },
            responseType: 'blob',
            onDownloadProgress: function (progressEvent) {
                console.log('download', progressEvent);
                console.log('percent ', (progressEvent.loaded * 100) / progressEvent.total);
                setPercentCompleted(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            }
        }).then(res => {
            if (res.data.size > 0) {
                NotificationManager.success('Downloading...', '', 2000);
                fileDownload(res.data, fileName + '_Deduplication.xlsx');
                setLoading(false);
                setPercentCompleted(0);
            }
            else {
                NotificationManager.success('No duplicates found', '', 2000);
                setLoading(false);
                setPercentCompleted(0);
            }
                      
        });
    }

    return (
        <Layout>
            <div className="row" style={{ height: "85vh" }}>
                <ChartsDrawer>
                    <OrgUnitFilterContext>
                        <OrgUnitFilter />                    
                        <br />
                        <SubmitFilters />
                    </OrgUnitFilterContext>
                    <hr />
                    <DedupReportPages />
                </ChartsDrawer>

                <ChartsContent>
                    
                    <div className="row">
                        <div className="col-md-12">
                            <div className={classes.progress} hidden={!loading}>
                                {percentCompleted === 0 &&
                                    <LinearProgress className={classes.progressBar} />
                                }
                                {percentCompleted > 0 &&
                                    <LinearProgress variant="determinate" className={classes.progressBar} value={percentCompleted} />
                                }
                            </div>
                        </div>
                        {/*
                        <div className="col-md-1">
                            <button onClick={loadFacilityData} className="border-0 bg-transparent float-right" >
                                <TiDownloadOutline className="color align-middle float-right" style={{ fontSize: '25px', padding: '0.2rem' }} />
                            </button>
                        </div>
                        */}
                    </div>
                    
                    <div className="chart-container">
                        <div className="row chart-row-100">
                            <div className="col-md-12">
                                <MetabaseChartTemplate resourceQuestion={109} title="Facility List" />
                            </div>
                            <Fab color="primary" className={classes.fab} onClick={loadFacilityData}>
                                <CloudDownload/> 
                            </Fab>
                        </div>
                    
                    </div>
                    
                </ChartsContent>
                
            </div>
                
        </Layout>
        )
}

export default DedupReportLayout1;