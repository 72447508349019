import { routerMiddleware, connectRouter } from 'connected-react-router'
import { createStore, compose, applyMiddleware, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk'
import userReducer from '../reducers/userReducer';

export default function configureStore(history) {
    const middleware = [
        thunk,
        routerMiddleware(history)
    ]

    const initialState = {}
    const enhancers = [];
    const windowIfDefined = typeof window === 'undefined' ? null : window;
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const rootReducer = combineReducers({
        user: userReducer,
        router: connectRouter(history)
    });

    const persistConfig = {
        key: 'root',
        storage: storageSession,
    }

    const persistedReducer = persistReducer(persistConfig, rootReducer)

    let store = createStore(
        persistedReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );

    let persistor = persistStore(store);

    return { store, persistor }

    /*return createStore(
        rootReducer,
          initialState,
          compose(applyMiddleware(...middleware), ...enhancers)
        )*/
}