import React, { Component } from 'react';
import MetabaseChartTemplate from '../../../Reports/Charts/MetabaseChartTemplate';
import Layout from '../../../Layout';
import HTSReportPages from './HTSReportPages';
import { DateRangeFilterContext } from '../../../Reports/Filters/DateRangeFilterContext';
import { OrgUnitFilterContext } from '../../../Reports/Filters/OrgUnitFilterContext';
import { DateRangeFilter } from '../../../Reports/Filters/DateRangeFilter';
import { SubmitFilters } from '../../../Reports/Filters/SubmitFilters';
import { OrgUnitFilter } from '../../../Reports/Filters/OrgUnitFilter';
import ChartsDrawer from '../ChartsDrawer';
import ChartsContent from '../ChartsContent';


class HTSLayout1 extends Component {

    state = {
        componentFilter: localStorage.getItem('componentFilter'),
        defaultStartDate: new Date("2023-04-01"),
        defaultEndDate: new Date("2024-03-31"),
        maxDate: new Date("2024-03-31")
    }

    render() {
        return (
            <Layout>               
                <div className="row" style={{ height: "85vh" }}>
                    <ChartsDrawer>
                        <OrgUnitFilterContext>
                            <DateRangeFilterContext>
                                    <OrgUnitFilter />                                    
                                    <DateRangeFilter defaultStartDate={this.state.defaultStartDate} defaultEndDate={this.state.defaultEndDate} />
                                    <SubmitFilters />
                            </DateRangeFilterContext>
                        </OrgUnitFilterContext>
                        <hr />
                        <HTSReportPages />
                    </ChartsDrawer>
                    <ChartsContent>
                        <div className="chart-container" >
                            <div className="row chart-row-50">
                                    <div className="col-md-4">
                                        <MetabaseChartTemplate resourceQuestion={9} title="Overall Results" />
                                    </div>
                                    <div className="col-md-4">
                                        <MetabaseChartTemplate resourceQuestion={15} title="Positivity Trend" />
                                    </div>
                                <div className="col-md-4">
                                    <MetabaseChartTemplate resourceQuestion={44} title="Overall Positivity" />
                                </div>
                            </div>
                            <br></br>
                            <div className="row chart-row-50">
                                <div className="col-md-6">
                                    <MetabaseChartTemplate resourceQuestion={11} title="Testing and Positivity" />
                                </div>
                                <div className="col-md-6">
                                    <MetabaseChartTemplate resourceQuestion={12} title="Testing and Positivity by Sex" />
                                </div>
                            </div>
                            <br></br>
                            <div className="row chart-row-50">
                                <div className="col-md-6">
                                    <MetabaseChartTemplate resourceQuestion={13} title="Testing and Positivity by Age Group" />
                                </div>
                                <div className="col-md-6">
                                    <MetabaseChartTemplate resourceQuestion={14} title="Testing by Sex and Age Group" />
                                </div>
                            </div>
                            <br></br>
                            <div className="row chart-row-50">
                                <div className="col-md-12">
                                    <MetabaseChartTemplate resourceQuestion={45} title="Positivity Trend by Sex" />
                                </div>
                            </div>
                        </div>
                    </ChartsContent>
                </div>
            </Layout>
        )
    }
}

export default HTSLayout1;