import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import * as NAVIGATION_TYPES from '../../actions/types';
import { ReportCard } from './ReportCard';

class UserPinnedReports extends Component {
    state = {
        userPinnedReports: []
    }

    componentDidMount() {

        this.props.OnPinUnpin(this.props.userId);

        if (this.props.startDate || this.props.endDate)
            this.props.ClearDynamicFilters();


        if (this.props.cohorts) {
            if (this.props.cohorts.length > 0) {
                this.props.ClearDynamicFilters();
            }      
        }    
    }

    render() {

        const reportsReturned = this.props.uprs.map(userPinnedReport => {
            return (
                <div key={userPinnedReport.reportId} className="col-md-3">
                    <ReportCard
                        reportId={userPinnedReport.reportId}
                        reportName={userPinnedReport.reportName}
                        reportDesc={userPinnedReport.reportShortDescription}
                        reportRoute={userPinnedReport.reportRoute}
                        userPinnedReportId={userPinnedReport.userPinnedReportId}
                        reportPinned={userPinnedReport.userPinned}
                    />
                </div>
            );
        });

        return (
            <div className="row mt-3">
                {reportsReturned}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        uprs: state.user.userPinnedReports,
        userId: state.user.user.sub,

        startDate: state.user.reportFilterStartDate,
        endDate: state.user.reportFilterEndDate,
        cohorts: state.user.reportFilterCohort
    };
};

const mapDispatchToProps = (dispatch) => {

    return {

        OnPinUnpin: (userId) => {
            axios.get('/api/userpinnedreports/userClaim/' + userId)
                .then(response => {
                    dispatch({
                        type: NAVIGATION_TYPES.PIN_UNPIN_SUCCESS,
                        payload: response.data
                    })
                })
                .catch(error => {
                    dispatch({
                        type: NAVIGATION_TYPES.PIN_UNPIN_FAILURE,
                        payload: error.message
                    })
                });
        },

        ClearDynamicFilters: () => {
            dispatch({
                type: NAVIGATION_TYPES.CLEAR_DYNAMIC_FILTERS,
                payload: null
            });
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPinnedReports);