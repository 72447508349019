import React, { Component } from 'react';
import Layout from '../../../Layout';
import HIVOutcomesReportPages from './HIVOutcomesReportPages';
import { DateRangeFilterContext } from '../../../Reports/Filters/DateRangeFilterContext';
import { OrgUnitFilterContext } from '../../../Reports/Filters/OrgUnitFilterContext';
//import { DateRangeFilter } from '../../../Reports/Filters/DateRangeFilter';
import { SubmitFilters } from '../../../Reports/Filters/SubmitFilters';
import { OrgUnitFilter } from '../../../Reports/Filters/OrgUnitFilter';
import ChartsDrawer from '../ChartsDrawer';
import ChartsContent from '../ChartsContent';
import ShinyChartTemplate from '../../../Reports/Charts/ShinyChartTemplate';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

class HIVOutcomesLayout1 extends Component {

    state = {
        componentFilter: localStorage.getItem('componentFilter'),
        //defaultStartDate: new Date("2020-07-01"),
        //defaultEndDate: new Date("2021-06-30"),
        //minDate: new Date("2019-01-01"),
        //maxDate: new Date("2021-06-30")
    }

    render() {        
        return (
            <Layout>               
                <div className="row" style={{ height: "85vh" }}>
                    <ChartsDrawer>
                        <OrgUnitFilterContext>
                            <DateRangeFilterContext>
                                <OrgUnitFilter 
                                    provinceDisable={false}
                                    districtDisable = {true}
                                    subDistrictDisable = {true}
                                    facilityDisable = {true}
                                                />
                                {/*<DateRangeFilter
                                    defaultStartDate={this.state.defaultStartDate}
                                    defaultEndDate={this.state.defaultEndDate}
                                    minDate={this.state.minDate}
                                    maxDate={this.state.maxDate}
                                />*/}
                                <SubmitFilters />
                            </DateRangeFilterContext>
                        </OrgUnitFilterContext>
                        <hr />
                        <HIVOutcomesReportPages />
                    </ChartsDrawer>
                    <ChartsContent>
                        <div className="chart-container">
                            <Tabs>
                                <TabList>
                                    <Tab>Sex</Tab>
                                    <Tab>Baseline Age</Tab>
                                    <Tab>Age and Sex</Tab>
                                    <Tab>YoA</Tab>
                                    <Tab>TBHIV</Tab>
                                    <Tab>pART</Tab>
                                    <Tab>bCD4</Tab>
                                    <Tab>Disease Stage</Tab>
                                    <Tab>Switched Regimen</Tab>
                                    <Tab>Pregnancy</Tab>
                                    <Tab>District</Tab>
                                </TabList>
                                <TabPanel>
                                    <div className="row chart-row-tab-50">
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/retention_sex"} title="Retention by Sex" />
                                        </div>
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/mortality_sex"} title="Mortality by Sex" />
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row chart-row-tab-50">
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/ltfu_sex"} title="LTFu by Sex" />
                                        </div>
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/rml_table_sex"} title="RML Table" />
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="row chart-row-tab-50">
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/m12retention_bage"} title="12-month Retention Rate by Baseline Age" />
                                        </div>
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/m12mortality_bage"} title="12-month Mortality Rate by Baseline Age" />
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row chart-row-tab-50">
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/m12ltfu_bage"} title="12-month LTFu Rate by Baseline Age" />
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="row chart-row-tab-50">
                                        <div className="col-md-12">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/m12ltfu_agesex"} title="12-month LTFu Rate by Baseline Age and Sex" />
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row chart-row-tab-50">
                                        <div className="col-md-12">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/m12mortality_agesex"} title="12month Mortality Rate by Baseline Age and Sex" />
                                        </div>
                                    </div>
                                </TabPanel>
                                
                                <TabPanel>
                                    <div className="row chart-row-tab-100">
                                        <div className="col-md-7">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/rml_yoa"} title="12-month Outcome Rates by ART Cohort" />
                                        </div>
                                        <div className="col-md-5">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/rml_yoa_table"} title="12-month Outcome Rates by ART Cohort" />
                                        </div>
                                    </div>
                                </TabPanel>

                                {/*TBHIV*/}
                                <TabPanel>
                                    <div className="row chart-row-tab-50">
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/retention_tbhiv"} title="Retention by TBHIV" />
                                        </div>
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/mortality_tbhiv"} title="Mortality by TBHIV" />
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row chart-row-tab-50">
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/ltfu_tbhiv"} title="LTFu by TBHIV" />
                                        </div>
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/rml_tbhiv_table"} title="RML Table" />
                                        </div>
                                    </div>
                                </TabPanel>
                                {/*pARTT */}
                                <TabPanel>
                                    <div className="row chart-row-tab-50">
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/retention_part"} title="Retention by Prior ART" />
                                        </div>
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/mortality_part"} title="Mortality by Prior ART" />
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row chart-row-tab-50">
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/ltfu_part"} title="LTFu by Prior ART" />
                                        </div>
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/rml_part_table"} title="RML Table" />
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="row chart-row-tab-50">
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/retention_bcd4"} title="Retention by bCD4" />
                                        </div>
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/mortality_bcd4"} title="Mortality by bCD4" />
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row chart-row-tab-50">
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/ltfu_bcd4"} title="LTFu by bCD4" />
                                        </div>
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/rml_table_bcd4"} title="RML Table" />
                                        </div>
                                    </div>
                                </TabPanel>

                                {/* Disease Stage */}
                                <TabPanel>
                                    <div className="row chart-row-tab-50">
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/retention_disease_stage"} title="Retention by Disease Stage" />
                                        </div>
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/mortality_disease_stage"} title="Mortality by Disease Stage" />
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row chart-row-tab-50">
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/ltfu_disease_stage"} title="LTFu by Disease Stage" />
                                        </div>
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/rml_disease_stage_table"} title="RML Table" />
                                        </div>
                                    </div>
                                </TabPanel>

                                {/* Switched Regimen */}
                                <TabPanel>
                                <div className="row chart-row-tab-50">
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/retention_regimen"} title="Retention by Switch to 2nd Line" />
                                        </div>
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/mortality_regimen"} title="Mortality by Switch to 2nd Line" />
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row chart-row-tab-50">
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/ltfu_regimen"} title="LTFu by Switch to 2nd Line" />
                                        </div>
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/rml_regimen_table"} title="RML Table" />
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="row chart-row-tab-50">
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/retention_pregnancy"} title="Retention by Pregnancy Status" />
                                        </div>
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/mortality_pregnancy"} title="Mortality by Pregnancy Status" />
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row chart-row-tab-50">
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/ltfu_pregnancy"} title="LTFu by Pregnancy Status" />
                                        </div>
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/rml_pregnancy_table"} title="RML Table" />
                                        </div>
                                    </div>
                                </TabPanel>
                                
                                {/* District */}
                                <TabPanel>
                                    <div className="row chart-row-tab-50">
                                    <div className="col-md-6">
                                        <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/retention_district"} title="Retention by District" />
                                    </div>
                                    <div className="col-md-6">
                                        <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/mortality_district"} title="Mortality by District" />
                                    </div>
                                    </div>
                                    <br></br>
                                    <div className="row chart-row-tab-50">
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/ltfu_district"} title="LTFu by District" />
                                        </div>
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/rml_district_table"} title="RML by District" />
                                        </div>
                                    </div>
                                </TabPanel>
                            
                            </Tabs>
                        </div>
                    </ChartsContent>
                </div>
            </Layout>
        )
    }
}

export default HIVOutcomesLayout1;