import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

const HandleStartDateContext = React.createContext();
const HandleEndDateContext = React.createContext();

const StartDateContext = React.createContext();
const EndDateContext = React.createContext();

export function useHandleStartDateContext() {
    return useContext(HandleStartDateContext);
}

export function useHandleEndDateContext() {
    return useContext(HandleEndDateContext);
}

export function useStartDateContext() {
    return useContext(StartDateContext);
}

export function useEndDateContext() {
    return useContext(EndDateContext);
}

export function DateRangeFilterContext({ children }) {

    const _startDate = useSelector(state => state.user.reportFilterStartDate);
    const _endDate = useSelector(state => state.user.reportFilterEndDate);

    const [startDate, setStartDate] = useState(_startDate);
    const [endDate, setEndDate] = useState(_endDate);

    const handleStartDateChange = (date) => {        
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {     
        setEndDate(date);
    };

    return (
        <HandleStartDateContext.Provider value={handleStartDateChange}>
            <HandleEndDateContext.Provider value={handleEndDateChange}>
                <StartDateContext.Provider value={startDate}>
                    <EndDateContext.Provider value={endDate}>
                        {children}
                    </EndDateContext.Provider>
                </StartDateContext.Provider>
            </HandleEndDateContext.Provider>
        </HandleStartDateContext.Provider>
        
        );
}