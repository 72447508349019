import React, { Component } from 'react';
import MetabaseChartTemplate from '../../../Reports/Charts/MetabaseChartTemplate';
import Layout from '../../../Layout';
import HtsReportPages from './HtsReportPages';
import { OrgUnitFilterContext } from '../../../Reports/Filters/OrgUnitFilterContext';
import { SubmitFilters } from '../../../Reports/Filters/SubmitFilters';
import { OrgUnitFilter } from '../../../Reports/Filters/OrgUnitFilter';
import ChartsDrawer from '../ChartsDrawer';
import ChartsContent from '../ChartsContent';

class Hts1 extends Component {
	state = {
		componentFilter: localStorage.getItem('componentFilter'),
	};
	render() {
		return (
			<Layout>
				<div className="row" style={{ height: '85vh' }}>
						<ChartsDrawer>
							<OrgUnitFilterContext>
									<OrgUnitFilter />
									<SubmitFilters />
							</OrgUnitFilterContext>
							<hr />
							<HtsReportPages />
						</ChartsDrawer>
					<ChartsContent>
						<div className="chart-container" style={{ height: '85vh' }}>
							<div className="row" style={{ height: '41vh' }}>
								<div className="col-md-4">
									<MetabaseChartTemplate 
                                        resourceQuestion={124} 
                                        title="HTS_TST Quarterly Change"
                                    />
								</div>
                                <div className="col-md-8">
									<MetabaseChartTemplate 
                                        resourceQuestion={125} 
                                        title="HIV Testing and Yield by Sex Over Time"
                                    />
								</div>
                            </div> 
                            <br></br>   
                            <div className="row" style={{ height: '41vh' }}>
								<div className="col-md-4">
									<MetabaseChartTemplate 
                                        resourceQuestion={126} 
                                        title="HTS_TST by Result in the Last Quarter"
                                    />
								</div>
                                <div className="col-md-8">
									<MetabaseChartTemplate 
                                        resourceQuestion={127} 
                                        title="HIV Testing Yield by Age Group and Sex at the Last Quarter"
                                    />
								</div>
                            </div> 
						</div>
					</ChartsContent>
				</div>
			</Layout>
		);
	}
}

export default Hts1;
