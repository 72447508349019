import React, { useState } from 'react';
import { FormGroup, Label, Container, Navbar, NavbarBrand, } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import { useHistory } from 'react-router'

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const history = useHistory();

    const goToLogin = () => {
        history.push("/login");
    };

    const handleSubmit = () => {

        axios.post('/api/users/passwordresetlink/' + email)
            .then(response => {
                NotificationManager.success('Password Reset link successfully sent to your email!', '', 3000);
                goToLogin();
            })
            .catch(error => {
                console.log('ERROR : ' + error.message);
                NotificationManager.error('Error occured!', '', 2000);
            })
    }

    return (

        <div>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3 bg-light" light>
                <Container fluid>
                    <NavbarBrand to="/login">
                        <img src="images/rsa-coat-of-arms.png" width="30" height="30" alt="" />
                                CHISA
                            </NavbarBrand>
                </Container>
            </Navbar>

            <AvForm onValidSubmit={handleSubmit}>
                <div className="container">
                    
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label className="color" htmlFor="email">Email Address:</Label>
                            <AvField
                                name="email"
                                type="email"
                                className="form-control-sm form-control"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                errorMessage="Email is required"
                            />

                        </FormGroup>     
                        <button type="submit" className="btn btn-infohub" >Submit</button>
                    </div>
                </div>
            </AvForm>
            </div>
        );
}

export default connect()(ForgotPassword);