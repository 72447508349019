import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import * as NAVIGATION_TYPES from '../actions/types';

class UserMenu extends Component {

    setComponentFilterState = (value, userId) => {
        localStorage.setItem('componentFilter', value);
        localStorage.setItem('updateUserId', userId);
        localStorage.setItem('userId', userId);
        
    }

    render(){
        return (
            <div className="container mt-3" style={{ borderRight: "1px solid #e1e1e1", height: "100%" }}>
                <div className="justify-content-end pr-3">                   
                    <div className="row justify-content-end text-right"><NavLink to="/manageusers" activeClassName="activeNavLink" onClick={() => this.setComponentFilterState(NAVIGATION_TYPES.USERS_LIST,'')}>Manage Existing User</NavLink></div>
                    <div className="row justify-content-end text-right"><NavLink to="/registeruser" activeClassName="activeNavLink" onClick={() => this.setComponentFilterState(NAVIGATION_TYPES.REGISTER_USER,'')}>Register New User</NavLink></div>                    
                </div>
            </div>
        )
    }
}

export default UserMenu;