import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';

class DedupReportPages extends Component {
    render() {
        return (
            <div className="container">
                <div className="row justify-content-end text-right">
                    <NavLink to="/charts/dedup/1" activeClassName="activeNavLink" className="report-page">
                        Facility Lists
                    </NavLink>
                </div>
                {/* <div className="row justify-content-end text-right">
                    <NavLink to="/charts/dedup/2" activeClassName="activeNavLink" className="report-page">
                        Deduplication Progress
                    </NavLink>
                </div> */}
            </div>
        )
    }
}

export default DedupReportPages;