import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

const HandleYearContext = React.createContext();

const YearContext = React.createContext();

export function useHandleYearContext() {
    return useContext(HandleYearContext);
}

export function useYearContext() {
    return useContext(YearContext);
}


export function YearFilterContext({ children }) {

    const _year = useSelector(state => state.user.reportFilterYear);
    const [year, setYear] = useState(_year);

    const handleYearChange = (val) => {
        setYear(val);
    };

    return (
        <HandleYearContext.Provider value={handleYearChange}>
            <YearContext.Provider value={year}>
                {children}
            </YearContext.Provider>
        </HandleYearContext.Provider>

    );
}