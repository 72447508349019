import React, { Component } from 'react';
import RegisterUser from '../RegisterUser';
//import Metabase from '../Layout/LayoutMetabaseCharts';
import Layout from '../Layout';
import UserMenu from '../UserMenu';
import UsersList from '../UsersList';
import ReportsFilter from '../Reports/ReportsFilter';
import Reports from '../Reports/Reports';
import UserPinnedReports from '../Reports/UserPinnedReports';
import ReportsSideMenu from '../Reports/ReportsSideMenu';
import ReportGroup from '../Reports/ReportManagement/ReportGroup';
import ReportSource from '../Reports/ReportManagement/ReportSource';
import ReportSourceSetting from '../Reports/ReportManagement/ReportSourceSetting';
import Report from '../Reports/ReportManagement/Report';
import ReportManagementMenu from '../Reports/ReportManagement/ReportManagementMenu';
import * as NAVIGATION_TYPES from '../../actions/types';
import ChangePassword from '../ChangePassword';
import SystemSettings from '../SystemSettings';
import SystemSettingsMenu from '../SystemSettingsMenu';
//import testfile from '../Reports/testfile';

class LayoutSecondary extends Component {

    state = {
        componentFilter: localStorage.getItem('componentFilter')
    }

    render() {
        
        return (
            <Layout>
                <div className="row mb-5">
                    <div className="col-md-2">

                        {this.state.componentFilter === NAVIGATION_TYPES.MANAGE_USER &&
                            <UserMenu />
                        }

                        {this.state.componentFilter === NAVIGATION_TYPES.USERS_LIST &&
                            <UserMenu />
                        }

                        {this.state.componentFilter === NAVIGATION_TYPES.REGISTER_USER &&
                            <UserMenu />
                        }

                        {this.state.componentFilter === NAVIGATION_TYPES.REGISTERED_USER &&
                            <UserMenu />
                        }

                        {this.state.componentFilter === NAVIGATION_TYPES.REPORTS &&
                            <ReportsSideMenu />
                        }

                        {this.state.componentFilter === NAVIGATION_TYPES.USER_PINNED_REPORTS &&
                            <ReportsSideMenu />
                        }

                        {this.state.componentFilter === NAVIGATION_TYPES.REPORT_GROUP &&
                            <ReportManagementMenu />
                        }

                        {this.state.componentFilter === NAVIGATION_TYPES.REPORT_SOURCE &&
                            <ReportManagementMenu />
                        }

                        {this.state.componentFilter === NAVIGATION_TYPES.REPORT_SOURCE_SETTING &&
                            <ReportManagementMenu />
                        }

                        {this.state.componentFilter === NAVIGATION_TYPES.REPORT &&
                            <ReportManagementMenu />
                        }

                        {this.state.componentFilter === NAVIGATION_TYPES.METABASE &&
                            <ReportsFilter />
                        }

                        {this.state.componentFilter === NAVIGATION_TYPES.SYSTEM_SETTINGS &&
                            <SystemSettingsMenu />
                        }
                    </div>
                    <div className="col-md-10 justify-content-start">
                        {this.state.componentFilter === NAVIGATION_TYPES.MANAGE_USER &&
                            <UsersList />
                        }

                        {this.state.componentFilter === NAVIGATION_TYPES.USERS_LIST &&
                            <UsersList />
                        }

                        {this.state.componentFilter === NAVIGATION_TYPES.REGISTER_USER &&
                            <RegisterUser />
                        }

                        {this.state.componentFilter === NAVIGATION_TYPES.REGISTERED_USER &&
                            <RegisterUser />
                        }

                        {this.state.componentFilter === NAVIGATION_TYPES.REPORTS &&
                            <Reports />
                        }

                        {this.state.componentFilter === NAVIGATION_TYPES.USER_PINNED_REPORTS &&
                            <UserPinnedReports />
                        }

                        {this.state.componentFilter === NAVIGATION_TYPES.REPORT_GROUP &&
                            <ReportGroup />
                        }

                        {this.state.componentFilter === NAVIGATION_TYPES.REPORT_SOURCE &&
                            <ReportSource />
                        }

                        {this.state.componentFilter === NAVIGATION_TYPES.REPORT_SOURCE_SETTING &&
                            <ReportSourceSetting />
                        }

                        {this.state.componentFilter === NAVIGATION_TYPES.REPORT &&
                            <Report />
                        }

                        {/*this.state.componentFilter === NAVIGATION_TYPES.METABASE &&
                            <Metabase />*/
                        }  

                        {this.state.componentFilter === NAVIGATION_TYPES.CHANGE_PWD &&
                            <ChangePassword />
                        }

                        {this.state.componentFilter === NAVIGATION_TYPES.SYSTEM_SETTINGS &&
                            <SystemSettings />
                        }
                    </div>
                </div>
            </Layout>
        )
    }    
}

export default LayoutSecondary;