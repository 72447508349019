import React, { Component } from 'react';
import MetabaseChartTemplate from '../../../Reports/Charts/MetabaseChartTemplate';
import Layout from '../../../Layout';
import TBOutcomesReportPages from '../TBOutcomes/TBOutcomesReportPages';
import { OrgUnitFilterContext } from '../../../Reports/Filters/OrgUnitFilterContext';
import { SubmitFilters } from '../../../Reports/Filters/SubmitFilters';
import { OrgUnitFilter } from '../../../Reports/Filters/OrgUnitFilter';
import { CohortFilterContext } from '../../../Reports/Filters/CohortFilterContext';
import { CohortFilter } from '../../../Reports/Filters/CohortFilter';
import ChartsDrawer from '../ChartsDrawer';
import ChartsContent from '../ChartsContent';

class TBOutcomesLayout1 extends Component {
    state = {
        componentFilter: localStorage.getItem('componentFilter'),
        defaultCohort: [2022]
    }
    render() {
        return (
            <Layout>
                <div className="row" style={{ height: "85vh" }}>
                    <ChartsDrawer>
                        <OrgUnitFilterContext>
                            <CohortFilterContext>
                                    <OrgUnitFilter />
                                    <CohortFilter label="Year" defaultCohort={this.state.defaultCohort} />     
                                    <br />
                                    <SubmitFilters />
                            </CohortFilterContext>
                        </OrgUnitFilterContext>
                        <hr />
                        <TBOutcomesReportPages />
                    </ChartsDrawer>
                    <ChartsContent>
                        <div className="chart-container">
                            <div className="row chart-row-50">
								<div className="col-md-6">
									<MetabaseChartTemplate 
                                        resourceQuestion={87} 
                                        title="Total TB Outcomes"
                                    />
								</div>
								<div className="col-md-6">
                                    <MetabaseChartTemplate 
                                        resourceQuestion={89} 
                                        title="Total TB Outcomes by Patient Category"
                                    />
                                </div>
							</div>
                            <br />
                            <div className="row chart-row-50">
								<div className="col-md-6">
									<MetabaseChartTemplate 
                                        resourceQuestion={94} 
                                        title="Quarterly %TB Outcomes"
                                    />
								</div>
                                <div className="col-md-6">
                                    <MetabaseChartTemplate 
                                            resourceQuestion={90} 
                                            title="Total TB Outcomes by Sex"
                                    />
                                </div>
                            </div>
                            <br />
                            <div className="row chart-row-50">
								<div className="col-md-6">
									<MetabaseChartTemplate 
                                        resourceQuestion={95} 
                                        title="Total TB Outcomes by Age"
                                    />
								</div>
                                <div className="col-md-6">
									<MetabaseChartTemplate 
                                        resourceQuestion={96} 
                                        title="%TB Outcomes by Age"
                                    />
								</div>
                            </div>
                            <br />
                            <div className="row chart-row-50">
								<div className="col-md-4">
									<MetabaseChartTemplate 
                                        resourceQuestion={97} 
                                        title="Quarterly: %New TB Outcomes"
                                    />
								</div>
                                <div className="col-md-4">
									<MetabaseChartTemplate 
                                        resourceQuestion={98} 
                                        title="Quarterly: %Relapse TB Outcomes"
                                    />
								</div>
                                <div className="col-md-4">
									<MetabaseChartTemplate 
                                        resourceQuestion={99} 
                                        title="Quarterly: %Retreatment/Other TB Outcomes"
                                    />
								</div>
                            </div>
                            <br />
                            <div className="row chart-row-50">
								<div className="col-md-6">
									<MetabaseChartTemplate 
                                        resourceQuestion={100} 
                                        title="Quarterly: %TB Outcomes - Females"
                                    />
								</div>
                                <div className="col-md-6">
									<MetabaseChartTemplate 
                                        resourceQuestion={101} 
                                        title="Quarterly: %TB Outcomes - Males"
                                    />
								</div>
                            </div>
                        </div>
                    </ChartsContent>
                </div>
            </Layout>
        )
    }
}

export default TBOutcomesLayout1;