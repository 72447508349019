import React, { Component } from 'react';
import { FormGroup, Label, Table, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { NotificationManager } from 'react-notifications';

class ReportSourceSetting extends Component {

    getReportSourceId = thePath => {
        return parseInt(thePath.substring(thePath.lastIndexOf('/') + 1));
    }


    state = {
        reportSourceId: this.getReportSourceId(window.location.href),
        reportSourceName: '',
        reportSourceSettingId: 0,
        reportSourceSettingKey: '',
        reportSourceSettingValue: '',
        reportSourceSettings: [],
        componentDidUpdateCheck: 0,
        saveUpdateDelete: 'Add',
        modal: false
    }

    resetValues = () => {
        this.setState({ reportSourceSettingId: 0 });
        this.setState({ reportSourceSettingKey: '' });
        this.setState({ reportSourceSettingValue: '' });
        this.setState({ componentDidUpdateCheck: 0 });
        this.setState({ saveUpdateDelete: 'Add' });
    }

    


    handleSubmit = () => {

        const data = {
            reportSourceId: this.state.reportSourceId,
            key: this.state.reportSourceSettingKey,
            value: this.state.reportSourceSettingValue
        };

        if (this.state.saveUpdateDelete === 'Add') {

            axios.post('/api/reportsourcesettings/add', data)
                .then(response => {
                    this.setState({ componentDidUpdateCheck: 1 });
                    this.toggle();
                    NotificationManager.success('Saved Successfully!', '', 2000);
                })
                .catch(error => {
                    console.log(error);
                    NotificationManager.error('An error occured!', '', 2000);
                })
        }

        else if (this.state.saveUpdateDelete === 'Update') {

            axios.post('/api/reportsourcesettings/update/' + this.state.reportSourceSettingId, data)
                .then(response => {
                    this.setState({ componentDidUpdateCheck: 1 });
                    this.toggle();
                    NotificationManager.success('Updated Successfully!', '', 2000);
                })
                .catch(error => {
                    console.log(error);
                    NotificationManager.error('An error occured!', '', 2000);
                })
        }
        else if (this.state.saveUpdateDelete === 'Delete') {

            axios.post('/api/reportsourcesettings/delete/' + this.state.reportSourceSettingId)
                .then(response => {
                    this.setState({ componentDidUpdateCheck: 1 });
                    this.toggle();
                    NotificationManager.success('Deleted Successfully!', '', 2000);
                })
                .catch(error => {
                    console.log(error);
                    NotificationManager.error('An error occured!', '', 2000);
                })
        }
    }

    componentDidMount() {
        axios.get('/api/reportsourcesettings/' + this.state.reportSourceId)
            .then(response => {
                this.setState({ reportSourceSettings: response.data });
            });

        axios.get('/api/reportsources/' + this.state.reportSourceId)
            .then(response => {
                this.setState({ reportSourceName: response.data.reportSourceName });
            });
    }

    componentDidUpdate() {
        
        if (this.state.componentDidUpdateCheck > 0) {
            axios.get('/api/reportsourcesettings/' + this.state.reportSourceId)
                .then(response => {
                    this.setState({ reportSourceSettings: response.data });
                });

            this.setState({ componentDidUpdateCheck: 0 });
        }
    }

    getReportSourceSetting = (reportSourceSetting, action) => {
        this.resetValues();
        this.toggle();
        this.setState({ reportSourceSettingId: reportSourceSetting.reportSourceSettingId })
        this.setState({ reportSourceSettingKey: reportSourceSetting.key });
        this.setState({ reportSourceSettingValue: reportSourceSetting.value });
        this.setState({ saveUpdateDelete: action });
    }

    toggle = () => {
        this.setState({ modal: !this.state.modal });
    }

    


    render() {
        const reportSourceSettingsReturned = this.state.reportSourceSettings.map(reportSourceSetting => {
            return <tr key={reportSourceSetting.reportSourceSettingId}>
                <td>{reportSourceSetting.key}</td>
                <td>{reportSourceSetting.value}</td>
                <td><NavLink to={"/reportsourcesetting/" + this.state.reportSourceId} onClick={() => this.getReportSourceSetting(reportSourceSetting, "Update")} >Edit</NavLink></td>
                <td><NavLink to={"/reportsourcesetting/" + this.state.reportSourceId} onClick={() => this.getReportSourceSetting(reportSourceSetting, "Delete")} >Delete</NavLink></td>
            </tr>;

        });

        let classNameHeader = 'color';
        let classNameBtn = 'btn btn-infohub w-100';
        if (this.state.saveUpdateDelete === "Delete") {
            classNameHeader = 'deleteColor';
            classNameBtn = 'btn btn-danger w-100';
        }

        return (
            <div>

                <Modal isOpen={this.state.modal} toggle={this.toggle} >
                    <ModalHeader toggle={this.toggle} className={classNameHeader} >{this.state.saveUpdateDelete + " Report Source Setting"}</ModalHeader>
                    <ModalBody>
                        <AvForm onValidSubmit={this.handleSubmit}>
                            <div className="row">
                                <div className="col-md-12">
                                    <FormGroup>
                                        <Label className="color">Key:</Label>
                                        <AvField
                                            name="rssKey"
                                            type="text"
                                            className="form-control-sm form-control"
                                            placeholder="Report source setting key"
                                            value={this.state.reportSourceSettingKey}
                                            onChange={(e) => this.setState({ reportSourceSettingKey: e.target.value })}
                                            required
                                            errorMessage="Report source setting key is required" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="color">Value:</Label>
                                        <AvField
                                            name="rssValue"
                                            type="text"
                                            className="form-control-sm form-control"
                                            placeholder="Report source setting value"
                                            value={this.state.reportSourceSettingValue}
                                            onChange={(e) => this.setState({ reportSourceSettingValue: e.target.value })}
                                            required
                                            errorMessage="Report source setting value is required" />
                                    </FormGroup>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-6">
                                    <button type="submit" className={classNameBtn} >{this.state.saveUpdateDelete}</button>
                                </div>
                                <div className="col-md-6">
                                    <button type="reset" className="btn btn-secondary w-100" onClick={this.toggle} >Cancel</button>
                                </div>

                            </div>

                        </AvForm>
                    </ModalBody>

                </Modal>


                <div className="row">
                    <h5 className="color">{'Report Source : ' + this.state.reportSourceName}</h5>
                </div>
                <div className="row">
                    <div className="col-md-10"></div>
                    <div>
                        <button
                            type="submit"
                            style={{
                                backgroundColor: "transparent",
                                outline: "none",
                                border: "none"
                            }}
                            onClick={() => { this.resetValues(); this.toggle(); }} >
                            <AiOutlinePlusCircle className="color" style={{ fontSize: "45px" }} />
                        </button>

                        {/*<button type="submit" className="btn btn-infohub w-100" onClick={() => { this.resetValues(); this.toggle(); }} >Add Report Source setting</button>*/}
                    </div>
                </div>

                <div className="row">
                    <Table striped>
                        <thead>
                            <tr>
                                <th className="color">Key</th>
                                <th className="color">Value</th>
                                <th className="color"></th>
                                <th className="color"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {reportSourceSettingsReturned}

                        </tbody>
                    </Table>
                </div>

            </div>
        )
    }
}

export default ReportSourceSetting;