import * as React from 'react';
import Layout from './Layout';

const Help = () => (
    <Layout>

    </Layout>
);

export default Help;
