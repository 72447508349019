import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme, withStyles, createStyles } from '@material-ui/core/styles';
import { Chip, Input, InputLabel, FormControl, MenuItem, Select } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHandleCohortContext, useCohortContext } from './CohortFilterContext';
import * as ACTION_TYPE from '../../../actions/types';

const useStyles = makeStyles(() => ({
    formControl: {
        width: '100%'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
            width: 250,
            
        }
    },
};

function getStyles(name, cohort, theme) {
    return {
        fontWeight:
            cohort.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const CustomMenuItem = withStyles((theme) =>
	createStyles({
		root: {
			"&$selected": {
				backgroundColor: "grey",
				"&:hover": {
					backgroundColor: "grey"
				}
			},
			"&:hover": {
				backgroundColor: "lightgrey"
			}
		},
		selected: {}
	})
)(MenuItem);

export const CohortFilter = (props) => {
    const dispatch = useDispatch();
    const handleCohortContext = useHandleCohortContext();
    const selectedCohorts = useCohortContext();
    const classes = useStyles();
    const theme = useTheme();
    const [cohorts, setCohorts] = useState([]);

    const label = props.label ? props.label : 'Cohorts';

    const [firstTimeLoad, setFirstTimeLoad] = useState(true);

    if (firstTimeLoad) {
        if (selectedCohorts.length === 0) {

            handleCohortContext(props.defaultCohort ? props.defaultCohort : []);

            dispatch({
                type: ACTION_TYPE.REPORT_COHORT,
                payload: props.defaultCohort ? props.defaultCohort : []
            })
        }

        setFirstTimeLoad(false);
    }


    useEffect(() => {
        let years = [];
        const currentYear = new Date().getFullYear();
        for (let year = 2010; year < currentYear + 1; year++) {
            years.push(year);
        }
        setCohorts(years);
    }, []);

    const handleChange = (event) => {
        handleCohortContext(event.target.value);
    };

    return (
        <div className="container">
            <FormControl className={classes.formControl}>
                <InputLabel id="cohort_select">{label}</InputLabel>
                <Select
                    labelId="cohort_select"
                    id="cohort_select"
                    multiple
                    value={selectedCohorts}
                    onChange={handleChange}
                    input={<Input id="select-multiple-cohort" />}
                    renderValue={(selected) => (
                        <div className={classes.chips}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} className={classes.chip} />
                            ))}
                        </div>
                    )}
                    MenuProps={MenuProps}
                >
                    {cohorts.map((cohort) => (
                        <CustomMenuItem key={cohort} value={cohort} style={getStyles(cohort, selectedCohorts, theme)}>
                            {cohort}
                        </CustomMenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}