import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';

class ExpandedTBOutcomesReportPages extends Component {
    render() {
        return (
            <div className="container">
                <div className="row justify-content-end text-right">
                    <NavLink to="/charts/expanded_tb_outcomes/1" activeClassName="activeNavLink" className="report-page">
                        Descriptive Characteristics of TB Patients
                    </NavLink>
                </div>
                <div className="row justify-content-end text-right">
                    <NavLink to="/charts/expanded_tb_outcomes/2" activeClassName="activeNavLink" className="report-page">
                        TB Treatment Outcomes
                    </NavLink>
                </div>
                <div className="row justify-content-end text-right">
                    <NavLink to="/charts/expanded_tb_outcomes/3" activeClassName="activeNavLink" className="report-page">
                        Stratified Analysis
                    </NavLink>
                </div>
            </div>
        )
    }
}

export default ExpandedTBOutcomesReportPages;