//import store from '../store';
//import configureStore from '../store';
//import * as ACTION_TYPES from "../actions/types";
//import { createBrowserHistory } from 'history';


//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
//const history = createBrowserHistory({ basename: baseUrl });
//const { dispatch } = configureStore(history);

export function handleResponse(response: { text: () => Promise<any>; ok: any; statusText: any; }) {
    return response.text().then(text => {
        const data = text;
        if (!response.ok) {
            /*dispatch({
                type: ACTION_TYPES.AUTHENTICATION_ERROR,
                payload: "Error Authenticating"
            });*/
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}