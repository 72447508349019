import React from 'react';

const Checkbox = (props) => (
   
    <div className="checkbox pl-1">
        <input
            type="checkbox"
            value={props.val}
            onChange={props.onChange}
            checked={props.rptClaims.includes(props.val.toString())}
        />
        <label className="pl-1">{props.txt}</label><br />
    </div>
);

export default Checkbox;