import React, { Component } from 'react';
import MetabaseChartTemplate from '../../../Reports/Charts/MetabaseChartTemplate';
import Layout from '../../../Layout';
import TBOutcomesReportPages from '../TBOutcomes/TBOutcomesReportPages';
import { OrgUnitFilterContext } from '../../../Reports/Filters/OrgUnitFilterContext';
import { SubmitFilters } from '../../../Reports/Filters/SubmitFilters';
import { OrgUnitFilter } from '../../../Reports/Filters/OrgUnitFilter';
import { CohortFilterContext } from '../../../Reports/Filters/CohortFilterContext';
import { CohortFilter } from '../../../Reports/Filters/CohortFilter';
import ChartsDrawer from '../ChartsDrawer';
import ChartsContent from '../ChartsContent';

class TBOutcomesLayout2 extends Component {
    state = {
        componentFilter: localStorage.getItem('componentFilter'),
        defaultCohort: [2021]
    }
    render() {
        return (
            <Layout>
                <div className="row" style={{ height: "85vh" }}>
                    <ChartsDrawer>
                        <OrgUnitFilterContext>
                            <CohortFilterContext>
                                    <OrgUnitFilter />
                                    <CohortFilter label="Year" defaultCohort={this.state.defaultCohort} />     
                                    <br />
                                    <SubmitFilters />
                            </CohortFilterContext>
                        </OrgUnitFilterContext>
                        <hr />
                        <TBOutcomesReportPages />
                    </ChartsDrawer>
                    <ChartsContent>
                        <div className="chart-container">
                            <div className="row chart-row-50">
								<div className="col-md-4">
									<MetabaseChartTemplate 
                                        resourceQuestion={160} 
                                        title="Total Cohort by TB Patient Category"
                                    />
								</div>
								<div className="col-md-4">
                                    <MetabaseChartTemplate 
                                        resourceQuestion={161} 
                                        title="Total Cohort by HIV Status"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <MetabaseChartTemplate 
                                        resourceQuestion={162} 
                                        title="Total on ART"
                                    />
                                </div>
							</div>
                            <br />
                            <div className="row chart-row-50">
								<div className="col-md-6">
									<MetabaseChartTemplate 
                                        resourceQuestion={157} 
                                        title="TB Outcomes by HIV Status"
                                    />
								</div>
                                
                                <div className="col-md-6">
                                    <MetabaseChartTemplate 
                                            resourceQuestion={159} 
                                            title="TB Outcomes among Co-infected Patients by Quarter"
                                    />
                                </div>
                            </div>
                            <br />
                            <div className="row chart-row-50">
								<div className="col-md-6">
                                    <MetabaseChartTemplate 
                                            resourceQuestion={165} 
                                            title="HIV Positive, TB Patients on ART vs Not on ART"
                                    />
                                </div>
                                <div className="col-md-6">
									<MetabaseChartTemplate 
                                        resourceQuestion={166} 
                                        title="TB Outcomes by ART Status"
                                    />
								</div>

                            </div>
                            <br />
                            <div className="row chart-row-50">
								<div className="col-md-12">
									<MetabaseChartTemplate 
                                        resourceQuestion={164} 
                                        title="TB/HIV Co-infection Summary"
                                    />
                                </div>
                            </div>
                        </div>
                    </ChartsContent>
                </div>
            </Layout>
        )
    }
}

export default TBOutcomesLayout2;