import * as React from 'react';
//import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
//import { Link } from 'react-router-dom';
import { Navbar } from 'reactstrap';
import './NavMenu.css';

export default class Footer extends React.PureComponent<{}, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    public render() {
        return (
            /*<Navbar className="fixed-bottom bg-light" light >*/           
            <Navbar className="fixed-bottom bg-light justify-content-center" light style={{ zIndex: 2 }}>
                <small style={{textAlign: 'center', color: '#02642e'}}>© {new Date().getFullYear()} Copyright. National Department of Health</small>
            </Navbar>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
