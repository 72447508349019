import React, { Component } from 'react';
import { Container, Navbar, NavbarBrand } from 'reactstrap';

import ChangePassword from './ChangePassword';

class ChangePwdOnLogin extends Component {

    render() {

        return (
            <div>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3 bg-light" light>
                    <Container fluid>
                        <NavbarBrand>
                            <img src="images/rsa-coat-of-arms.png" width="30" height="30" alt="" />
                                CHISA
                            </NavbarBrand>
                    </Container>
                </Navbar>

                <div className="container align-self-center">
                    <ChangePassword />
                </div>
            </div>
        )
    }
}

export default ChangePwdOnLogin;