import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import * as NAVIGATION_TYPES from '../../actions/types';

class ReportsSideMenu extends Component {

    state = {
        reportGroups: [],
    }

    componentDidMount() {
        axios.get('/api/reportgroups/user/' + this.props.userId)
            .then(response => {
                this.setState({ reportGroups: response.data });
            });

        this.props.OnPinUnpin(this.props.userId);
    }

    setComponentFilterState = (value, reportGroupId) => {
        localStorage.setItem('componentFilter', value);
        localStorage.setItem('reportGroupId', reportGroupId);
    }

    render() {
        const reportGroupsReturned = this.state.reportGroups.map(reportGroup => {
            return <div key={reportGroup.reportGroupId} className="row justify-content-end text-right">
                <NavLink to={"/reports/" + reportGroup.reportGroupId}
                    activeClassName="activeNavLink"
                    onClick={() => this.setComponentFilterState(NAVIGATION_TYPES.REPORTS, reportGroup.reportGroupId)}>{reportGroup.reportGroupName}</NavLink>
            </div>;
        });

        return (
            <div className="container mt-3" style={{ borderRight: "1px solid #e1e1e1", height: "100%" }}>
                <div className="justify-content-end pr-3">
                    <NavLink className="row justify-content-end text-right"
                        to="/userpinnedreports"
                        activeClassName="activeNavLink"
                        onClick={() => this.setComponentFilterState(NAVIGATION_TYPES.USER_PINNED_REPORTS)}>
                        Pinned Reports</NavLink>

                    <hr />

                    {reportGroupsReturned}

                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        uprs: state.user.userPinnedReports,
        userId: state.user.user.sub
    };
};

const mapDispatchToProps = (dispatch ) => {

    return {

        OnPinUnpin: (userId) => {
            axios.get('/api/userpinnedreports/user/' + userId)
                .then(response => {
                    dispatch({
                        type: NAVIGATION_TYPES.PIN_UNPIN_SUCCESS,
                        payload: response.data
                    })
                })
                .catch(error => {
                    dispatch({
                        type: NAVIGATION_TYPES.PIN_UNPIN_FAILURE,
                        payload: error.message
                    })
                });
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsSideMenu);