import React, { useContext, useState } from 'react';

const HandleProvinceIdContext = React.createContext();
const HandleProvinceNameContext = React.createContext();

const HandleDistrictIdContext = React.createContext();
const HandleDistrictNameContext = React.createContext();

const HandleSubDistrictIdContext = React.createContext();
const HandleSubDistrictNameContext = React.createContext();

const HandleFacilityIdContext = React.createContext();
const HandleFacilityNameContext = React.createContext();

const ProvinceIdContext = React.createContext();
const ProvinceNameContext = React.createContext();

const DistrictIdContext = React.createContext();
const DistrictNameContext = React.createContext();

const SubDistrictIdContext = React.createContext();
const SubDistrictNameContext = React.createContext();

const FacilityIdContext = React.createContext();
const FacilityNameContext = React.createContext();

export function useHandleProvinceIdContext() {
    return useContext(HandleProvinceIdContext);
}

export function useHandleProvinceNameContext() {
    return useContext(HandleProvinceNameContext);
}

export function useHandleDistrictIdContext() {
    return useContext(HandleDistrictIdContext);
}

export function useHandleDistrictNameContext() {
    return useContext(HandleDistrictNameContext);
}

export function useHandleSubDistrictIdContext() {
    return useContext(HandleSubDistrictIdContext);
}

export function useHandleSubDistrictNameContext() {
    return useContext(HandleSubDistrictNameContext);
}

export function useHandleFacilityIdContext() {
    return useContext(HandleFacilityIdContext);
}

export function useHandleFacilityNameContext() {
    return useContext(HandleFacilityNameContext);
}


export function useProvinceIdContext() {
    return useContext(ProvinceIdContext);
}

export function useProvinceNameContext() {
    return useContext(ProvinceNameContext);
}

export function useDistrictIdContext() {
    return useContext(DistrictIdContext);
}

export function useDistrictNameContext() {
    return useContext(DistrictNameContext);
}

export function useSubDistrictIdContext() {
    return useContext(SubDistrictIdContext);
}

export function useSubDistrictNameContext() {
    return useContext(SubDistrictNameContext);
}

export function useFacilityIdContext() {
    return useContext(FacilityIdContext);
}

export function useFacilityNameContext() {
    return useContext(FacilityNameContext);
}


export function OrgUnitFilterContext({ children }) {

    const [provId, setProvId] = useState('0');
    const [provName, setProvName] = useState(null);

    const [distId, setDistId] = useState('0');
    const [distName, setDistName] = useState(null);

    const [subDistId, setSubDistId] = useState('0');
    const [subDistName, setSubDistName] = useState(null);

    const [facId, setFacId] = useState('0');
    const [facName, setFacName] = useState(null);

    const handleProvinceIdChange = (val) => {
        setProvId(val);
    };

    const handleProvinceNameChange = (val) => {
        setProvName(val);
    };

    const handleDistrictIdChange = (val) => {
        setDistId(val);
    };

    const handleDistrictNameChange = (val) => {
        setDistName(val);
    };

    const handleSubDistrictIdChange = (val) => {
        setSubDistId(val);
    };

    const handleSubDistrictNameChange = (val) => {
        setSubDistName(val);
    };

    const handleFacilityIdChange = (val) => {
        setFacId(val);
    };

    const handleFacilityNameChange = (val) => {
        setFacName(val);
    };

   


    return (
        <HandleProvinceIdContext.Provider value={handleProvinceIdChange}>
            <HandleProvinceNameContext.Provider value={handleProvinceNameChange}>
                <HandleDistrictIdContext.Provider value={handleDistrictIdChange}>
                    <HandleDistrictNameContext.Provider value={handleDistrictNameChange}>

                        <HandleSubDistrictIdContext.Provider value={handleSubDistrictIdChange}>
                            <HandleSubDistrictNameContext.Provider value={handleSubDistrictNameChange}>
                                <HandleFacilityIdContext.Provider value={handleFacilityIdChange}>
                                    <HandleFacilityNameContext.Provider value={handleFacilityNameChange}>

                                        <ProvinceIdContext.Provider value={provId}>
                                            <ProvinceNameContext.Provider value={provName}>
                                                <DistrictIdContext.Provider value={distId}>
                                                    <DistrictNameContext.Provider value={distName}>
                                                        <SubDistrictIdContext.Provider value={subDistId}>
                                                            <SubDistrictNameContext.Provider value={subDistName}>
                                                                <FacilityIdContext.Provider value={facId}>
                                                                    <FacilityNameContext.Provider value={facName}>
                                                                        {children}
                                                                    </FacilityNameContext.Provider>
                                                                </FacilityIdContext.Provider>
                                                            </SubDistrictNameContext.Provider>
                                                        </SubDistrictIdContext.Provider>
                                                    </DistrictNameContext.Provider>
                                                </DistrictIdContext.Provider>
                                            </ProvinceNameContext.Provider>
                                        </ProvinceIdContext.Provider>
                                    </HandleFacilityNameContext.Provider>
                                </HandleFacilityIdContext.Provider>
                            </HandleSubDistrictNameContext.Provider>
                        </HandleSubDistrictIdContext.Provider>
                    </HandleDistrictNameContext.Provider>
                </HandleDistrictIdContext.Provider>
            </HandleProvinceNameContext.Provider>
        </HandleProvinceIdContext.Provider>
        
            

    );
}