import { Vector as VectorSource } from 'ol/source';
import GeoJSON from 'ol/format/GeoJSON';
import {bbox as bboxStrategy} from 'ol/loadingstrategy';

function hts({filter}) {
    //const typeName = filter.provinceFilter ? 'chisa:hts_positivity' : filter.districtFilter ? 'chisa:hts_positivity_subdistrict' : 'chisa:hts_positivity_province';
    //const cqlFilter = filter.provinceFilter ? 'CQL_FILTER=province_name=\''+ filter.provinceFilter +'\'&' : filter.districtFilter ? 'CQL_FILTER=district_name=\''+ filter.districtFilter +'\'&' : '';
    const typeName = filter.districtFilter ? 'chisa:hts_positivity_subdistrict'
                            : filter.provinceFilter ? 'chisa:hts_positivity_district'
                                : 'chisa:hts_positivity_province';
    const cqlFilter = filter.districtFilter ? 'CQL_FILTER=district_name=\''+ filter.districtFilter +'\'&'
                            : filter.provinceFilter ? 'CQL_FILTER=province_name=\''+ filter.provinceFilter +'\'&'
                                : '';
    
	return new VectorSource({
		format: new GeoJSON(),
        url: function (extent) {
            return (
              'https://geoserver.chi-sa.org/geoserver/wfs?service=WFS&' +
              'version=1.1.0&request=GetFeature&typename='+typeName+'&' +
              cqlFilter +
              'outputFormat=application/json&srsname=EPSG:4326&'
            );
          },
        strategy : bboxStrategy,

	});
}

export default hts;