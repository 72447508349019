import React, { Component } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { authentication } from "../_services/authentication";

class ChangePassword extends Component {
    state = {
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    }

    goToLogin = () => {
        authentication.logout();
        this.props.history.push("/login");
    };

    updatePassword = () => {
        
        const data = {
            userId: this.props.userId,
            currentPassword: this.state.currentPassword,
            newPassword: this.state.newPassword
        };
        console.log(JSON.stringify(data));

        axios.post('/api/users/changepassword', data)
            .then(response => {
                NotificationManager.success('Password changed successfully!', '', 2000);
                this.goToLogin();
            })
            .catch(error => {
                NotificationManager.error(error.message, '', 10000);
            });
    }

    resetValues = () => {
        this.setState({
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
        });
    }
  

    render() {

        return (
            <>
                {this.props.LDAPUser === "1" && "Your user account is managed by NDOH. Please contact the system administrator."}

                {this.props.LDAPUser === "0" &&

                    <AvForm onValidSubmit={this.updatePassword} >

                        <div className="row">
                            <div className="col-md-9">


                                <FormGroup>
                                    <Label className="color" htmlFor="pwd">Current Password:</Label>
                                    <AvField
                                        name="currPwd"
                                        type="password"
                                        className="form-control-sm form-control"
                                        placeholder="Current Password"
                                        value={this.state.currentPassword}
                                        onChange={(e) => this.setState({ currentPassword: e.target.value })}
                                        required
                                        disabled={this.props.LDAPUser === "1"}
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: 'Current Password is required!'
                                            },
                                            pattern: {
                                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W]).{6,}$/,
                                                errorMessage: 'Password must be composed of uppercase character, lowercase character, a digit, a non-alphanumeric character and be 6 characters long'
                                            },
                                            minLength: {
                                                value: 6,
                                                errorMessage: 'Password must be at least six characters long'
                                            }
                                        }} />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="color" htmlFor="pwd">New Password:</Label>
                                    <AvField
                                        name="pwd"
                                        type="password"
                                        className="form-control-sm form-control"
                                        placeholder="New Password"
                                        value={this.state.newPassword}
                                        onChange={(e) => this.setState({ newPassword: e.target.value })}
                                        required
                                        disabled={this.props.LDAPUser === "1"}
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: 'New Password is required!'
                                            },
                                            pattern: {
                                                //value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{6,}$/,
                                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W]).{6,}$/,
                                                errorMessage: 'Password must be composed of uppercase character, lowercase character, a digit, a non-alphanumeric character and be 6 characters long'
                                            },
                                            minLength: {
                                                value: 6,
                                                errorMessage: 'Password must be at least six characters long'
                                            }
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="color" htmlFor="confirm_pwd">Confirm Password:</Label>
                                    <AvField
                                        name="confirm_pwd"
                                        type="password"
                                        className="form-control-sm form-control"
                                        placeholder="Confirm Password"
                                        value={this.state.confirmPassword}
                                        onChange={(e) => this.setState({ confirmPassword: e.target.value })}
                                        validate={{ match: { value: 'pwd' } }}
                                        errorMessage="Password does not match"
                                        required
                                        disabled={this.props.LDAPUser === "1"}
                                    />
                                </FormGroup>
                            </div>




                        </div>

                        <div className="row">
                            <div className="col-md-2">
                                <button
                                    type="submit"
                                    className="btn btn-infohub w-100" >Update</button>
                            </div>
                            <div className="col-md-2">
                                <button
                                    type="reset"
                                    className="btn btn-secondary w-100"
                                    onClick={this.resetValues} >Reset</button>
                            </div>
                            <div className="col-md-8"></div>
                        </div>

                    </AvForm>
                }
                </>
        );
    }
}

const mapStateToProps = state => {
    return {
        userId: state.user.user.sub,
        LDAPUser: state.user.user.LDAPUser
    };
};

export default connect(mapStateToProps)(withRouter(ChangePassword));
//export default withRouter(ChangePassword);