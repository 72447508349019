import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import * as NAVIGATION_TYPES from '../../../actions/types';

class ReportManagementMenu extends Component {

    setComponentFilterState = (value, reportGroupId) => {
        localStorage.setItem('componentFilter', value);
    }

    render() {

        return (
            <div className="container text-right mt-3" style={{ borderRight: "1px solid #e1e1e1", height: "100%" }}>
                <div className="justify-content-end pr-3">
                    <div className="row justify-content-end">
                        <NavLink to="/reportgroup"
                            activeClassName="activeNavLink"
                            onClick={() => this.setComponentFilterState(NAVIGATION_TYPES.REPORT_GROUP)}>Report Groups</NavLink>
                    </div>
                    <div className="row justify-content-end">
                        <NavLink to="/reportsource"
                            activeClassName="activeNavLink"
                            onClick={() => this.setComponentFilterState(NAVIGATION_TYPES.REPORT_SOURCE)}>Report Sources</NavLink>
                    </div>
                    <div className="row justify-content-end">
                        <NavLink to="/report"
                            activeClassName="activeNavLink"
                            onClick={() => this.setComponentFilterState(NAVIGATION_TYPES.REPORT)}>Reports</NavLink>
                    </div>

                </div>
            </div>
        )
    }
}

export default ReportManagementMenu;