import React, { Component } from 'react';
import Layout from '../../../Layout';
import HTSForecastReportPages from './HTSForecastReportPages';
import { DateRangeFilterContext } from '../../../Reports/Filters/DateRangeFilterContext';
import { OrgUnitFilterContext } from '../../../Reports/Filters/OrgUnitFilterContext';
import { SubmitFilters } from '../../../Reports/Filters/SubmitFilters';
import { OrgUnitFilter } from '../../../Reports/Filters/OrgUnitFilter';
import ChartsDrawer from '../ChartsDrawer';
import ChartsContent from '../ChartsContent';
import ShinyChartTemplate from '../../../Reports/Charts/ShinyChartTemplate';


class HTSForecastLayout1 extends Component {

    state = {
        componentFilter: localStorage.getItem('componentFilter'),
    }

    render() {        
        return (
            <Layout>               
                <div className="row" style={{ height: "85vh" }}>
                    <ChartsDrawer>
                        <OrgUnitFilterContext>
                            <DateRangeFilterContext>
                            <OrgUnitFilter 
                                    provinceDisable={false}
                                    districtDisable = {false}
                                    subDistrictDisable = {true}
                                    facilityDisable = {true}
                            />
                            <SubmitFilters />
                            </DateRangeFilterContext>
                        </OrgUnitFilterContext>
                        <hr />
                        <HTSForecastReportPages />
                    </ChartsDrawer>
                    <ChartsContent>
                        <div className="chart-container" >
                            <div className="row" style={{ height: '60%' }}>
                                <div className="col-md-12">
                                        <ShinyChartTemplate resourceQuestion={"hts_forecast/hts_forecast_plot"} title="6-Month Forecast of Positive HIV Tests" />
                                </div>
                            </div>
                            <br />
                            <div className="row" style={{ height: '36.5%' }}>
                                <div className="col-md-8">
                                        <ShinyChartTemplate resourceQuestion={"hts_forecast/hts_forecast_values"} title="HTS Positive Forecast Values" />
                                </div>
                                <div className="col-md-4">
                                        <ShinyChartTemplate resourceQuestion={"hts_forecast/hts_forecast_metrics"} title="HTS Positive Forecast Metrics" />
                                </div>
                            </div>
                        </div>
                    </ChartsContent>
                </div>
            </Layout>
        )
    }
}

export default HTSForecastLayout1;