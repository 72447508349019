import React from "react";
import MindMap from "react-mindmap";
import Layout from './Layout';
import map from "./map";

const Home = () => {
    
    return (
        <Layout>
            <div className="container mt-3">
                <div className="col-md-12 text-center">
                    <h3 style={{ color: '#02642e' }}>WELCOME TO CHISA</h3>
                    <p>
                    Welcome to the Consolidated Health Informatics of South Africa (CHISA) portal, developed and maintained by the Republic of South Africa National Department of Health (NDoH). 
                    The NDoH  Informatics portal is an interactive analytical platform providing bespoke  visual analytics  and reports to wide-ranging users at all levels of the South Africa TB/HIV Response.
                    </p>
                    <p>
                    The analytical platform accesses disparate public sector health information systems with the aim of achieving patient linkage and longitudinal clinical management. 
                    Using the consolidated National TB/HIV dataset as the initial point of departure, data from other relevant health Information Systems is linked and layered to form the most complete picture of patient care while ensuring the widest possible coverage of public health facilities. 
                    </p>
                    <p>
                    This  data covers the South African public health system HIV response going back to 2004 when ART was introduced to the public sector and is updated quarterly. 
                    NDoH informatics portal analytics and reports address data needs across the TB-HIV cascades for routine reporting, continuous quality improvement, outcomes measurement, and predictive analytics.
                    </p>
                </div>

                <div className="col-md-12 d-sm-none d-md-none d-lg-none">
                    <MindMap nodes={map.nodes} connections={map.connections} />
                </div>
                <div className="col-md-12">
                    <MindMap nodes={map.nodes} connections={map.connections} />
                </div>
            </div>
        </Layout>
        );
    
}

export default Home
