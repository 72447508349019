import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';

class TLDUptakeReportPages extends Component {
    render() {
        return (
            <div className="container">
                <div className="row justify-content-end text-right">
                    <NavLink to="/charts/tld/1" activeClassName="activeNavLink" className="report-page">
                        Overview
                    </NavLink>
                </div>               
            </div>
        )
    }
}

export default TLDUptakeReportPages;