import React, { Component } from 'react';
import MetabaseChartTemplate from '../../../Reports/Charts/MetabaseChartTemplate';
import Layout from '../../../Layout';
import ARTInitiationReportPages from './ARTInitiationReportPages';
import { DateRangeFilterContext } from '../../../Reports/Filters/DateRangeFilterContext';
import { OrgUnitFilterContext } from '../../../Reports/Filters/OrgUnitFilterContext';
import { DateRangeFilter } from '../../../Reports/Filters/DateRangeFilter';
import { SubmitFilters } from '../../../Reports/Filters/SubmitFilters';
import { OrgUnitFilter } from '../../../Reports/Filters/OrgUnitFilter';
import ChartsDrawer from '../ChartsDrawer';
import ChartsContent from '../ChartsContent';


class ARTInitiationLayout1 extends Component {

    state = {
        componentFilter: localStorage.getItem('componentFilter'),
        defaultStartDate: new Date("2023-04-01"),
        defaultEndDate: new Date("2024-03-31"),
        minDate: new Date("2010-01-01"),
        maxDate: new Date("2024-03-31")
    }

    render() {        
        return (
            <Layout>               
                <div className="row" style={{ height: "85vh" }}>
                    <ChartsDrawer>
                        <OrgUnitFilterContext>
                            <DateRangeFilterContext>
                                <OrgUnitFilter />
                                <DateRangeFilter
                                    defaultStartDate={this.state.defaultStartDate}
                                    defaultEndDate={this.state.defaultEndDate}
                                    minDate={this.state.minDate}
                                    maxDate={this.state.maxDate}
                                />
                                <SubmitFilters />
                            </DateRangeFilterContext>
                        </OrgUnitFilterContext>
                        <hr />
                        <ARTInitiationReportPages />
                    </ChartsDrawer>
                    <ChartsContent>
                        <div className="chart-container">
                            <div className="row chart-row-50">
                                <div className="col-md-6">
                                    <MetabaseChartTemplate resourceQuestion={46} title="Linkage by District" />
                                </div>
                                <div className="col-md-6">
                                    <MetabaseChartTemplate resourceQuestion={17} title="Linkage per Quarter" />
                                </div>
                            </div>
                            <br></br>
                            <div className="row chart-row-50">
                                <div className="col-md-12">
                                    <MetabaseChartTemplate resourceQuestion={33} title="Linkage to ART by Age and Sex" />
                                </div>
                            </div>
                            <br></br>
                            <div className="row chart-row-50">
                                <div className="col-md-6">
                                    <MetabaseChartTemplate resourceQuestion={18} title="New on ART by Month and Sex" />
                                </div>
                                <div className="col-md-6">
                                    <MetabaseChartTemplate resourceQuestion={78} title="New on ART (10-24) by Month and Sex" />
                                </div>
                            </div>
                            <br></br>
                            <div className="row chart-row-50">
                                <div className="col-md-12">
                                    <MetabaseChartTemplate resourceQuestion={20} title="YoY ART Start Change" />
                                </div>
                            </div>
                        </div>
                    </ChartsContent>
                </div>
            </Layout>
        )
    }
}

export default ARTInitiationLayout1;