import React, { Component } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import Checkbox from './Controls/Checkbox';
import * as NAVIGATION_TYPES from '../actions/types';
import { withRouter } from 'react-router-dom';

class RegisterUser extends Component {

    state = {
        userId: '',
        firstName: '',
        lastName: '',
        username: '',
        password: '',
        confirmPassword: '',
        email: '',
        changePwdOnLogin: false,
        userRole: 'USER',
        userRoleOrig: 'USER',
        orgUnitLevel: '0',
        province: '',
        district: '0',
        subDistrict: '0',
        facility: '0',
        disableProvince: true,
        disableDistrict: true,
        disableSubDistrict: true,
        disableFacility: true,
        createOrUpdate: '',
        reports: [],
        userAssignedReports: [],
        userAssignedReportsOrig: [],
        user: [],
        orgUnitLevels: [],
        provinces: [],
        districts: [],
        subDistricts: [],
        facilities: [],
        required: true,
        disabled: false,
        duplicateEmail: 'default'
    }

    setComponentFilterState = (value, userId) => {
        localStorage.setItem('componentFilter', value);
        localStorage.setItem('updateUserId', userId);
    }

    resetValues = () => {
        if (localStorage.getItem('updateUserId') === '') {
            this.setState({ userId: '' });
            this.setState({ firstName: '' });
            this.setState({ lastName: '' });
            this.setState({ username: '' });
            this.setState({ password: '' });
            this.setState({ confirmPassword: '' });
            this.setState({ email: '' });
            this.setState({ changePwdOnLogin: false });
            this.setState({ userRole: 'USER' });
            this.setState({ orgUnitLevel: '0' });
            this.setState({ province: '0' });
            this.setState({ district: '0' });
            this.setState({ subDistrict: '0' });
            this.setState({ facility: '0' });
            this.setState({ userAssignedReports: [] });
            this.setState({ disableProvince: true });
            this.setState({ disableDistrict: true });
            this.setState({ disableSubDistrict: true });
            this.setState({ disableFacility: true });
            this.setState({ duplicateEmail: '' });
        }
        else {
            this.setState({ userId: localStorage.getItem('updateUserId') });
            this.setValues();
            this.setState({ userRole: this.state.userRoleOrig });
            this.setState({ userAssignedReports: this.state.userAssignedReportsOrig });
        }
    }

    setValues = () => {
        this.setState({ firstName: this.state.user.firstName });
        this.setState({ lastName: this.state.user.lastName });
        this.setState({ username: this.state.user.username });
        this.setState({ email: this.state.user.email });
        this.setState({ orgUnitLevel: this.state.user.orgUnitLevel });
        this.setState({ province: this.state.user.province });
        this.setState({ district: this.state.user.district });
        this.setState({ subDistrict: this.state.user.subDistrict });
        this.setState({ facility: this.state.user.facility });

        this.validateOrgUnitLevels(this.state.user.orgUnitLevel);
        this.loadProvinces();
        this.loadDistricts(this.state.user.province);
        this.loadSubDistricts(this.state.user.district);
        this.loadFacilities(this.state.user.subDistrict);
    }

    handleSubmit = () => {
        const data = {
            Id: localStorage.getItem('updateUserId'),
            Username: this.state.username,
            FirstName: this.state.firstName,
            LastName: this.state.lastName,
            Email: this.state.email,
            Password: this.state.password,
            ChangePwdOnLogin: this.state.changePwdOnLogin,
            UserRole: this.state.userRole,
            OrgUnitLevel: this.state.orgUnitLevel,
            Province: this.state.province,
            District: this.state.district,
            SubDistrict: this.state.subDistrict,
            Facility: this.state.facility,
            UserAssignedReports: this.state.userAssignedReports
        };

        //console.log('DATA :' + JSON.stringify(data));
        axios.get('/api/users/duplicatecheckemail', { params: { UserName: data.Username, UserEmail: data.Email } })
            .then(responseDuplicate => {
                if (!responseDuplicate.data) {
                    if (localStorage.getItem('updateUserId') !== "") {
                        axios.post('/api/users/updateuser', data)
                            .then(response => {
                                NotificationManager.success('Updated Successfully!', '', 2000);
                                //Update state.user for Reset
                                let userUpdate = JSON.parse(JSON.stringify(this.state.user));
                                userUpdate.username = this.state.username;
                                userUpdate.firstName = this.state.firstName;
                                userUpdate.lastName = this.state.lastName;
                                userUpdate.email = this.state.email;
                                userUpdate.password = this.state.password;
                                userUpdate.changePwdOnLogin = this.state.changePwdOnLogin;
                                userUpdate.userRole = this.state.userRole;
                                userUpdate.orgUnitLevel = this.state.orgUnitLevel;
                                userUpdate.province = this.state.province;
                                userUpdate.district = this.state.district;
                                userUpdate.subDistrict = this.state.subDistrict;
                                userUpdate.facility = this.state.facility;
                                this.setState({ user: userUpdate });
                                this.setState({ userRoleOrig: this.state.userRole });
                                this.setState({ userAssignedReportsOrig: this.state.userAssignedReports });
                            })
                            .catch(error => {
                                console.log('ERROR : ' + error.message);
                                NotificationManager.error('Error occured!', '', 2000);
                            })
                    }
                    else {
                        axios.post('/api/users/signup', data)
                            .then(response => {
                                NotificationManager.success('Registered Successfully!', '', 2000);
                                this.setComponentFilterState(NAVIGATION_TYPES.REGISTERED_USER, response.data)
                                this.props.history.push("/registereduser/" + response.data);
                            })
                            .catch(error => {
                                console.log('ERROR : ' + error.message);
                                NotificationManager.error('Error occured!', '', 2000);
                            })
                    }
                }
                else {
                    this.setState({ duplicateEmail: this.state.email }); //This automatically triggers validations
                }
            })
            .catch(error => {
                console.log('ERROR : ' + error.message);
                NotificationManager.error(error.message, '', 2000);
            })
    }

    componentDidMount() {
        axios.get('/api/reports')
            .then(response => {
                this.setState({ reports: response.data });
            });

        axios.get('/api/orgunitlevel')
            .then(response => {
                this.setState({ orgUnitLevels: response.data });
            });

        this.loadProvinces();

        if (localStorage.getItem('updateUserId') !== "") {
            this.setState({ createOrUpdate: 'Update' });
            this.setState({ required: false });
            this.setState({ disabled: true });

            axios.get('/api/users/userreportclaims/' + localStorage.getItem('updateUserId'))
                .then(response => {
                    this.setState({ userAssignedReports: response.data });
                    this.setState({ userAssignedReportsOrig: response.data });
                });

            axios.get('/api/users/userroles/' + localStorage.getItem('updateUserId'))
                .then(response => {
                    this.setState({ userRole: response.data });
                    this.setState({ userRoleOrig: response.data });
                });

            axios.get('/api/users/' + localStorage.getItem('updateUserId'))
                .then(response => {
                    this.setState({ userId: localStorage.getItem('updateUserId') });
                    this.setState({ user: response.data });
                    this.setValues();
                });
        }
        else {
            this.setState({ createOrUpdate: 'Create' });
            this.setState({ required: true });
            this.setState({ disabled: false });
        }
        //localStorage.setItem('updateUserId', "");
    }

    loadProvinces = () => {
        axios.get('/api/orgunit/provinces')
            .then(response => {
                this.setState({ provinces: response.data });
            });
    }

    loadDistricts = (provinceId) => {
        axios.get('/api/orgunit/districts/' + provinceId)
            .then(response => {
                this.setState({ districts: response.data });
            });
    }

    loadSubDistricts = (districtId) => {
        axios.get('/api/orgunit/subdistricts/' + districtId)
            .then(response => {
                this.setState({ subDistricts: response.data });
            });
    }

    loadFacilities = (subDistrictId) => {
        axios.get('/api/orgunit/facilities/' + subDistrictId)
            .then(response => {
                this.setState({ facilities: response.data });
            });
    }

    validateOrgUnitLevels = (orgUnitLevel) => {

        if (orgUnitLevel === '1') {
            this.setState({ province: '0', disableProvince: true });
            this.setState({ district: '0', disableDistrict: true, });
            this.setState({ subDistrict: '0', disableSubDistrict: true, });
            this.setState({ facility: '0', disableFacility: true });
        }
        else if (orgUnitLevel === '2') {
            this.setState({ disableProvince: false });
            this.setState({ district: '0', disableDistrict: true });
            this.setState({ subDistrict: '0', disableSubDistrict: true });
            this.setState({ facility: '0', disableFacility: true });
        }
        else if (orgUnitLevel === '3') {
            this.setState({ disableProvince: false });
            this.setState({ disableDistrict: false });
            this.setState({ subDistrict: '0', disableSubDistrict: true });
            this.setState({ facility: '0', disableFacility: true });

        }
        else if (orgUnitLevel === '4') {
            this.setState({ disableProvince: false });
            this.setState({ disableDistrict: false });
            this.setState({ disableSubDistrict: false });
            this.setState({ facility: '0', disableFacility: true });
        }
        else if (orgUnitLevel === '5') {
            this.setState({ disableProvince: false });
            this.setState({ disableDistrict: false });
            this.setState({ disableSubDistrict: false });
            this.setState({ disableFacility: false });

        }
    }

    getUserAssignedReports = (e) => {
        if (e.target.checked === true) {

            //var array = this.state.userAssignedReports.concat(e.target.value);
            //this.setState({ userAssignedReports: array })

            this.setState({ userAssignedReports: [...this.state.userAssignedReports, e.target.value] });
            //console.log('selected reports :' + this.state.userAssignedReports);
        }
        else {
            var array = [...this.state.userAssignedReports];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                this.setState({ userAssignedReports: array });
            }
            //console.log('selected reports :' + this.state.userAssignedReports);
        }
    }

    duplicateCheckEmail = (value) => {
        if (value !== '' && this.state.duplicateEmail === value) {
            return 'Email already Exists';
        }
        else {
            return true;
        }
    }

    render() {
        const reportsReturned = this.state.reports.map(report => {
            return <Checkbox
                key={report.reportId}
                val={report.reportId}
                txt={report.reportName}
                rptClaims={this.state.userAssignedReports}
                onChange={(e) => this.getUserAssignedReports(e)} />;
        });

        const orgUnitLevels = this.state.orgUnitLevels.map(orgUnitLevel => {
            {/*return <AvRadio key={orgUnitLevel.orgUnitLevelID} label={orgUnitLevel.name} value={orgUnitLevel.orgUnitLevelID} checked={orgUnitLevel.orgUnitLevelID.toString() === this.state.orgUnitLevel.toString()} />;*/ }
            return <label key={orgUnitLevel.orgUnitLevelID + 1}>
                <input
                    type="radio"
                    className="ml-2"
                    key={orgUnitLevel.orgUnitLevelID}
                    name="userPermissionLevel"
                    value={orgUnitLevel.orgUnitLevelID}
                    checked={orgUnitLevel.orgUnitLevelID.toString() === this.state.orgUnitLevel.toString()}
                    onChange={(e) => {
                        this.setState({ orgUnitLevel: e.target.value });
                        this.validateOrgUnitLevels(e.target.value);
                    }
                    }
                    required
                />  {orgUnitLevel.name} </label>;
        });

        const provinces = this.state.provinces.map(province => {
            return <option key={province.organisationUnitID} value={province.organisationUnitID}>{province.name}</option>;
        });

        const districts = this.state.districts.map(district => {
            return <option key={district.organisationUnitID} value={district.organisationUnitID}>{district.name}</option>;
        });

        const subDistricts = this.state.subDistricts.map(subDistrict => {
            return <option key={subDistrict.organisationUnitID} value={subDistrict.organisationUnitID}>{subDistrict.name}</option>;
        });

        const facilities = this.state.facilities.map(facility => {
            return <option key={facility.organisationUnitID} value={facility.organisationUnitID}>{facility.name}</option>;
        });

        const defaultValues = {
            userPermissionLevel: this.state.orgUnitLevel
        };

        return (

            <AvForm onValidSubmit={this.handleSubmit} model={defaultValues}>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <FormGroup>
                            <Label className="color" htmlFor="first_name">First Name:</Label>
                            <AvField
                                name="first_name"
                                type="text"
                                className="form-control-sm form-control"
                                placeholder="First Name"
                                value={this.state.firstName}
                                onChange={(e) => this.setState({ firstName: e.target.value })}
                                required
                                errorMessage="First name is required" />

                        </FormGroup>

                        <FormGroup>
                            <Label className="color" htmlFor="last_name">Last Name</Label>
                            <AvField
                                name="last_name"
                                type="text"
                                className="form-control-sm form-control"
                                placeholder="Last Name"
                                value={this.state.lastName}
                                onChange={(e) => this.setState({ lastName: e.target.value })}
                                required
                                errorMessage="Last name is required" />
                        </FormGroup>
                        <FormGroup>
                            <Label className="color" htmlFor="username">Username:</Label>
                            <AvField
                                name="username"
                                type="text"
                                className="form-control-sm form-control"
                                placeholder="Username"
                                value={this.state.username}
                                onChange={(e) => this.setState({ username: e.target.value })}
                                disabled={this.state.disabled}
                                required
                                errorMessage="Username is required" />
                        </FormGroup>
                        <FormGroup>
                            <Label className="color" htmlFor="pwd">Password:</Label>
                            <AvField
                                name="pwd"
                                type="password"
                                className="form-control-sm form-control"
                                placeholder="Password"
                                value={this.state.password}
                                onChange={(e) => this.setState({ password: e.target.value })}
                                required={this.state.required}
                                disabled={this.state.disabled}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: 'Password is required!'
                                    },
                                    pattern: {
                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W]).{6,}$/,
                                        errorMessage: 'Password must be composed of uppercase character, lowercase character, a digit, a non-alphanumeric character and be 6 characters long'
                                    },
                                    minLength: {
                                        value: 6,
                                        errorMessage: 'Password must be at least six characters long'
                                    }
                                }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label className="color" htmlFor="confirm_pwd">Confirm Password:</Label>
                            <AvField
                                name="confirm_pwd"
                                type="password"
                                className="form-control-sm form-control"
                                placeholder="Confirm Password"
                                value={this.state.confirmPassword}
                                onChange={(e) => this.setState({ confirmPassword: e.target.value })}
                                validate={{ match: { value: 'pwd' } }}
                                errorMessage="Password does not match"
                                required={this.state.required}
                                disabled={this.state.disabled}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label className="color" htmlFor="email">Email Address:</Label>
                            <AvField
                                name="email"
                                type="email"
                                className="form-control-sm form-control"
                                placeholder="Email"
                                value={this.state.email}
                                onChange={(e) => this.setState({ email: e.target.value })}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: 'Email is required'
                                    },
                                    email: {
                                        value: true,
                                        errorMessage: 'Email is Invalid'
                                    },
                                    myValidation: this.duplicateCheckEmail
                                }}
                            />
                        </FormGroup>
                        <div className="form-check">
                            <Input
                                type="checkbox"
                                className="form-check-input"
                                id="change_pwd"
                                onClick={(e) => {
                                    this.setState({ changePwdOnLogin: e.target.checked });
                                    //console.log('changePwdOnLogin :' + this.state.changePwdOnLogin);
                                }
                                }
                            />
                            <label className="form-check-label" htmlFor="change_pwd">User must change password at login</label>
                        </div>
                        <div className="form-check">
                            <Input
                                type="checkbox"
                                className="form-check-input"
                                id="make_admin"
                                onChange={(e) => {
                                    if (e.target.checked)
                                        this.setState({ userRole: 'ADMIN' });
                                    else
                                        this.setState({ userRole: 'USER' });
                                    //console.log('userRole :' + this.state.userRole);
                                }
                                }
                                checked={this.state.userRole === 'ADMIN'}
                            />
                            <label className="form-check-label" htmlFor="make_admin">Make user an administrator</label>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <FormGroup>
                            <Label className="color">User Permission Level:</Label> <br/>
                            {orgUnitLevels}

                        </FormGroup>
                        <FormGroup className="pt-1">
                            <Label className="color" htmlFor="ddlProvince">Province:</Label>
                            <AvField
                                type="select"
                                name="ddlProvince"
                                className="form-control form-control-sm"
                                value={this.state.province}
                                onChange={(e) => {
                                    this.setState({ province: e.target.value });
                                    this.loadDistricts(e.target.value);
                                }
                                }
                                disabled={this.state.disableProvince}
                                required
                            >
                                <option value=''>select</option>
                                {provinces}
                            </AvField>
                        </FormGroup>
                        <FormGroup>
                            <label className="color" htmlFor="ddlDistrict">District:</label>
                            <AvField
                                type="select"
                                className="form-control form-control-sm"
                                name="ddlDistrict"
                                value={this.state.district}
                                onChange={(e) => {
                                    this.setState({ district: e.target.value });
                                    this.loadSubDistricts(e.target.value);
                                }
                                }
                                disabled={this.state.disableDistrict}
                            >
                                <option value='0'>select</option>
                                {districts}
                            </AvField>
                        </FormGroup>
                        <FormGroup>
                            <label className="color" htmlFor="ddlSubDistrict">Sub-District:</label>
                            <AvField
                                type="select"
                                className="form-control form-control-sm"
                                name="ddlSubDistrict"
                                value={this.state.subDistrict}
                                onChange={(e) => {
                                    this.setState({ subDistrict: e.target.value });
                                    this.loadFacilities(e.target.value);
                                }
                                }
                                disabled={this.state.disableSubDistrict}
                            >
                                <option value='0'>select</option>
                                {subDistricts}
                            </AvField>
                        </FormGroup>
                        <FormGroup>
                            <label className="color" htmlFor="ddlFacility">Facility:</label>
                            <AvField
                                type="select"
                                className="form-control form-control-sm"
                                name="ddlFacility"
                                value={this.state.facility}
                                onChange={(e) => this.setState({ facility: e.target.value })}
                                disabled={this.state.disableFacility}
                            >
                                <option value='0'>select</option>
                                {facilities}
                            </AvField>
                        </FormGroup>
                        <FormGroup>
                            <label className="color" htmlFor="reports">Reports</label>
                            <div className="p-1 border rounded overflow-auto" style={{ height: "100%" }} >
                                {reportsReturned}
                            </div>
                        </FormGroup>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-2">
                        <button type="submit" className="btn btn-infohub w-100" >{this.state.createOrUpdate}</button>
                    </div>
                    <div className="col-md-2">
                        <button type="reset" className="btn btn-secondary w-100" onClick={this.resetValues} >Reset</button>
                    </div>
                    <div className="col-md-8"></div>
                </div>
            </AvForm>
        )
    }
}

export default withRouter(RegisterUser);