import React, { Component } from 'react';
import Layout from '../../../Layout';
import ExpandedTBOutcomesReportPages from './ExpandedTBOutcomesReportPages';
import { OrgUnitFilterContext } from '../../../Reports/Filters/OrgUnitFilterContext';
import { SubmitFilters } from '../../../Reports/Filters/SubmitFilters';
import { OrgUnitFilter } from '../../../Reports/Filters/OrgUnitFilter';
import ChartsDrawer from '../ChartsDrawer';
import ChartsContent from '../ChartsContent';
import ShinyChartTemplate from '../../../Reports/Charts/ShinyChartTemplate';

class ExpandedTBOutcomesLayout2 extends Component {

    state = {
        componentFilter: localStorage.getItem('componentFilter'),
    }

    render() {        
        return (
            <Layout>               
                <div className="row" style={{ height: "85vh" }}>
                    <ChartsDrawer>
                        <OrgUnitFilterContext>
                            <OrgUnitFilter 
                                    provinceDisable={false}
                                    districtDisable = {true}
                                    subDistrictDisable = {true}
                                    facilityDisable = {true}
                            />
                                
                                <SubmitFilters />
                        </OrgUnitFilterContext>
                        <hr />
                        <ExpandedTBOutcomesReportPages />
                    </ChartsDrawer>
                    <ChartsContent>
                        <div className="chart-container" style={{ height: "85vh" }} >
                            <div className="row" style={{ height: "84vh" }}>
                                <div className="col-md-6">
                                    <div className='row' style={{ height: "41vh" }}>
                                        <div className="col-md-12">
                                            <ShinyChartTemplate resourceQuestion={"epi/tboutcomes/tx_outcomes_plot"} title="Treatment Outcomes" />
                                        </div>
                                    </div>
                                    <br />
                                    <div className='row' style={{ height: "41vh" }}>
                                        <div className="col-md-12">
                                            <ShinyChartTemplate resourceQuestion={"epi/tboutcomes/tx_outcomes_table"} title="Treatment Outcomes" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                        <ShinyChartTemplate resourceQuestion={"epi/tboutcomes/tx_success_table"} title="Successful Outcomes (Cured or Completed Treatment)" />
                                </div>
                            </div>
                        </div>
                    </ChartsContent>
                </div>
            </Layout>
        )
    }
}

export default ExpandedTBOutcomesLayout2;