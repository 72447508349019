import React from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { url } from './api';

// React Notification
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';

const AppResources = () => {

    const userToken = useSelector(state => state.user.userToken);

    let token = `Bearer ${userToken}`;
    //token = token.replace(/['"]+/g, '');

    axios.defaults.baseURL = url;
    axios.defaults.headers.common['Authorization'] = token;

    return (
        <NotificationContainer />
    );
}

export default AppResources;