import React, { Component } from 'react';
import MetabaseChartTemplate from '../../../Reports/Charts/MetabaseChartTemplate';
import Layout from '../../../Layout';
import ReportsFilter from '../../../Reports/ReportsFilter';
import HTSReportPages from './HTSReportPages';

class HTSLayout3 extends Component {

    state = {
        componentFilter: localStorage.getItem('componentFilter')
    }

    render() {
        return (

            <Layout>
                <div className="row mb-5" style={{ height: "85vh" }}>
                    <div className="col-md-3">
                        <ReportsFilter />
                        <hr />
                        <HTSReportPages />
                    </div>
                    <div className="col-md-9 justify-content-start" style={{ borderLeft: "3px solid #02642e" }}>
                        <div className="w-100" style={{ height: "85vh" }} >
                            <div className="row h-100">
                                <div className="col-md-12">
                                    <MetabaseChartTemplate resourceQuestion={168} height="600px" title="HTS Testing Yield by Age Group and Sex" />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default HTSLayout3;