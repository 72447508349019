import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';

class ARTInitiationReportPages extends Component {
    render() {
        return (
            <div className="container">
                <div className="row justify-content-end text-right">
                    <NavLink to="/charts/art/1" activeClassName="activeNavLink" className="report-page">
                        Overview
                    </NavLink>
                </div>
                {/*<div className="row justify-content-end text-right">
                    <NavLink to="/charts/art/2" activeClassName="activeNavLink" className="report-page">
                        Time to ART Start
                    </NavLink>
                </div>*/}
            </div>
        )
    }
}

export default ARTInitiationReportPages;