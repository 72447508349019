import React, { Component } from 'react';
import Layout from '../../../Layout';
import HIVOutcomesReportPages from './HIVOutcomesReportPages';
import { DateRangeFilterContext } from '../../../Reports/Filters/DateRangeFilterContext';
import { OrgUnitFilterContext } from '../../../Reports/Filters/OrgUnitFilterContext';
//import { DateRangeFilter } from '../../../Reports/Filters/DateRangeFilter';
import { SubmitFilters } from '../../../Reports/Filters/SubmitFilters';
import { OrgUnitFilter } from '../../../Reports/Filters/OrgUnitFilter';
import ChartsDrawer from '../ChartsDrawer';
import ChartsContent from '../ChartsContent';
import ShinyChartTemplate from '../../../Reports/Charts/ShinyChartTemplate';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

class HIVOutcomesLayout5 extends Component {

    state = {
        componentFilter: localStorage.getItem('componentFilter'),
    }

    render() {        
        return (
            <Layout>               
                <div className="row" style={{ height: "85vh" }}>
                    <ChartsDrawer>
                        <OrgUnitFilterContext>
                            <DateRangeFilterContext>
                            <OrgUnitFilter 
                                    provinceDisable={false}
                                    districtDisable = {true}
                                    subDistrictDisable = {true}
                                    facilityDisable = {true}
                            />
                                
                                <SubmitFilters />
                            </DateRangeFilterContext>
                        </OrgUnitFilterContext>
                        <hr />
                        <HIVOutcomesReportPages />
                    </ChartsDrawer>
                    <ChartsContent>
                        <div className="chart-container">
                            <div className="row" style={{ height: "84vh" }}>
                                <div className="col-md-12">
                                        <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/vl_suppression_demographics"} title="VL Suppression by Demographics" />
                                </div>
                            </div>
                        </div>
                    </ChartsContent>
                </div>
            </Layout>
        )
    }
}

export default HIVOutcomesLayout5;