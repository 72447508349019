import React, { Component } from 'react';
import MetabaseChartTemplate from '../../../Reports/Charts/MetabaseChartTemplate';
import Layout from '../../../Layout';
import VlReportPages from './VlReportPages';
import { OrgUnitFilterContext } from '../../../Reports/Filters/OrgUnitFilterContext';
import { SubmitFilters } from '../../../Reports/Filters/SubmitFilters';
import { OrgUnitFilter } from '../../../Reports/Filters/OrgUnitFilter';
import ChartsDrawer from '../ChartsDrawer';
import ChartsContent from '../ChartsContent';

class Vl1 extends Component {
	state = {
		componentFilter: localStorage.getItem('componentFilter'),
	};
	render() {
		return (
			<Layout>
				<div className="row" style={{ height: '85vh' }}>
						<ChartsDrawer>
							<OrgUnitFilterContext>
									<OrgUnitFilter />
									<SubmitFilters />
							</OrgUnitFilterContext>
							<hr />
							<VlReportPages />
						</ChartsDrawer>
					<ChartsContent>
						<div className="chart-container" style={{ height: '85vh' }}>
							<div className="row" style={{ height: '41vh' }}>
								<div className="col-md-4">
									<MetabaseChartTemplate 
                                        resourceQuestion={130} 
                                        title="Suppression Quarterly Change"
                                    />
								</div>
                                <div className="col-md-8">
									<MetabaseChartTemplate 
                                        resourceQuestion={129} 
                                        title="Viral Load Coverage and Suppression Cascades over Time"
                                    />
								</div>
                            </div> 
                            <br></br>   
                            <div className="row" style={{ height: '41vh' }}>
								<div className="col-md-4">
									<MetabaseChartTemplate 
                                        resourceQuestion={131} 
                                        title="Coverage Quarterly Change"
                                    />
								</div>
                                <div className="col-md-8">
									<MetabaseChartTemplate 
                                        resourceQuestion={132} 
                                        title="Coverage and Suppression by Age Group"
                                    />
								</div>
                            </div> 
						</div>
					</ChartsContent>
				</div>
			</Layout>
		);
	}
}

export default Vl1;
