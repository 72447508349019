import React from 'react';
import { useSelector } from 'react-redux';

export default (props) => {
    const drawerOpen = useSelector(state => state.user.drawerOpen);
    return (
        <div className={drawerOpen ? "col-md-9 justify-content-start mt-3" : "col justify-content-start mt-3"}>
            {props.children}
        </div>
    );
}
