import React, { Component } from 'react';
import { FormGroup, Label, Table, Modal, ModalHeader, ModalBody, Input } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/ai";
import DropdownOptions from '../../Controls/DropdownOptions';

class Report extends Component {

    state = {
        reportId: 0,
        reportGroupId: 0,
        reportName: '',
        reportShortDescription: '',
        reportDetailedDescription: '',
        reportRoute: '',
        reportSourceId: 0,
        reports: [],
        reportGroups: [],
        reportSources: [],
        reportOrder: 0,
        componentDidUpdateCheck: 0,
        saveUpdateDelete: 'Add',
        modal: false
    }

    resetValues = () => {
        this.setState({ reportId: 0 });
        this.setState({ reportGroupId: 0 });
        this.setState({ reportName: '' });
        this.setState({ reportShortDescription: '' });
        this.setState({ reportDetailedDescription: '' });
        this.setState({ reportRoute: '' });
        this.setState({ reportSourceId: 0 });
        this.setState({ reportOrder: 0 });
        this.setState({ componentDidUpdateCheck: 0 });
        this.setState({ saveUpdateDelete: 'Add' });
        
    }

    handleSubmit = () => {

        const data = {
            reportId: this.state.reportId,
            reportGroupId: parseInt(this.state.reportGroupId),
            reportName: this.state.reportName,
            reportShortDescription: this.state.reportShortDescription,
            reportDetailedDescription: this.state.reportDetailedDescription,
            reportRoute: this.state.reportRoute,
            reportSourceId: parseInt(this.state.reportSourceId),
            reportOrder: parseInt(this.state.reportOrder)
        };

        if (this.state.saveUpdateDelete === 'Add') {

            axios.post('/api/reports/add', data)
                .then(response => {
                    this.setState({ componentDidUpdateCheck: 1 });
                    this.toggle();
                    NotificationManager.success('Saved Successfully!', '', 2000);
                })
                .catch(error => {
                    console.log(error);
                    NotificationManager.error('An error occured!', '', 2000);
                })
        }

        else if (this.state.saveUpdateDelete === 'Update') {

            axios.post('/api/reports/update/' + this.state.reportId, data)
                .then(response => {
                    this.setState({ componentDidUpdateCheck: 1 });
                    this.toggle();
                    NotificationManager.success('Updated Successfully!', '', 2000);
                })
                .catch(error => {
                    console.log(error);
                    NotificationManager.error('An error occured!', '', 2000);
                })
        }
        else if (this.state.saveUpdateDelete === 'Delete') {

            axios.post('/api/reports/delete/' + this.state.reportId)
                .then(response => {
                    this.setState({ componentDidUpdateCheck: 1 });
                    this.toggle();
                    NotificationManager.success('Deleted Successfully!', '', 2000);
                })
                .catch(error => {
                    console.log(error);
                    NotificationManager.error('An error occured!', '', 2000);
                })
        }
    }

    componentDidMount() {
        axios.get('/api/reports')
            .then(response => {
                this.setState({ reports: response.data });
            });

        axios.get('/api/reportGroups')
            .then(response => {
                this.setState({ reportGroups: response.data });
            });

        axios.get('/api/reportSources')
            .then(response => {
                this.setState({ reportSources: response.data });
            });
    }

    componentDidUpdate() {
        if (this.state.componentDidUpdateCheck > 0) {
            axios.get('/api/reports')
                .then(response => {
                    this.setState({ reports: response.data });
                });

            this.setState({ componentDidUpdateCheck: 0 });
        }
    }

    getReport = (report, action) => {
        this.resetValues();
        this.toggle();
        this.setState({ reportId: report.reportId });
        this.setState({ reportGroupId: report.reportGroupId });
        this.setState({ reportName: report.reportName });
        this.setState({ reportShortDescription: report.reportShortDescription });
        this.setState({ reportDetailedDescription: report.reportDetailedDescription });
        this.setState({ reportRoute: report.reportRoute });
        this.setState({ reportSourceId: report.reportSourceId });
        this.setState({ reportOrder: report.reportOrder });
        this.setState({ saveUpdateDelete: action });
    }


    toggle = () => {
        this.setState({ modal: !this.state.modal });
    }

    render() {
        
        const reportsReturned = this.state.reports.map(report => {

            const rgName = this.state.reportGroups.map(rg => {
                if (rg.reportGroupId === report.reportGroupId)
                    return rg.reportGroupName;
            });

            const rsName = this.state.reportSources.map(rs => {
                if (rs.reportSourceId === report.reportSourceId)
                    return rs.reportSourceName;
            });
            
            return <tr key={report.reportId}>
                <td>{rgName}</td>
                <td>{report.reportName}</td>
                <td>{report.reportShortDescription}</td>
                <td>{report.reportDetailedDescription}</td>
                <td>{report.reportRoute}</td>
                <td>{rsName}</td>
                <td>{report.reportOrder}</td>
                <td><NavLink to={"/report"} onClick={() => this.getReport(report, "Update")} ><FaRegEdit /> </NavLink></td>
                <td><NavLink to={"/report"} onClick={() => this.getReport(report, "Delete")} ><FaRegTrashAlt /> </NavLink></td>
            </tr>;

        });

        const reportGroupsReturned = this.state.reportGroups.map(reportGroup => {
            return <DropdownOptions key={reportGroup.reportGroupId} val={reportGroup.reportGroupId} txt={reportGroup.reportGroupName} />;
        });

        const reportSourcesReturned = this.state.reportSources.map(reportSource => {
            return <DropdownOptions key={reportSource.reportSourceId} val={reportSource.reportSourceId} txt={reportSource.reportSourceName} />;
        });

        let classNameHeader = 'color';
        let classNameBtn = 'btn btn-infohub w-100';
        if (this.state.saveUpdateDelete === "Delete") {
            classNameHeader = 'deleteColor';
            classNameBtn = 'btn btn-danger w-100';
        }

        return (
            <div>
                <Modal isOpen={this.state.modal} toggle={this.toggle} >
                    <ModalHeader toggle={this.toggle} className={classNameHeader} >{this.state.saveUpdateDelete + " Report"}</ModalHeader>
                    <ModalBody>
                        <AvForm onValidSubmit={this.handleSubmit}>
                            <div className="row">
                                <div className="col-md-12">
                                    <FormGroup>
                                        <label className="color" htmlFor="reports">Report group</label>
                                        <Input
                                            name="reportGroup"
                                            type="select"
                                            className="form-control-sm form-control"
                                            value={this.state.reportGroupId}
                                            onChange={(e) => this.setState({ reportGroupId: e.target.value })}
                                            required 
                                        >
                                            <option>Select</option>
                                            {reportGroupsReturned}
                                        </Input>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label className="color">Report Name</Label>
                                        <AvField
                                            name="reportName"
                                            type="text"
                                            className="form-control-sm form-control"
                                            placeholder="Report Name"
                                            value={this.state.reportName}
                                            onChange={(e) => this.setState({ reportName: e.target.value })}
                                            required
                                            errorMessage="Report name is required" />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label className="color">Report Short Description</Label>
                                        <AvField
                                            name="reportShortDescription"
                                            type="textarea"
                                            className="form-control-sm form-control"
                                            placeholder="Report Short Description"
                                            value={this.state.reportShortDescription}
                                            onChange={(e) => this.setState({ reportShortDescription: e.target.value })}
                                            required
                                            errorMessage="Report short description is required" />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label className="color">Report Detailed Description</Label>
                                        <AvField
                                            name="reportDetailedDescription"
                                            type="textarea"
                                            className="form-control-sm form-control"
                                            placeholder="Report Detailed Description"
                                            value={this.state.reportDetailedDescription}
                                            onChange={(e) => this.setState({ reportDetailedDescription: e.target.value })}
                                            required
                                            errorMessage="Report detailed description is required" />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label className="color">Report Route</Label>
                                        <AvField
                                            name="reportRoute"
                                            type="text"
                                            className="form-control-sm form-control"
                                            placeholder="Report Route"
                                            value={this.state.reportRoute}
                                            onChange={(e) => this.setState({ reportRoute: e.target.value })}
                                            required
                                            errorMessage="Report route is required" />
                                    </FormGroup>

                                    <FormGroup>
                                        <label className="color">Report source</label>
                                        <Input
                                            type="select"
                                            name="reportSource"
                                            className="form-control-sm form-control"
                                            value={this.state.reportSourceId}
                                            onChange={(e) => this.setState({ reportSourceId: e.target.value })}
                                            required
                                        >
                                            <option>Select</option>
                                            {reportSourcesReturned}
                                        </Input>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label className="color">Report Order</Label>
                                        <AvField
                                            name="reportOrder"
                                            type="number"
                                            className="form-control-sm form-control"
                                            placeholder="Report Order"
                                            value={this.state.reportOrder}
                                            onChange={(e) => this.setState({ reportOrder: e.target.value })}
                                            required
                                            errorMessage="Report order is required" />
                                    </FormGroup>

                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-6">
                                    <button type="submit" className={classNameBtn} >{this.state.saveUpdateDelete}</button>
                                </div>
                                <div className="col-md-6">
                                    <button type="reset" className="btn btn-secondary w-100" onClick={this.toggle} >Cancel</button>
                                </div>

                            </div>
                        </AvForm>
                    </ModalBody>

                </Modal>


                <div className="row">
                    <div className="col-md-10"></div>
                    <div>
                        <button
                            type="submit"
                            style={{
                                backgroundColor: "transparent",
                                outline: "none",
                                border: "none"
                            }}
                            onClick={() => { this.resetValues(); this.toggle(); }} >
                            <AiOutlinePlusCircle className="color" style={{ fontSize: "45px" }} />
                        </button>

                        {/*<button type="submit" className="btn btn-infohub w-100" onClick={() => { this.resetValues(); this.toggle(); }} >Add Report</button>*/}
                    </div>
                </div>

                <div className="row">
                    <Table striped>
                        <thead>
                            <tr>
                                <th className="color">Group</th>
                                <th className="color">Name</th>
                                <th className="color">Short Description</th>
                                <th className="color">Detailed Description</th>
                                <th className="color">Route</th>
                                <th className="color">Source</th>
                                <th className="color">Order</th>
                                <th className="color"></th>
                                <th className="color"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {reportsReturned}

                        </tbody>
                    </Table>
                </div>

            </div>
        )
    }
}

export default Report;