import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';

class HTSForecastReportPages extends Component {
    render() {
        return (
            <div className="container">
                <div className="row justify-content-end text-right">
                    <NavLink to="/charts/htsforecast/1" activeClassName="activeNavLink" className="report-page">
                        6-month Forecast
                    </NavLink>
                </div>
            </div>
        )
    }
}

export default HTSForecastReportPages;