import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import 'bootstrap/dist/css/bootstrap.css';
import configureStore from './store';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import { history } from "./history";

const { store, persistor } = configureStore(history);

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

//registerServiceWorker();
