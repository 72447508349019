import React, { useContext, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import ReactDOMServer from 'react-dom/server';
import MapContext from "../Map/MapContext";
import OLVectorLayer from "ol/layer/Vector";
import { Fill, Stroke, Style } from "ol/style";
import hts from '../Source/hts';
//import { fromLonLat, toLonLat } from 'ol/proj';
import Overlay from "ol/Overlay";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";


const HTSLayer = ({ filter }) => {
	const [hasWindowBeenResized, setWindowHasBeenResized] = useState(false);
	const { map } = useContext(MapContext);
	const drawerOpen = useSelector(state => state.user.drawerOpen);
    let vectorLayer = new OLVectorLayer();
	const popup = document.createElement('div');
	//popup.id = 'popup';
	popup.className = 'ol-popup';
	if (map && (hasWindowBeenResized || drawerOpen.toLocaleString())) {
		setTimeout(function () {
			map.updateSize();
		}, 500);
    }

	function getColor(feature) {
		let positivity = feature.get('positivity');
		//const avgPositiviy = Math.avg(positivity);
		if(positivity <= 0.025){
			return '#ffae00';
		}
		else if(positivity < 0.035 ){
			return '#ff7e00';
		}
		else if(positivity < 0.05 ){
			return '#ff4200';
		}
		else if(positivity < 0.08 ){
			return '#d90000';
		}
		else {
			return '#c30000';
		}
	}

	function createPopup(feature) {
		popup.innerHTML = '';
		if(!feature){
			//return 'Nothing to see Here';
			popup.style.display = 'none';
			return;
		}
		//const positivity = feature.get('positivity');
		popup.style.display = 'block';
		const html ='<div class="ol-header">'
						+ '<b>' + feature.get('name') + '</b>'
					+ '</div>'

					+ '<div class="ol-body">'
						+ 'Number Tested : <b>' + (feature.get('tested')).toLocaleString() + '</b>'
						+ '<br> Positive : <b>' + (feature.get('positive')).toLocaleString() + '</b>'
						+ '<br> Positivity : <b>' + (feature.get('positivity')*100).toFixed(1) + '%</b>'
					+ '</div>';

		return html;
	}

	/*function createPopover(feature,coordinate) {
		const popover = () => {
			return <Popover>
					<PopoverHeader>{feature.get('name')}</PopoverHeader>
					<PopoverBody>{feature.get('tested')}</PopoverBody>
				</Popover>
		}

		var pop = new Overlay({
			element: popover(),
			position: coordinate,
			autoPan: true,
			autoPanAnimation: {
				duration: 250,
			},

		});
	}
	*/

	/*const popover = () => {
		return (<Popover>
				<PopoverHeader>Header</PopoverHeader>
				<PopoverBody>Body</PopoverBody>
			</Popover>);
	}*/

	const popover = () => {
		return ('<div>Hi</div>');
	}

	useEffect(() => {
		if (!map) return;

		let htsSource = hts({ filter });

		vectorLayer.setSource(htsSource);
		map.addLayer(vectorLayer);
		vectorLayer.setZIndex(1);
		vectorLayer.setStyle(function (feature) {
			return new Style({
				fill: new Fill({
					color: getColor(feature),
				  }),
				  stroke: new Stroke({
					color: 'rgba(255,255,255,1)',
				  }),
			})
		});
		vectorLayer.setOpacity(0.8);
		map.getOverlays().clear();
		map.on('click', function (evt) {
			map.getView().animate({ center: evt.coordinate });
			const feature = Promise.resolve(vectorLayer.getFeatures(evt.pixel)).then(value => {
				popup.innerHTML = createPopup(value[0]);
			});
			const pop = new Overlay({
				element: popup,
				position: evt.coordinate,
				autoPan: true,
				autoPanAnimation: {
					duration: 250,
				},
			});
			map.getOverlays().clear();
			map.addOverlay(pop);
		});

		vectorLayer.once('change', () => {
			map.getView().fit(vectorLayer.getSource().getExtent(), {
				size: map.getSize(),
				maxZoom: 16,
				duration: 1000
            });
		});

		return () => {
			if (map) {
				map.removeLayer(vectorLayer);
			}
		};
	}, [map, filter]);

	const handleResize = () => {
		setWindowHasBeenResized(true);
	}
	window.addEventListener('resize', handleResize);

	return null;
};

export default HTSLayer;