import React, { Component } from 'react';
import Layout from '../../../Layout';
import ExpandedTBOutcomesReportPages from './ExpandedTBOutcomesReportPages';
import { OrgUnitFilterContext } from '../../../Reports/Filters/OrgUnitFilterContext';
import { SubmitFilters } from '../../../Reports/Filters/SubmitFilters';
import { OrgUnitFilter } from '../../../Reports/Filters/OrgUnitFilter';
import ChartsDrawer from '../ChartsDrawer';
import ChartsContent from '../ChartsContent';
import ShinyChartTemplate from '../../../Reports/Charts/ShinyChartTemplate';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

class ExpandedTBOutcomesLayout3 extends Component {

    state = {
        componentFilter: localStorage.getItem('componentFilter'),
    }

    render() {        
        return (
            <Layout>               
                <div className="row" style={{ height: "85vh" }}>
                    <ChartsDrawer>
                        <OrgUnitFilterContext>
                            <OrgUnitFilter 
                                    provinceDisable={false}
                                    districtDisable = {true}
                                    subDistrictDisable = {true}
                                    facilityDisable = {true}
                            />
                            <SubmitFilters />
                        </OrgUnitFilterContext>
                        <hr />
                        <ExpandedTBOutcomesReportPages />
                    </ChartsDrawer>
                    <ChartsContent>
                        <div className="chart-container" style={{ height: "85vh" }} >
                            <Tabs>
                                <TabList>
                                    <Tab>Age &amp; Sex</Tab>
                                    <Tab>Entry</Tab>
                                    <Tab>TB/HIV</Tab>
                                </TabList>
                                <TabPanel>
                                    <div className="row" style={{ height: "38vh" }}>
                                        <div className="col-md-8">
                                            <ShinyChartTemplate resourceQuestion={"epi/tboutcomes/tx_success_age_plot"} title="Treatment Success Rates by Fine Age Group" />
                                        </div>
                                        <div className="col-md-4">
                                            <ShinyChartTemplate resourceQuestion={"epi/tboutcomes/tx_success_bage_table"} title="Treatment Success Rates by Coarse Age Group" />
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row" style={{ height: "38vh" }}>
                                        <div className="col-md-8">
                                            <ShinyChartTemplate resourceQuestion={"epi/tboutcomes/tx_success_agesex_plot"} title="Treatment Success Rates by Age Group and Sex" />
                                        </div>
                                        <div className="col-md-4">
                                            <ShinyChartTemplate resourceQuestion={"epi/tboutcomes/tx_success_sex_table"} title="Treatment Success Rates by Sex" />
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                <div className="row" style={{ height: "78vh" }}>
                                        <div className="col-md-6">
                                            <ShinyChartTemplate resourceQuestion={"epi/tboutcomes/tx_success_patientcat_plot"} title="Treatment Success Rates by Patient Category" />
                                        </div>
                                        <div className="col-md-6">
                                        <div className="row" style={{ height: "38vh" }}>
                                            <div className="col-md-12">
                                                <ShinyChartTemplate resourceQuestion={"epi/tboutcomes/tx_success_entry_plot"} title="Treatment Success Rates by Entry" />
                                            </div>
                                        </div>
                                        <br></br>
                                        <div className="row" style={{ height: "38vh" }}>
                                            <div className="col-md-12">
                                                <ShinyChartTemplate resourceQuestion={"epi/tboutcomes/tx_success_tbtype_plot"} title="Treatment Success Rates by TB Type" />
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    
                                </TabPanel>
                                <TabPanel></TabPanel>
                            </Tabs>
                        </div>
                    </ChartsContent>
                </div>
            </Layout>
        )
    }
}

export default ExpandedTBOutcomesLayout3;