import React, { useState } from 'react';
import { FormGroup, Label, Container, Navbar, NavbarBrand } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import queryString from 'query-string';
import { useHistory } from 'react-router'

const ResetPassword = () => {

    const [newPasword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const history = useHistory();

    const goToLogin = () => {
       history.push("/login");
    };

    const resetPassword = () => {

        const infoPassed = queryString.parse(window.location.search);

        const data = {
            email: infoPassed.email,
            token: infoPassed.token,
            password: newPasword
        };

        console.log('JSON :' + JSON.stringify(data));

        axios.post('/api/users/resetpassword', data)
            .then(response => {
                NotificationManager.success('Password Reset successful!', '', 2000);
                goToLogin();
            })
            .catch(error => {
                NotificationManager.error(error.message, '', 10000);
            });
    }

    const resetValues = () => {
        setNewPassword("");
        setConfirmPassword("");
    }

    return (
        <div>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3 bg-light" light>
                <Container fluid>
                    <NavbarBrand to="/login">
                        <img src="images/rsa-coat-of-arms.png" width="30" height="30" alt="" />
                                CHISA
                            </NavbarBrand>
                </Container>
            </Navbar>

            <AvForm onValidSubmit={resetPassword} >

                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label className="color" htmlFor="pwd">New Password:</Label>
                            <AvField
                                name="pwd"
                                type="password"
                                className="form-control-sm form-control"
                                placeholder="New Password"
                                value={newPasword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: 'New Password is required!'
                                    },
                                    pattern: {
                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W]).{6,}$/,
                                        errorMessage: 'Password must be composed of uppercase character, lowercase character, a digit, a non-alphanumeric character and be 6 characters long'
                                    },
                                    minLength: {
                                        value: 6,
                                        errorMessage: 'Password must be at least six characters long'
                                    }
                                }} />
                        </FormGroup>
                        <FormGroup>
                            <Label className="color" htmlFor="confirm_pwd">Confirm Password:</Label>
                            <AvField
                                name="confirm_pwd"
                                type="password"
                                className="form-control-sm form-control"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                validate={{ match: { value: 'pwd' } }}
                                errorMessage="Password does not match"
                                required />
                        </FormGroup>
                    </div>




                </div>

                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-2">
                        <button
                            type="submit"
                            className="btn btn-infohub w-100" >Submit</button>
                    </div>
                    <div className="col-md-2">
                        <button
                            type="reset"
                            className="btn btn-secondary w-100"
                            onClick={resetValues} >Reset</button>
                    </div>
                    <div className="col-md-8"></div>
                </div>

            </AvForm>
        </div>
    );
}

export default ResetPassword;