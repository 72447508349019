import React, { useContext, useState } from 'react';

const HandleGenderContext = React.createContext();

const GenderContext = React.createContext();

export function useHandleGenderContext() {
    return useContext(HandleGenderContext);
}

export function useGenderContext() {
    return useContext(GenderContext);
}


export function GenderFilterContext({ children }) {

    const [gender, setGender] = useState([]);

    const handleGenderChange = (val) => {
        setGender(val);
        console.log('Gender context : ' + gender);

    };

    return (
        <HandleGenderContext.Provider value={handleGenderChange}>
            <GenderContext.Provider value={gender}>
                {children}
            </GenderContext.Provider>
        </HandleGenderContext.Provider>

    );
}