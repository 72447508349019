import React, { Component } from 'react';
import { FormGroup, Button } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import { connect } from 'react-redux';
import * as NAVIGATION_TYPES from '../../actions/types';

var jwt = require("jsonwebtoken");


class ReportsFilter extends Component {


    state = {
        provinceId: this.props.provinceIdFilter !== '0' ? this.props.provinceIdFilter : '0',
        districtId: this.props.districtIdFilter !== '0' ? this.props.districtIdFilter : '0',
        subDistrictId: this.props.subDistrictIdFilter !== '0' ? this.props.subDistrictIdFilter : '0',
        facilityId: this.props.facilityIdFilter !== '0' ? this.props.facilityIdFilter : '0',
        provinceName: this.props.provinceNameFilter !== null ? this.props.provinceNameFilter : null,
        districtName: this.props.districtNameFilter !== null ? this.props.districtNameFilter : null,
        subDistrictName: this.props.subDistrictNameFilter !== null ? this.props.subDistrictNameFilter : null,
        facilityName: this.props.facilityNameFilter !== null ? this.props.facilityNameFilter : null,
        disableProvince: true,
        disableDistrict: true,
        disableSubDistrict: true,
        disableFacility: true,
        provinces: [],
        districts: [],
        subDistricts: [],
        facilities: [],
    }

    userOrgUnitClaimData = () => {

        //var token = localStorage.getItem('currentUser');
        var token = this.props.userToken;
        var data = '';
        if (token !== null) {
            token = token.replace(/['"]+/g, '');
            data = jwt.decode(token);
        }

        this.loadProvinces();
        this.loadDistricts(this.props.provinceIdFilter !== '0' ? this.props.provinceIdFilter : data.userOrgUnitProvinceClaim);
        this.loadSubDistricts(this.props.districtIdFilter !== '0' ? this.props.districtIdFilter : data.userOrgUnitDistrictClaim);
        this.loadFacilities(this.props.subDistrictIdFilter !== '0' ? this.props.subDistrictIdFilter : data.userOrgUnitSubDistrictClaim);

        if (data.provinceName !== "") {
            this.setState({ provinceName: data.provinceName });
            this.props.onProvinceChange({ id: data.userOrgUnitProvinceClaim, name: data.provinceName });
        }

        if (data.districtName !== "") {
            this.setState({ districtName: data.districtName });
            this.props.onDistrictChange({ id: data.userOrgUnitDistrictClaim, name: data.districtName });
        }

        if (data.subDistrictName !== "") {
            this.setState({ subDistrictName: data.subDistrictName });
            this.props.onSubDistrictChange({ id: data.userOrgUnitSubDistrictClaim, name: data.subDistrictName });
        }

        if (data.facilityName !== "") {
            this.setState({ facilityName: data.facilityName });
            this.props.onFacilityChange({ id: data.userOrgUnitFacilityClaim, name: data.facilityName });
        }


        /*if (data.facilityName !== "")
            this.props.onFacilityChange(data.facilityName);*/

        if (data.userOrgUnitProvinceClaim === "0") {
            this.setState({ disableProvince: false })
        }

        if (data.userOrgUnitDistrictClaim === "0") {
            this.setState({ disableDistrict: false })
        }

        if (data.userOrgUnitSubDistrictClaim === "0") {
            this.setState({ disableSubDistrict: false })
        }

        if (data.userOrgUnitFacilityClaim === "0") {
            this.setState({ disableFacility: false })
        }

        this.setState({ provinceId: this.props.provinceIdFilter !== '0' ? this.props.provinceIdFilter : data.userOrgUnitProvinceClaim });
        this.setState({ districtId: this.props.districtIdFilter !== '0' ? this.props.districtIdFilter : data.userOrgUnitDistrictClaim });
        this.setState({ subDistrictId: this.props.subDistrictIdFilter !== '0' ? this.props.subDistrictIdFilter : data.userOrgUnitSubDistrictClaim });
        this.setState({ facilityId: this.props.facilityIdFilter !== '0' ? this.props.facilityIdFilter : data.userOrgUnitFacilityClaim });

    }

    loadProvinces = () => {
        axios.get('/api/orgunit/provinces')
            .then(response => {
                this.setState({ provinces: response.data });
            });

        this.setState({ districts: [] });
        this.setState({ subDistricts: [] });
        this.setState({ facilities: [] });
    }

    loadDistricts = (provinceId) => {
        axios.get('/api/orgunit/districts/' + provinceId)
            .then(response => {
                this.setState({ districts: response.data });
            })
            .catch(error => { });

        this.setState({ subDistricts: [] });
        this.setState({ facilities: [] });
    }

    loadSubDistricts = (districtId) => {
        axios.get('/api/orgunit/subdistricts/' + districtId)
            .then(response => {
                this.setState({ subDistricts: response.data });
            })
            .catch(error => { });

        this.setState({ facilities: [] });
    }

    loadFacilities = (subDistrictId) => {
        axios.get('/api/orgunit/facilities/' + subDistrictId)
            .then(response => {
                this.setState({ facilities: response.data });
            })
            .catch(error => { });
    }

    componentDidMount() {
        this.userOrgUnitClaimData();
    }

    render() {
        return (
            <div className="container">
                <AvForm>

                    <div className="row">

                        <div className="col-md-12">
                            <FormGroup className="pt-1">
                                <Autocomplete
                                    id="province_select"
                                    options={this.state.provinces}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            this.setState({ provinceId: newValue.organisationUnitID });
                                            this.loadDistricts(newValue.organisationUnitID);
                                            this.setState({ provinceName: newValue.name });
                                            this.setState({ districtId: '0', districtName: null });
                                            this.setState({ subDistrictId: '0', subDistrictName: null });
                                            this.setState({ facilityId: '0', facilityName: null });
                                        }
                                        else {
                                            this.setState({ provinceId: '0', provinceName: null });
                                            this.setState({ districtId: '0', districtName: null });
                                            this.setState({ subDistrictId: '0', subDistrictName: null });
                                            this.setState({ facilityId: '0', facilityName: null });
                                        }
                                    }}
                                    autoHighlight
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Province"
                                            variant="outlined"
                                        />
                                    )}
                                    disabled={this.state.disableProvince}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Autocomplete
                                    id="district_select"
                                    options={this.state.districts}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            this.setState({ districtId: newValue.organisationUnitID });
                                            this.loadSubDistricts(newValue.organisationUnitID);
                                            this.setState({ districtName: newValue.name });
                                            this.setState({ subDistrictId: '0', subDistrictName: null });
                                            this.setState({ facilityId: '0', facilityName: null });
                                        }
                                        else {
                                            this.setState({ districtId: '0', districtName: null });
                                            this.setState({ subDistrictId: '0', subDistrictName: null });
                                            this.setState({ facilityId: '0', facilityName: null });
                                        }
                                    }}
                                    autoHighlight
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select District"
                                            variant="outlined"
                                        />
                                    )}
                                    disabled={this.state.disableDistrict}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Autocomplete
                                    id="subDistrict_select"
                                    options={this.state.subDistricts}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            this.setState({ subDistrictId: newValue.organisationUnitID });
                                            this.loadFacilities(newValue.organisationUnitID);
                                            this.setState({ subDistrictName: newValue.name });
                                            this.setState({ facilityId: '0', facilityName: null });
                                        }
                                        else {
                                            this.setState({ subDistrictId: '0', subDistrictName: null });
                                            this.setState({ facilityId: '0', facilityName: null });
                                        }
                                    }}
                                    autoHighlight
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Sub-District"
                                            variant="outlined"
                                        />
                                    )}
                                    disabled={this.state.disableSubDistrict}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Autocomplete
                                    id="facility_select"
                                    options={this.state.facilities}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            this.setState({ facilityId: newValue.organisationUnitID });
                                            this.setState({ facilityName: newValue.name });
                                        }
                                        else {
                                            this.setState({ facilityId: '0', facilityName: null });
                                        }
                                    }}
                                    autoHighlight
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Facility"
                                            variant="outlined"
                                        />
                                    )}
                                    disabled={this.state.disableFacility}
                                />
                            </FormGroup>
                            <Button
                                className="btn-infohub w-100"
                                onClick={
                                    () => {
                                        this.props.onProvinceChange({ id: this.state.provinceId, name: this.state.provinceName });
                                        this.props.onDistrictChange({ id: this.state.districtId, name: this.state.districtName });
                                        this.props.onSubDistrictChange({ id: this.state.subDistrictId, name: this.state.subDistrictName });
                                        this.props.onFacilityChange({ id: this.state.facilityId, name: this.state.facilityName });
                                    }
                                }>Filter</Button>
                        </div>
                    </div>
                </AvForm>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        uprs: state.user.userPinnedReports,
        userToken: state.user.userToken,
        provinceIdFilter: state.user.reportFilterProvinceId,
        districtIdFilter: state.user.reportFilterDistrictId,
        subDistrictIdFilter: state.user.reportFilterSubDistrictId,
        facilityIdFilter: state.user.reportFilterFacilityId,

        provinceNameFilter: state.user.reportFilterProvinceName,
        districtNameFilter: state.user.reportFilterDistrictName,
        subDistrictNameFilter: state.user.reportFilterSubDistrictName,
        facilityNameFilter: state.user.reportFilterFacilityName
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        onProvinceChange: (value) => {
            dispatch({
                type: NAVIGATION_TYPES.REPORT_FILTER_PROVINCE,
                payload: value
            });
        },

        onDistrictChange: (value) => {
            dispatch({
                type: NAVIGATION_TYPES.REPORT_FILTER_DISTRICT,
                payload: value
            });
        },

        onSubDistrictChange: (value) => {
            dispatch({
                type: NAVIGATION_TYPES.REPORT_FILTER_SUB_DISTRICT,
                payload: value
            });
        },

        onFacilityChange: (value) => {
            dispatch({
                type: NAVIGATION_TYPES.REPORT_FILTER_FACILITY,
                payload: value
            });
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsFilter);