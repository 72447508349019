import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux'
import { ReportCard } from './ReportCard';

const Reports = () => {

    const [reports, setReports] = useState([]);

    const userId = useSelector(state => state.user.user.sub);

    const getLastItem = thePath => {
        return thePath.substring(thePath.lastIndexOf('/') + 1);
    }

    const getUserId = () => {
        return userId;
    }

    useEffect(() => {      
        if (getLastItem(window.location.href) > 0) {
            if (getLastItem(window.location.href) === localStorage.getItem('reportGroupId')) {

                axios.get('/api/reports/reportgroup/' + getLastItem(window.location.href) + '/' + getUserId())
                    .then(response => {
                        setReports(response.data);
                    });
            }
        }

        localStorage.setItem('reportGroupId', '');
    });

        const reportsReturned = reports.map(report => {
            return (
                <div key={report.reportId} className="col-md-3">
                    <ReportCard
                        reportId={report.reportId}
                        reportName={report.reportName}
                        reportDesc={report.reportShortDescription}
                        reportRoute={report.reportRoute}
                        userPinnedReportId={report.userPinnedReportId}
                        reportPinned={report.userPinned}
                    />
                </div>
                );
        });

        return (
            <div className="row mt-3">
                {reportsReturned}
            </div>
        );
}

export default Reports;