import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

const HandleCohortContext = React.createContext();

const CohortContext = React.createContext();

export function useHandleCohortContext() {
    return useContext(HandleCohortContext);
}

export function useCohortContext() {
    return useContext(CohortContext);
}


export function CohortFilterContext({ children }) {

    const _cohort = useSelector(state => state.user.reportFilterCohort);
    const [cohort, setCohort] = useState(_cohort);

    const handleCohortChange = (val) => {
        setCohort(val);
        console.log('Cohort context : ' + cohort);

    };

    return (
        <HandleCohortContext.Provider value={handleCohortChange}>
            <CohortContext.Provider value={cohort}>
                {children}
            </CohortContext.Provider>
        </HandleCohortContext.Provider>

    );
}