import React, { Component } from 'react';
import Layout from '../../Layout';

class HTSForecast extends Component {

    render() {

        var iframeUrl = "https://shiny.chi-sa.org/hts/forecast/";

        return (
            <Layout>
                <div id="predict" className="w-100" style={{ height: "85vh" }}>
                    <iframe
                        src={iframeUrl}
                        frameBorder={0}
                        width="100%"
                        height="100%"
                        title="HTS Forecast"
                    />
                </div>
            </Layout>
        );
    }
}

export default HTSForecast;