import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { authentication } from "../_services/authentication";
import axios from 'axios';

function CountdownModal({ handleClose, handleCancel, handleTimeout, showModal, idleTimer }) {
    const [countDown, setCountDown] = useState(typeof(idleTimer.countdownPeriod) === "undefined" ? 98789 : idleTimer.countdownPeriod);
    const [countDownStart, setCountDownStart] = useState(false);
    const [countMessage, setCountMessage] = useState('');

    useEffect(() => {
        const countdownTimer = setTimeout(() => {
            if (localStorage.getItem("_timeoutStarted") === "true") {
                let counter = countDown;
                if (!countDownStart) {
                    counter = idleTimer.countdownPeriod;
                    setCountDownStart(true);
                }

                if (counter > 1) {
                    showModal ? setCountDown(counter - 1) : setCountDown(counter);
                }
                else {
                    onTimeout();
                }
            }
        }, 1000);

        axios.get('/api/systemsettings/Setting/TimerMessage')
            .then(response => {
                setCountMessage(response.data);
            });

        return () => clearTimeout(countdownTimer);
    });

    const onCancel = () => {
        if (localStorage.getItem("_timeoutStarted") === "true") {
            handleClose();
            handleCancel();
            setCountDown(idleTimer.countdownPeriod);
            setCountDownStart(false);
        }
    }

    const onTimeout = () => {
        //if (localStorage.getItem("_timeoutStarted") === "true") {
        handleClose();
        setCountDown(idleTimer.countdownPeriod);
        setCountDownStart(false);
        authentication.logout();
        handleTimeout();
        console.log("onTimeout");
        //}
    }

    const displayCounter = () => {
        if (typeof (countDown) !== "undefined" && countDown !== 98789 && countDown !== idleTimer.countdownPeriod) {
            return showModal ? countDown : "Timed Out";
        }
        return "In...";
    }

    return (
        <Modal isOpen={showModal} style={{ width: "20%" }}>
            <ModalHeader className="color" >Timeout</ModalHeader>
            <ModalBody>
                <div style={{ textAlign: "center" }}>
                    <p>{countMessage }</p>
                    <h5 className="color">{displayCounter()}</h5>
                    <Button
                        className="btn btn-infohub w-50" type="button" onClick={onCancel}>
                        Cancel
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default CountdownModal;