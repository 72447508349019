import React, { Component } from 'react';
import MetabaseChartTemplate from '../../../Reports/Charts/MetabaseChartTemplate';
import Layout from '../../../Layout';
import ARTInitiationReportPages from './ARTInitiationReportPages';
import { DateRangeFilterContext } from '../../../Reports/Filters/DateRangeFilterContext';
import { OrgUnitFilterContext } from '../../../Reports/Filters/OrgUnitFilterContext';
import { DateRangeFilter } from '../../../Reports/Filters/DateRangeFilter';
import { SubmitFilters } from '../../../Reports/Filters/SubmitFilters';
import { OrgUnitFilter } from '../../../Reports/Filters/OrgUnitFilter';
import ChartsDrawer from '../ChartsDrawer';
import ChartsContent from '../ChartsContent';

class ARTInitiationLayout2 extends Component {

    state = {
        componentFilter: localStorage.getItem('componentFilter'),
        defaultStartDate: new Date("2021-01-01"),
        defaultEndDate: new Date("2021-12-31"),
        minDate: new Date("2010-01-01"),
        maxDate: new Date("2021-12-31")
    }

    render() {
        return (
            <Layout>                
                <div className="row" style={{ height: "85vh" }}>
                    <ChartsDrawer>
                        <OrgUnitFilterContext>
                            <DateRangeFilterContext>
                                    <OrgUnitFilter />
                                    <DateRangeFilter defaultStartDate={this.state.defaultStartDate} defaultEndDate={this.state.defaultEndDate} />
                                    <SubmitFilters />
                            </DateRangeFilterContext>
                        </OrgUnitFilterContext>
                        <hr />
                        <ARTInitiationReportPages />
                    </ChartsDrawer>
                    <ChartsContent>
                    <div className="chart-container" style={{ height: "85vh" }} >
                            <div className="row" style={{ height: "50vh" }}>
                                <div className="col-md-4">
                                    <MetabaseChartTemplate resourceQuestion={76} title="Time to ART Start" />
                                </div>
                                <div className="col-md-8">
                                    <MetabaseChartTemplate resourceQuestion={77} title="Median Time to ART Start" />
                                </div>
                            </div>
                        </div>
                    </ChartsContent>
                </div>
            </Layout>
        )
    }
}

export default ARTInitiationLayout2;