import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Nav } from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';
import { AiOutlineUser, AiFillCaretDown, AiOutlineHome, AiOutlineBarChart, AiOutlineQuestionCircle, AiOutlinePoweroff, AiOutlineTeam, AiOutlineDotChart, AiOutlineSetting } from "react-icons/ai";
import { VscShield, VscKey } from "react-icons/vsc";
import { connect } from 'react-redux';
import './NavMenu.css';
import * as NAVIGATION_TYPES from '../actions/types';
import { authentication } from "../_services/authentication";

class NavMenu extends React.PureComponent {

    setComponentFilterState = (value) => {
        localStorage.setItem('componentFilter', value);
    }

    render() {
        return (

            <div>

                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow bg-light" light>
                    <Container fluid>
                        <NavbarBrand tag={Link} to="/">
                            <img src="images/chisa-logo.png" width="136" height="40" alt="" />

                        </NavbarBrand>

                        <Collapse navbar>
                            <ul className="navbar-nav flex-grow d-sm-inline-flex">

                                <NavLink
                                    exact
                                    to="/"
                                    activeClassName="activeNavLink">
                                    <AiOutlineHome className="mb-1 mr-1" style={{ fontSize: "20px" }} />
                                    Home</NavLink>
                                <NavLink
                                    to="/userpinnedreports"
                                    activeClassName="activeNavLink"
                                    onClick={() => this.setComponentFilterState(NAVIGATION_TYPES.USER_PINNED_REPORTS)}>
                                    <AiOutlineBarChart className="mb-1 mr-1" style={{ fontSize: "20px" }} />
                                    Reports</NavLink>
                                {/*<NavLink
                                    to="/help"
                                    activeClassName="activeNavLink">
                                    <AiOutlineQuestionCircle className="mb-1 mr-1" style={{ fontSize: "20px" }} />
                                Help</NavLink>*/}

                                {this.props.userRole === 'ADMIN' &&
                                    <Nav className="mt-2" navbar>
                                        <UncontrolledDropdown nav inNavbar>
                                            <DropdownToggle style={{ color: "#02642e" }} nav caret>
                                                <VscShield className="mb-1 mr-1" style={{ fontSize: "20px" }} />Admin
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <DropdownItem>
                                                    <NavLink
                                                        to="/manageusers"
                                                        activeClassName="activeNavLink"
                                                        onClick={() => this.setComponentFilterState(NAVIGATION_TYPES.MANAGE_USER)}>
                                                        <AiOutlineTeam className="mb-1 mr-1" style={{ fontSize: "20px" }} />
                                                        Manage Users
                                                    </NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavLink
                                                        to="/reportgroup"
                                                        activeClassName="activeNavLink"
                                                        onClick={() => this.setComponentFilterState(NAVIGATION_TYPES.REPORT_GROUP)}>
                                                        <AiOutlineDotChart className="mb-1 mr-1" style={{ fontSize: "20px" }} />
                                                        Report Management
                                                    </NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavLink
                                                        to="/systemsettings"
                                                        activeClassName="activeNavLink"
                                                        onClick={() => this.setComponentFilterState(NAVIGATION_TYPES.SYSTEM_SETTINGS)}>
                                                        <AiOutlineSetting className="mb-1 mr-1" style={{ fontSize: "20px" }} />
                                                        System Settings
                                                    </NavLink>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </Nav>
                                }


                            </ul>
                        </Collapse>

                        <ul className="navbar-nav flex-grow">


                            <div className="pr-1 d-flex align-items-center color justify-content-end text-right">
                                Welcome {this.props.userFullName !== undefined ? this.props.userFullName.toUpperCase() : ''}<br />
                                {this.props.userOrgUnit !== undefined ? this.props.userOrgUnit.toUpperCase() : ''}
                            </div>
                            <div className="d-flex align-items-center color justify-content-end">
                                <Nav navbar>
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle style={{ color: "#02642e" }} nav>
                                            <AiOutlineUser style={{ fontSize: '40px' }} />
                                            <AiFillCaretDown />
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <DropdownItem>
                                                <NavLink
                                                    to="/user/changepwd"
                                                    activeClassName="activeNavLink"
                                                    onClick={() => this.setComponentFilterState(NAVIGATION_TYPES.CHANGE_PWD)}
                                                >
                                                    <VscKey className="mb-1 mr-1" />
                                                    Change Password
                                                </NavLink>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <NavLink
                                                    to="/login"
                                                    activeClassName="activeNavLink"
                                                    onClick={this.props.logout}>
                                                    <AiOutlinePoweroff className="mb-1 mr-1" />
                                                    Logout
                                                </NavLink>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </Nav>
                            </div>
                        </ul>

                    </Container>
                </Navbar>


            </div>

        );
    }
}

const mapStateToProps = state => {

    if (state.user.user !== undefined) {
        return {
            userToken: state.user.userToken,
            userRole: state.user.user.role,
            userFullName: state.user.user.userFullName,
            userOrgUnit: state.user.user.facilityName !== "" ? state.user.user.facilityName :
                state.user.user.subDistrictName !== "" ? state.user.user.subDistrictName :
                    state.user.user.districtName !== "" ? state.user.user.districtName :
                        state.user.user.provincetName !== "" ? state.user.user.provinceName : ""


        };
    }
    else {
        return {
            userToken: "",
            userFullName: "",
            userOrgUnit: ""
        }
        
    }
};

const mapDispatchToProps = (dispatch) => {
    return {

        logout: () => {
            authentication.logout();

            dispatch({
                type: NAVIGATION_TYPES.LOG_OUT,
                payload: ""
            })
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);