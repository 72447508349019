import React, { useRef, useState, useEffect } from "react"
import "./Map.css";
import MapContext from "./MapContext";
import * as ol from "ol";

const Map = ({ children, zoom, center }) => {
	const mapRef = useRef();
	const [map, setMap] = useState(null);
	
	
	// on component mount
	useEffect(() => {
		let options = {
			view: new ol.View({ zoom, center }),
			layers: [],
			controls: [],
			overlays: []
		};

		let mapObject = new ol.Map(options);
		mapObject.setTarget(mapRef.current);
		setMap(mapObject);

		return () => mapObject.setTarget(undefined);
	}, []);

	// zoom change handler
	useEffect(() => {
		if (!map) return;

		map.getView().setZoom(zoom);
	}, [zoom]);

	// center change handler
	useEffect(() => {
		if (!map) return;

		map.getView().setCenter(center)
	}, [center])
	
	return (
		<MapContext.Provider value={{ map }} >
			<div className="card mb-3 metabase-chart">
                    <div className="card-header align-items-center text-truncate">
                        <small> HTS Positivity Map</small> {/*Dynamic Title TODO*/}
                    </div>
                    <div className="card-body metabase-chart metabase-card-body">
						<div ref={mapRef} className="ol-map">
							{children}
						</div>
					</div>
			</div>
		</MapContext.Provider>
	)
}

export default Map;