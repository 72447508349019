import React, { Component } from 'react';
import Layout from '../../../Layout';
import HIVOutcomesReportPages from './HIVOutcomesReportPages';
import { DateRangeFilterContext } from '../../../Reports/Filters/DateRangeFilterContext';
import { OrgUnitFilterContext } from '../../../Reports/Filters/OrgUnitFilterContext';
//import { DateRangeFilter } from '../../../Reports/Filters/DateRangeFilter';
import { SubmitFilters } from '../../../Reports/Filters/SubmitFilters';
import { OrgUnitFilter } from '../../../Reports/Filters/OrgUnitFilter';
import ChartsDrawer from '../ChartsDrawer';
import ChartsContent from '../ChartsContent';
import ShinyChartTemplate from '../../../Reports/Charts/ShinyChartTemplate';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

class HIVOutcomesLayout2 extends Component {

    state = {
        componentFilter: localStorage.getItem('componentFilter'),
        //defaultStartDate: new Date("2020-07-01"),
        //defaultEndDate: new Date("2021-06-30"),
        //minDate: new Date("2019-01-01"),
        //maxDate: new Date("2021-06-30")
    }

    render() {        
        return (
            <Layout>               
                <div className="row" style={{ height: "85vh" }}>
                    <ChartsDrawer>
                        <OrgUnitFilterContext>
                            <DateRangeFilterContext>
                                <OrgUnitFilter 
                                    provinceDisable={false}
                                    districtDisable = {true}
                                    subDistrictDisable = {true}
                                    facilityDisable = {true}
                                />
                                {/*<DateRangeFilter
                                    defaultStartDate={this.state.defaultStartDate}
                                    defaultEndDate={this.state.defaultEndDate}
                                    minDate={this.state.minDate}
                                    maxDate={this.state.maxDate}
                                />*/}
                                <SubmitFilters />
                            </DateRangeFilterContext>
                        </OrgUnitFilterContext>
                        <hr />
                        <HIVOutcomesReportPages />
                    </ChartsDrawer>
                    <ChartsContent>
                        <div className="chart-container">
                            <div className="row chart-row-100">
                                <div className="col-md-12">
                                        <ShinyChartTemplate resourceQuestion={"epi/hivoutcomes/predictors_of_attrition"} title="Predictors of Attrition" />
                                </div>
                            </div>
                        </div>
                    </ChartsContent>
                </div>
            </Layout>
        )
    }
}

export default HIVOutcomesLayout2;