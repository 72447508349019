import React, { Component } from 'react';
import MetabaseChartTemplate from '../../../Reports/Charts/MetabaseChartTemplate';
import Layout from '../../../Layout';
import TxReportPages from './TxReportPages';
import { OrgUnitFilterContext } from '../../../Reports/Filters/OrgUnitFilterContext';
import { SubmitFilters } from '../../../Reports/Filters/SubmitFilters';
import { OrgUnitFilter } from '../../../Reports/Filters/OrgUnitFilter';
import ChartsDrawer from '../ChartsDrawer';
import ChartsContent from '../ChartsContent';

class Tx2 extends Component {
	state = {
		componentFilter: localStorage.getItem('componentFilter'),
	};
	render() {
		return (
			<Layout>
				<div className="row" style={{ height: '85vh' }}>
						<ChartsDrawer>
							<OrgUnitFilterContext>
									<OrgUnitFilter />
									<SubmitFilters />
							</OrgUnitFilterContext>
							<hr />
							<TxReportPages />
						</ChartsDrawer>
					<ChartsContent>
						<div className="chart-container" style={{ height: '85vh' }}>
							<div className="row" style={{ height: '41vh' }}>
								<div className="col-md-4 col-sm-12">
									<MetabaseChartTemplate 
                                        resourceQuestion={120} 
                                        title="TX_CURR Quarterly Change"
                                    />
								</div>
                                <div className="col-md-8 col-sm-12">
									<MetabaseChartTemplate 
                                        resourceQuestion={121} 
                                        title="TX_CURR by Sex and Quarter"
                                    />
								</div>
                            </div>
                            <br></br>
                            <div className="row" style={{ height: '41vh' }}>
								<div className="col-md-6 col-sm-12">
									<MetabaseChartTemplate 
                                        resourceQuestion={122} 
                                        title="TX_CURR by Sex and Age Group"
                                    />
								</div>
                                <div className="col-md-6 col-sm-12">
									<MetabaseChartTemplate 
                                        resourceQuestion={123} 
                                        title="TX_CURR by MMD and Age Group"
                                    />
								</div>
                            </div>    
						</div>
					</ChartsContent>
				</div>
			</Layout>
		);
	}
}

export default Tx2;
