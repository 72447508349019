/**
 * @TYPES
 * ===============================================
 * AUTHENTICATE
 */
export const AUTHENTICATION = 'AUTHENTICATION'
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR'
export const UNAUTHORISED_ERROR = 'UNAUTHORISED_ERROR'
export const LOG_OUT = 'LOG_OUT'

/**
 * @TYPES
 * ===============================================
 * USER
 */

export const REGISTER_REQUEST = 'USERS_REGISTER_REQUEST'
export const REGISTER_SUCCESS =  'USERS_REGISTER_SUCCESS'
export const REGISTER_FAILURE = 'USERS_REGISTER_FAILURE'
export const FETCH_USERS = 'FETCH_USERS'
export const FETCH_USER_BY_ID = 'FETCH_USER_BY_ID'
export const USERS_ERROR = 'USERS_ERROR'

/**
 * @TYPES
 * ===============================================
 * Navigation
 */

export const MANAGE_USER = 'MANAGE_USER'
export const REGISTER_USER = 'REGISTER_USER'
export const REGISTERED_USER = 'REGISTERED_USER'
export const CHANGE_PWD = 'CHANGE_PWD'
export const REPORT = 'REPORT'
export const REPORTS = 'REPORTS'
export const USER_PINNED_REPORTS = 'USER_PINNED_REPORTS'
export const METABASE = 'METABASE'
export const USERS_LIST = 'USERS_LIST'
export const REPORT_GROUP = 'REPORT_GROUP'
export const REPORT_SOURCE = 'REPORT_SOURCE'
export const REPORT_SOURCE_SETTING = 'REPORT_SOURCE_SETTING'
export const PIN_UNPIN_SUCCESS = 'PIN_UNPIN_SUCCESS'
export const PIN_UNPIN_FAILURE = 'PIN_UNPIN_FAILURE'
export const SYSTEM_SETTINGS = 'SYSTEM_SETTINGS'

/**
 * @TYPES
 * ===============================================
 * Report Filters
 */
export const REPORT_FILTER_PROVINCE = 'REPORT_FILTER_PROVINCE'
export const REPORT_FILTER_DISTRICT = 'REPORT_FILTER_DISTRICT'
export const REPORT_FILTER_SUB_DISTRICT = 'REPORT_FILTER_SUB_DISTRICT'
export const REPORT_FILTER_FACILITY = 'REPORT_FILTER_FACILITY'
export const REPORT_START_DATE = 'REPORT_START_DATE'
export const REPORT_END_DATE = 'REPORT_END_DATE'
export const REPORT_GENDER = 'REPORT_GENDER'
export const REPORT_COHORT = 'REPORT_COHORT'
export const REPORT_YEAR = 'REPORT_YEAR'
export const CLEAR_DYNAMIC_FILTERS = 'CLEAR_DYNAMIC_FILTERS'
export const FETCH_PROVINCES = 'FETCH_PROVINCES'
export const FETCH_DISTRICTS = 'FETCH_DISTRICTS'
export const FETCH_SUB_DISTRICTS = 'FETCH_SUB_DISTRICTS'
export const FETCH_FACILITIES = 'FETCH_FACILITIES'
export const SELECT_PROVINCE = 'SELECT_PROVINCE'
export const SELECT_DISTRICT = 'SELECT_DISTRICT'
export const SELECT_SUB_DISTRICT = 'SELECT_SUB_DISTRICT'
export const SELECT_FACILITY = 'SELECT_FACILITY'
export const CLEAR_SELECTED_PROVINCE = 'CLEAR_SELECTED_PROVINCE'
export const CLEAR_SELECTED_DISTRICT = 'CLEAR_SELECTED_DISTRICT'
export const CLEAR_SELECTED_SUB_DISTRICT = 'CLEAR_SELECTED_SUB_DISTRICT'
export const CLEAR_SELECTED_FACILITY = 'CLEAR_SELECTED_FACILITY'
export const CLEAR_DISTRICTS = 'CLEAR_DISTRICTS'
export const CLEAR_SUB_DISTRICTS = 'CLEAR_SUB_DISTRICTS'
export const CLEAR_FACILITIES = 'CLEAR_FACILITIES'
export const FILTERS_ERROR = 'FILTERS_ERROR'
export const REPORT_ID = 'REPORT_ID'

/**
 * @TYPES
 * ===============================================
 * DRAWER
 */

 export const TOGGLE_DRAWER = 'TOGGLE_DRAWER'

