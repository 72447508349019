import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TiDownloadOutline, TiArrowMaximise } from "react-icons/ti";
import { format } from 'date-fns';
import axios from 'axios';
//import { sign } from 'jsonwebtoken';

class ShinyChartTemplate extends Component {

    state = {
        key: null,
        url: null,
        provFil: "",
        distFil: "",
        subDistFil: "",
        facFil: "",
    }

    loadDataSourceCredentials = () => {

        axios.get('/api/reports/datasourcecredentials/' + this.props.reportId, {
            params: {
                Province: this.props.provinceFilter,
                District: this.props.districtFilter,
                Sub_District: this.props.subDistrictFilter,
                Facility: this.props.facilityFilter
            }
        })
            .then(response => {
                this.setState({
                    // key: response.data[0].value,
                    url: response.data[0].value
                });
                
            });
    }

    downloadUrl = () => {
        this.props.downloadUrl()
    }

    render() {

        if (this.state.provFil != this.props.provinceFilter || this.state.distFil != this.props.districtFilter
            || this.state.subDistFil != this.props.subDistrictFilter || this.state.facFil != this.props.facilityFilter) {
            this.loadDataSourceCredentials();
        }

        this.state.provFil = this.props.provinceFilter;
        this.state.distFil = this.props.districtFilter;
        this.state.subDistFil = this.props.subDistrictFilter;
        this.state.facFil = this.props.facilityFilter;

        if (this.state.url !== null) {
            
            var SHINY_SERVER_URL = this.state.url;
            var prov = this.props.provinceFilter;
            var district = this.props.districtFilter;
            var subdistrict = this.props.subDistrictFilter;
            var iframeUrl = SHINY_SERVER_URL + "/" + this.props.resourceQuestion + "/?province=" + prov + "&district=" + district + "&subdistrict=" + subdistrict;
            return (
                <div className="card mb-3 metabase-chart">
                    <div className="card-header align-items-center text-truncate">
                        <small> {this.props.title} in {this.props.facilityFilter !== null ?
                            this.props.facilityFilter : this.props.subDistrictFilter !== null ?
                                this.props.subDistrictFilter : this.props.districtFilter !== null ?
                                    this.props.districtFilter : this.props.provinceFilter !== null ?
                                        this.props.provinceFilter : 'the Country'}
                            {this.props.startDate ?
                                ' between ' + format(new Date(this.props.startDate), 'MMM/yy') + ' and ' + format(new Date(this.props.endDate), 'MMM/yy') : ''}

                        </small>
                        {
                            this.props.downloadUrl
                                ? <button onClick={this.downloadUrl} className="border-0 bg-transparent float-right" >
                                    <TiDownloadOutline className="color align-middle float-right" style={{ fontSize: '25px', padding: '0.2rem' }} />
                                  </button>
                            : null
                        }
                        
                    </div>
                    <div className="card-body metabase-chart metabase-card-body">
                        <iframe
                            src={iframeUrl}
                            frameBorder={0}
                            width="100%"
                            height="100%"
                            title={this.props.title}
                        />
                    </div>
                </div>
            )
        }
        else {
            return (
                <>
                    Something went wrong :-( Please contact the Systems Administrator.
                </>
               )
        }
    }
}

const mapStateToProps = state => {
    return {
        provinceFilter: state.user.reportFilterProvinceName,
        districtFilter: state.user.reportFilterDistrictName,
        subDistrictFilter: state.user.reportFilterSubDistrictName,
        facilityFilter: state.user.reportFilterFacilityName,
        startDate: state.user.reportFilterStartDate,
        endDate: state.user.reportFilterEndDate,
        cohorts: state.user.reportFilterCohort,
        year: state.user.reportFilterYear,
        reportId: state.user.reportId
    };
};

export default connect(mapStateToProps)(ShinyChartTemplate);