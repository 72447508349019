import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import * as NAVIGATION_TYPES from '../actions/types';

class UserMenu extends Component {

    setComponentFilterState = (value) => {
        localStorage.setItem('componentFilter', value);
    }

    render() {
        return (
            <div className="container mt-3" style={{ borderRight: "1px solid #e1e1e1", height: "100%" }}>
                <div className="justify-content-end pr-3">
                    <div className="row justify-content-end text-right"><NavLink to="/systemsettings" activeClassName="activeNavLink" onClick={() => this.setComponentFilterState(NAVIGATION_TYPES.SYSTEM_SETTINGS, '')}>System Settings</NavLink></div>
                </div>
            </div>
        )
    }
}

export default UserMenu;