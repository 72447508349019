import React, { useState } from 'react';
import { TiPin, TiPinOutline } from "react-icons/ti";
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { useSelector, useDispatch } from 'react-redux';
import * as acts from '../../actions/types';
import { Card, CardContent, Typography } from '@material-ui/core';

export const ReportCard = (props) => {

    const [pinUnpin, setpinUnpin] = useState(props.reportPinned);
    const userId = useSelector(state => state.user.user.sub);
    
    const dispatch = useDispatch();

    const fetchUserPinnedReports = () => {

        return function (dispatch) {
            axios.get('/api/userpinnedreports/user/' + userId)
                .then(response => {
                    dispatch({
                        type: acts.PIN_UNPIN_SUCCESS,
                        payload: response.data
                    });
                })
                .catch(error => {
                    dispatch({
                        type: acts.PIN_UNPIN_FAILURE,
                        payload: error.message
                    })
                });
        }
    }

    const PinReport = (reportId) => {

        const data = {
            reportId: reportId,
            userId: userId
        };
        

        if (pinUnpin === 'Pin Report') {
            axios.post('/api/UserPinnedReports/add', data)
                .then(response => {
                    //NotificationManager.success('Report pinned Successfully!', '', 2000);
                    setpinUnpin('Unpin Report');
                    dispatch(fetchUserPinnedReports());
                })
                .catch(error => {
                    console.log(error);
                    NotificationManager.error('An error occured!', '', 2000);
                })
        }
        else if (pinUnpin === 'Unpin Report') {
            axios.post('/api/UserPinnedReports/delete/', data)
                .then(response => {
                    //NotificationManager.success('Report unpinned Successfully!', '', 2000);
                    setpinUnpin('Pin Report');
                    dispatch(fetchUserPinnedReports());
                })
                .catch(error => {
                    console.log(error);
                    NotificationManager.error('An error occured!', '', 2000);
                })
        }

    };


    return (
        <Card sx={{ minWidth: 275}} variant="outlined" className='mb-2'>
            <CardContent className='card-report-content'>
                    <Typography sx={{ fontSize: 14 }} gutterBottom>
                    <NavLink to={props.reportRoute} className="card-report-url"
                                onClick={() => dispatch({
                                    type: acts.REPORT_ID,
                                    payload: props.reportId
                                })
                            }>{props.reportName} </NavLink>
                    
                    
                    {pinUnpin === 'Pin Report' ? <TiPinOutline size={28} className="color float-right" onClick={() => PinReport(props.reportId)} cursor='pointer'/> 
                            :  <TiPin size={28} className="color float-right" onClick={() => PinReport(props.reportId)} cursor='pointer'/>}
                    </Typography>
                
                <Typography sx={{ mb: 1.5 }} variant="body2" gutterBottom>
                    {props.reportDesc}
                </Typography>
                <Typography variant="overline">Updated on 31/03/2024</Typography>
            </CardContent>
        </Card>
            );
        }