import React, { Component } from 'react';
import Layout from '../../../Layout';
import { OrgUnitFilterContext } from '../../../Reports/Filters/OrgUnitFilterContext';
import { DateRangeFilterContext } from '../../../Reports/Filters/DateRangeFilterContext';
import { DateRangeFilter } from '../../../Reports/Filters/DateRangeFilter';
import { SubmitFilters } from '../../../Reports/Filters/SubmitFilters';
import { OrgUnitFilter } from '../../../Reports/Filters/OrgUnitFilter';
import ChartsDrawer from '../ChartsDrawer';
import ChartsContent from '../ChartsContent';
import HTSMapsReportPages from './HTSMapsReportPages';
import Map from '../../../Maps/Map';
import {osm} from '../../../Maps/Source';
import {Layers, TileLayer, HTSLayer} from '../../../Maps/Layers';
import { fromLonLat } from 'ol/proj';
import MetabaseChartTemplate from '../../../Reports/Charts/MetabaseChartTemplate';
import { connect } from 'react-redux';

class HTSMapsLayout1 extends Component {

    state = {
        componentFilter: localStorage.getItem('componentFilter'),
        defaultStartDate: new Date("2023-04-01"),
        defaultEndDate: new Date("2024-03-31"),
        minDate: new Date("2010-01-01"),
        maxDate: new Date("2024-03-31"),
        defaultFilter: "1=1",
        center: fromLonLat([25,-29])
    }
    render() {
        return (
            <Layout>               
                <div className="row" style={{ height: "85vh" }}>
                    <ChartsDrawer>
                        <OrgUnitFilterContext>
                            <DateRangeFilterContext>
                                    <OrgUnitFilter />
                                    <DateRangeFilter 
                                        defaultStartDate={this.state.defaultStartDate} 
                                        defaultEndDate={this.state.defaultEndDate} 
                                        minDate={this.state.minDate}
                                        maxDate={this.state.maxDate}
                                    />                                    
                                    <SubmitFilters />
                            </DateRangeFilterContext>
                        </OrgUnitFilterContext>
                        <hr />
                        <HTSMapsReportPages />
                    </ChartsDrawer>
                    <ChartsContent>
                        <div className="chart-container">
                            <div className="row chart-row-100">
                                    <div className="col-md-8">
                                        <Map center={this.state.center} zoom={6}>
                                            <Layers>
                                                <TileLayer source={osm()} />
                                                <HTSLayer filter={this.props}/>
                                            </Layers>
                                        </Map>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='row chart-row-50'>
                                            <div className="col-md-12">
                                                <MetabaseChartTemplate resourceQuestion={this.props.districtFilter ? 139 : this.props.provinceFilter ? 44 : 138} title="Positivity" />
                                            </div>
                                        </div>
                                       <br />
                                        <div className='row chart-row-50'>
                                            <div className="col-md-12">
                                                <MetabaseChartTemplate resourceQuestion={15} title="Q/Q Change in Positivity" />
                                            </div>
                                        </div>
                                    </div>
                                    
                            </div>
                        </div>
                    </ChartsContent>
                </div>
            </Layout>
        )
    }
}
const mapStateToProps = state => {
    return {
        provinceFilter: state.user.reportFilterProvinceName,
        districtFilter: state.user.reportFilterDistrictName,
        subDistrictFilter: state.user.reportFilterSubDistrictName,
        facilityFilter: state.user.reportFilterFacilityName,
        reportId: state.user.reportId
    };
};

export default connect(mapStateToProps)(HTSMapsLayout1);