import React, { useState, useEffect } from "react";
//import { BrowserRouter, Redirect, Switch, Route } from "react-router-dom";
import { Route } from 'react-router-dom';
import Home from "./components/Home";
import Login from "./components/Login";
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Help from "./components/Help";
import LayoutSecondary from "./components/Layout/LayoutSecondary";
import history from "./history";
import { ConnectedRouter } from 'connected-react-router';
import AppResources from './AppResources';
import TROALayout from './components/Layout/ChartsLayout/TROA/TROALayout';
import TROALayout2 from './components/Layout/ChartsLayout/TROA/TROALayout2';
import TROALayout3 from './components/Layout/ChartsLayout/TROA/TROALayout3';
import HTSLayout1 from './components/Layout/ChartsLayout/HTS/HTSLayout1';
import HTSLayout2 from './components/Layout/ChartsLayout/HTS/HTSLayout2';
import HTSLayout3 from './components/Layout/ChartsLayout/HTS/HTSLayout3';
import ChangePwdOnLogin from './components/ChangePwdOnLogin';
import HTSYield from './components/Reports/Predict/HTSYield';
import HTSForecast from './components/Reports/Predict/HTSForecast';
import PrivateRoute from './components/PrivateRoute';
import ARTInitiationLayout1 from "./components/Layout/ChartsLayout/ARTInitiation/ARTInitiationLayout1";
import ARTInitiationLayout2 from "./components/Layout/ChartsLayout/ARTInitiation/ARTInitiationLayout2";
import TLDUptakeLayout1 from "./components/Layout/ChartsLayout/TLDUptake/TLDUptakeLayout1";
import DedupLayout1 from "./components/Layout/ChartsLayout/Dedup/DedupLayout1";
import CALayout1 from "./components/Layout/ChartsLayout/CohortAnalysis/CALayout1";
import CALayout2 from "./components/Layout/ChartsLayout/CohortAnalysis/CALayout2";
import CALayout3 from "./components/Layout/ChartsLayout/CohortAnalysis/CALayout3";
import TBLayout1 from "./components/Layout/ChartsLayout/TB/TBLayout1";
import TBLayout2 from "./components/Layout/ChartsLayout/TB/TBLayout2";
import TBLayout3 from "./components/Layout/ChartsLayout/TB/TBLayout3";
import TBLayout4 from "./components/Layout/ChartsLayout/TB/TBLayout4";
import TBOutcomesLayout1 from "./components/Layout/ChartsLayout/TBOutcomes/TBOutcomesLayout1";
import DedupReportLayout1 from "./components/Layout/ChartsLayout/Dedup/DedupReportLayout1";
import DedupReportLayout2 from "./components/Layout/ChartsLayout/Dedup/DedupReportLayout2";
import HIVOutcomesLayout1 from "./components/Layout/ChartsLayout/HIVOutcomes/HIVOutcomesLayout1";
import HIVOutcomesLayout2 from "./components/Layout/ChartsLayout/HIVOutcomes/HIVOutcomesLayout2";
import HIVOutcomesLayout3 from "./components/Layout/ChartsLayout/HIVOutcomes/HIVOutcomesLayout3";
import HIVOutcomesLayout4 from "./components/Layout/ChartsLayout/HIVOutcomes/HIVOutcomesLayout4";
import HIVOutcomesLayout5 from "./components/Layout/ChartsLayout/HIVOutcomes/HIVOutcomesLayout5";
import HIVOutcomesLayout6 from "./components/Layout/ChartsLayout/HIVOutcomes/HIVOutcomesLayout6";
import HTSForecastLayout1 from "./components/Layout/ChartsLayout/HTSForecast/HTSForecastLayout1";
import Tx1 from "./components/Layout/ChartsLayout/MER/Tx1";
import Tx2 from "./components/Layout/ChartsLayout/MER/Tx2";
import Hts1 from "./components/Layout/ChartsLayout/MER/Hts1";
import Vl1 from "./components/Layout/ChartsLayout/MER/Vl1";
import ExpandedTBOutcomesLayout1 from "./components/Layout/ChartsLayout/ExpandedTB/ExpandedTBOutcomesLayout1";
import ExpandedTBOutcomesLayout2 from "./components/Layout/ChartsLayout/ExpandedTB/ExpandedTBOutcomesLayout2";
import ExpandedTBOutcomesLayout3 from "./components/Layout/ChartsLayout/ExpandedTB/ExpandedTBOutcomesLayout3";
import HTSMapsLayout1 from "./components/Layout/ChartsLayout/HTSMaps/HTSMapsLayout1";
import TBHIVLayout1 from "./components/Layout/ChartsLayout/TBHIV/TBHIVLayout1";
import IdleTimer from "./_helpers/IdleTimer";
import CountdownModal from "./_helpers/CountdownModal.js";
import TBLayout5 from "./components/Layout/ChartsLayout/TB/TBLayout5";
import TBOutcomesLayout2 from "./components/Layout/ChartsLayout/TBOutcomes/TBOutcomesLayout2";
import PrepReportLayout1 from "./components/Layout/ChartsLayout/Prep/PrepReportLayout1";
import PrepReportLayout2 from "./components/Layout/ChartsLayout/Prep/PrepReportLayout2";

//const getBasename = () => {return `/${process.env.PUBLIC_URL.split("/").pop()}`;};
function Routes() {
    const [isTimeout, setIsTimeout] = useState(false);
    const [idleTimer, setIdleTimer] = useState(new IdleTimer({ onTimeout: () => setIsTimeout(true) }));

    useEffect(() => {
        //Needed for Page Refresh
        if (localStorage.getItem("_timeoutStarted") === "true") {
            idleTimer.startInterval();
        }

        return () => idleTimer.clearTracker();
    }, []);

    return (
        <ConnectedRouter history={history}>
            <CountdownModal showModal={isTimeout}
                idleTimer={idleTimer}
                handleClose={() => { setIsTimeout(false); }}
                handleCancel={() => { idleTimer.startInterval(); }}
                handleTimeout={() => { history.push("/login"); }} />

            <Route path="/login" component={() => <Login startTimeout={() => { idleTimer.startInterval(); }} />} />
            <Route path="/forgotpassword" component={ForgotPassword} />
            <Route path="/resetpassword" component={ResetPassword} />

            <PrivateRoute exact path='/' component={Home} />
            <PrivateRoute path="/help" component={Help} />
            <PrivateRoute path="/registeruser" component={LayoutSecondary} />
            <PrivateRoute path="/registereduser" component={LayoutSecondary} />
            <PrivateRoute path="/manageusers" component={LayoutSecondary} />
            <PrivateRoute path="/mb" component={LayoutSecondary} />
            <PrivateRoute path="/charts/troa/1" component={TROALayout} />
            <PrivateRoute path="/charts/troa/2" component={TROALayout2} />
            <PrivateRoute path="/charts/troa/3" component={TROALayout3} />
            <PrivateRoute path="/charts/hts/1" component={HTSLayout1} />
            <PrivateRoute path="/charts/hts/2" component={HTSLayout2} />
            <PrivateRoute path="/charts/hts/3" component={HTSLayout3} />
            <PrivateRoute path="/predict/hts/yield" component={HTSYield} />
            <PrivateRoute path="/predict/hts/forecast" component={HTSForecast} />
            <PrivateRoute path="/userpinnedreports" component={LayoutSecondary} />
            <PrivateRoute path="/reports/:reportGroup?/:userId?" component={LayoutSecondary} />
            <PrivateRoute path="/reportgroup" component={LayoutSecondary} />
            <PrivateRoute path="/reportsource" component={LayoutSecondary} />
            <PrivateRoute path="/reportsourcesetting/:reportSource" component={LayoutSecondary} />
            <PrivateRoute path="/report" component={LayoutSecondary} />
            <PrivateRoute path="/systemsettings" component={LayoutSecondary} />
            <PrivateRoute path="/user/changepwd" component={LayoutSecondary} />
            <PrivateRoute path="/user/changepwdonlogin" component={ChangePwdOnLogin} />
            <PrivateRoute path="/charts/art/1" component={ARTInitiationLayout1} />
            <PrivateRoute path="/charts/art/2" component={ARTInitiationLayout2} />
            <PrivateRoute path="/charts/tld/1" component={TLDUptakeLayout1} />
            <PrivateRoute path="/charts/dedup/1" component={DedupReportLayout1} />
            <PrivateRoute path="/charts/ca/1" component={CALayout1} />
            <PrivateRoute path="/charts/ca/2" component={CALayout2} />
            <PrivateRoute path="/charts/ca/3" component={CALayout3} />
            <PrivateRoute path="/charts/tb/1" component={TBLayout1} />
            <PrivateRoute path="/charts/tb/2" component={TBLayout2} />
            <PrivateRoute path="/charts/tb/3" component={TBLayout3} />
            <PrivateRoute path="/charts/tb/4" component={TBLayout4} />
            <PrivateRoute path="/charts/tboutcomes/1" component={TBOutcomesLayout1} />
            <PrivateRoute path="/charts/dedup/2" component={DedupReportLayout2} />
            <PrivateRoute path="/charts/hivoutcomes/1" component={HIVOutcomesLayout1} />
            <PrivateRoute path="/charts/hivoutcomes/2" component={HIVOutcomesLayout2} />
            <PrivateRoute path="/charts/hivoutcomes/3" component={HIVOutcomesLayout3} />
            <PrivateRoute path="/charts/hivoutcomes/4" component={HIVOutcomesLayout4} />
            <PrivateRoute path="/charts/hivoutcomes/5" component={HIVOutcomesLayout5} />
            <PrivateRoute path="/charts/hivoutcomes/6" component={HIVOutcomesLayout6} />
            <PrivateRoute path="/charts/htsforecast/1" component={HTSForecastLayout1} />
            <PrivateRoute path="/charts/mer/tx/1" component={Tx1} />
            <PrivateRoute path="/charts/mer/tx/2" component={Tx2} />
            <PrivateRoute path="/charts/mer/hts/1" component={Hts1} />
            <PrivateRoute path="/charts/mer/vl/1" component={Vl1} />
            <PrivateRoute path="/charts/expanded_tb_outcomes/1" component={ExpandedTBOutcomesLayout1} />
            <PrivateRoute path="/charts/expanded_tb_outcomes/2" component={ExpandedTBOutcomesLayout2} />
            <PrivateRoute path="/charts/expanded_tb_outcomes/3" component={ExpandedTBOutcomesLayout3} />
            <PrivateRoute path="/charts/hts_maps/1" component={HTSMapsLayout1} />
            <PrivateRoute path="/charts/tbhiv/1" component={TBHIVLayout1} />
            <PrivateRoute path="/charts/tb/5" component={TBLayout5} />
            <PrivateRoute path="/charts/tboutcomes/2" component={TBOutcomesLayout2} />
            <PrivateRoute path="/charts/prep/1" component={PrepReportLayout1} />
            <PrivateRoute path="/charts/prep/2" component={PrepReportLayout2} />
            <AppResources />
        </ConnectedRouter>
    );
}
  
export default Routes;