import React, { useState } from 'react';
import { FormGroup } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { Grid } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { useStartDateContext, useEndDateContext, useHandleStartDateContext, useHandleEndDateContext } from './DateRangeFilterContext';

import { useDispatch } from 'react-redux';
import * as ACTION_TYPE from '../../../actions/types';

export const DateRangeFilter = (props) => {

    const dispatch = useDispatch();

    const [firstTimeLoad, setFirstTimeLoad] = useState(true);
    const minimumDate = props.minDate ? props.minDate : undefined;
    const maximumDate = props.maxDate ? props.maxDate : undefined;

    let startDate = useStartDateContext();
    let endDate = useEndDateContext();
    const handleStartDate = useHandleStartDateContext();
    const handleEndDate = useHandleEndDateContext();

    if (firstTimeLoad) {

        startDate = props.defaultStartDate ? props.defaultStartDate : null;
        endDate = props.defaultEndDate ? props.defaultEndDate : null;


        if (startDate) {
            handleStartDate(startDate);

            dispatch({
                type: ACTION_TYPE.REPORT_START_DATE,
                payload: startDate
            })
        }

        if (endDate) {
            handleEndDate(endDate);

            dispatch({
                type: ACTION_TYPE.REPORT_END_DATE,
                payload: endDate
            })
        }

        setFirstTimeLoad(false);
    }
    else {
        startDate = startDate ? startDate : props.defaultStartDate ? props.defaultStartDate : null;
        endDate = endDate ? endDate : props.defaultEndDate ? props.defaultEndDate : null;
    }

    return (
        <div className="container mb-1">
            <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <DatePicker
                            variant="inline"
                            format="dd-MMM-yyyy"
                            margin="normal"
                            id="start-date"
                            label="Start Date"
                            value={startDate}
                            onChange={handleStartDate}
                            className="w-100"
                            disableFuture
                            disableToolbar
                            maxDate={endDate}
                            minDate={minimumDate}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <DatePicker
                            variant="inline"
                            format="dd-MMM-yyyy"
                            margin="normal"
                            id="end-date"
                            label="End Date"
                            value={endDate}
                            onChange={handleEndDate}
                            className="w-100"
                            disableFuture
                            disableToolbar
                            minDate={startDate}
                            maxDate={maximumDate}
                        />
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        </div>
    );
}