import React, {useState} from "react";
import { useSelector } from "react-redux";
import MetabaseChartTemplate from "../../../Reports/Charts/MetabaseChartTemplate";
import ChartsDrawer from "../ChartsDrawer";
import ChartsContent from "../ChartsContent";
import { OrgUnitFilter } from "../../../Reports/Filters/OrgUnitFilter";
import { OrgUnitFilterContext } from "../../../Reports/Filters/OrgUnitFilterContext";
import { SubmitFilters } from "../../../Reports/Filters/SubmitFilters";
import Layout from "../../../Layout";
import PrepReportPages from "./PrepReportPages";
import { DateRangeFilter } from "../../../Reports/Filters/DateRangeFilter";
import { DateRangeFilterContext } from "../../../Reports/Filters/DateRangeFilterContext";

import { makeStyles } from '@material-ui/core/styles';
import { NotificationManager } from 'react-notifications';
import fileDownload from 'js-file-download';
import axios from 'axios';

import Fab from '@material-ui/core/Fab';
import CloudDownload from '@material-ui/icons/CloudDownload';

import { CircularProgress } from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
    progress: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    progressBar: {
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: "#02642e"
        },
        backgroundColor: "lightgray",
        
    },
    fab: {
        position:'fixed',
        bottom: theme.spacing(5),
        right: theme.spacing(2),
    },
    circularProgress : {
        zIndex: 1,
        position:'fixed',
        bottom: theme.spacing(4.5),
        right: theme.spacing(1.5),
        color: indigo[300]
    }
}));

export const PrepReportLayout1 = () => {
    
    const classes = useStyles();

    const defaultStartDate = new Date("2023-04-01");
    const defaultEndDate = new Date("2024-03-31");
    const minDate = new Date("2016-01-01");
    const maxDate = new Date("2024-04-31");

    const provinceName = useSelector(state => state.user.reportFilterProvinceName);
    const districtName = useSelector(state => state.user.reportFilterDistrictName);
    const subDistrictName = useSelector(state => state.user.reportFilterSubDistrictName);
    const facilityName = useSelector(state => state.user.reportFilterFacilityName);
    const startDate = useSelector(state => state.user.reportFilterStartDate);
    const endDate = useSelector(state => state.user.reportFilterEndDate);

    const [loading, setLoading] = useState(false);
    const [percentCompleted, setPercentCompleted] = useState(0);

    const generateExcelDoc = () => {
        setLoading(true);
        NotificationManager.success('Processing your request...', '', 3000);
        const fileName = facilityName != null ? facilityName : subDistrictName != null ? subDistrictName : districtName != null ? districtName : provinceName != null ? provinceName : 'National';

        axios.get('/api/prep/startPrep', {
            params: {
                province: provinceName,
                district: districtName,
                subDistrict: subDistrictName,
                facility: facilityName,
                startDate: startDate,
                endDate: endDate
            },
            responseType: 'blob',
            onDownloadProgress: function (progressEvent) {
                console.log('download', progressEvent);
                console.log('percent ', (progressEvent.loaded * 100) / progressEvent.total);
                setPercentCompleted(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            }
        }).then(res => {
            if (res.data.size > 0) {
                NotificationManager.success('Downloading...', '', 2000);
                fileDownload(res.data, fileName + ' - Started on PrEP.xlsx');
                setLoading(false);
                setPercentCompleted(0);
            }
            else {
                NotificationManager.success('PrEP Data Not Found', '', 2000);
                setLoading(false);
                setPercentCompleted(0);
            }
                      
        }).catch(function (error){
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            setLoading(false);
            setPercentCompleted(0);
        });;
    }

    return (
        <Layout>
            <div className="row" style={{ height: "85vh" }}>
                <ChartsDrawer>
                    <OrgUnitFilterContext>
                        <DateRangeFilterContext>
                            <OrgUnitFilter /> 
                            <DateRangeFilter
                                    defaultStartDate={defaultStartDate}
                                    defaultEndDate={defaultEndDate}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                />                   
                            <SubmitFilters />
                        </DateRangeFilterContext>
                    </OrgUnitFilterContext>
                    <hr />
                    <PrepReportPages />
                </ChartsDrawer>
                <ChartsContent>
                    <div className="chart-container">
                        <div className="row chart-row-100">
                            <div className="col-md-12">
                                <MetabaseChartTemplate resourceQuestion={170} title="Started on PrEP" />
                            </div>
                            <Fab color="primary" className={classes.fab} onClick={generateExcelDoc}>
                                <CloudDownload/> 
                            </Fab>
                            {loading && (
                                <CircularProgress size={64} className={classes.circularProgress}/>
                            )}
                        </div>
                    </div>
                </ChartsContent>
            </div>
        </Layout>
        )
}

export default PrepReportLayout1;