import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class TBReportPages extends Component {
    render() {
        return (
            <div className="container">
                <div className="row justify-content-end text-right">
                    <NavLink to="/charts/tb/1" activeClassName="activeNavLink" className="report-page">Overview</NavLink>
                </div> 
                <div className="row justify-content-end text-right">
                    <NavLink to="/charts/tb/2" activeClassName="activeNavLink" className="report-page">New TB Episodes</NavLink>
                </div> 
                <div className="row justify-content-end text-right">
                    <NavLink to="/charts/tb/3" activeClassName="activeNavLink" className="report-page">Relapse TB Episodes</NavLink>
                </div> 
                <div className="row justify-content-end text-right">
                    <NavLink to="/charts/tb/4" activeClassName="activeNavLink" className="report-page">Retreatment/Other TB Episodes</NavLink>
                </div>
                <div className="row justify-content-end text-right">
                    <NavLink to="/charts/tb/5" activeClassName="activeNavLink" className="report-page">TB/HIV Co-infection</NavLink>
                </div> 
            </div>
        )
    }
}

export default TBReportPages;