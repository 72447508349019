import axios from 'axios';

class IdleTimer {
    constructor({ onTimeout }) {
        this.onTimeout = onTimeout; //function to run on timeout
        this.eventHandler = this.updateExpiredTime.bind(this);
    }

    startInterval() {
        //timer is active or not
        axios.get('/api/systemsettings/Setting/TimerActive')
            .then(response => {
                this.activateTimer = response.data;

                if (this.activateTimer) {
                    //logout after X seconds idle
                    axios.get('/api/systemsettings/Setting/TimerPeriod')
                        .then(response => {
                            this.idlePeriod = Number(response.data);

                            //countdown seconds before logout
                            axios.get('/api/systemsettings/Setting/TimerCountdown')
                                .then(response => {
                                    this.countdownPeriod = Number(response.data);

                                    localStorage.setItem("_timeoutStarted", "true");
                                    this.updateExpiredTimeNoDelay();
                                    this.eventTracker();

                                    this.interval = setInterval(() => {
                                        const expiredTime = parseInt(localStorage.getItem("_timeoutTime") || 0, 10);
                                        const timeoutStarted = localStorage.getItem("_timeoutStarted");

                                        if (timeoutStarted === "true") {
                                            if (expiredTime < Date.now()) {
                                                if (this.onTimeout) {
                                                    this.onTimeout();
                                                    this.clearTracker();
                                                }
                                            }
                                        }
                                        else {
                                            this.clearTracker();
                                        }
                                    }, 1000);
                                });
                        });
                }
                else {
                    this.clearTracker();
                }
            });
    }

    updateExpiredTime() {
        if (this.timeoutTracker) {
            clearTimeout(this.timeoutTracker);
        }
        this.timeoutTracker = setTimeout(() => {
            localStorage.setItem("_timeoutTime", Date.now() + (this.idlePeriod - this.countdownPeriod) * 1000);
        }, 250);
    }

    updateExpiredTimeNoDelay() {
        localStorage.setItem("_timeoutTime", Date.now() + (this.idlePeriod - this.countdownPeriod) * 1000);
    }

    eventTracker() {
        window.addEventListener("mousemove", this.eventHandler);
        window.addEventListener("scroll", this.eventHandler);
        window.addEventListener("keydown", this.eventHandler);
    }

    clearTracker() {
        localStorage.removeItem("_expiredTime");
        clearInterval(this.interval);
        window.removeEventListener("mousemove", this.eventHandler);
        window.removeEventListener("scroll", this.eventHandler);
        window.removeEventListener("keydown", this.eventHandler);
    }
}
export default IdleTimer;
