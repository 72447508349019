import * as USERTYPES from '../actions/types'

var jwt = require("jsonwebtoken");

const initialState = {
    status: 0,
    userToken: null,
    userPinnedReports: [],
    userReportClaims: {},
    orgUnit: null,
    reportId: null,
    reportFilterProvinceId: '0',
    reportFilterDistrictId: '0',
    reportFilterSubDistrictId: '0',
    reportFilterFacilityId: '0',
    reportFilterProvinceName: null,
    reportFilterDistrictName: null,
    reportFilterSubDistrictName: null,
    reportFilterFacilityName: null,
    reportFilterProvince: {},
    reportFilterDistrict: {},
    reportFilterSubDistrict: {},
    reportFilterFacility: {},
    provinces: [],
    districts: [],
    subDistricts: [],
    facilities: [],
    selectedProvince: null,
    selectedDistrict: null,
    selectedSubDistrict: null,
    selectedFacility: null,
    reportFilterStartDate: null,
    reportFilterEndDate: null,
    reportFilterGender: [],
    reportFilterCohort: [],
    reportFilterYear: null,
    user: {},
    drawerOpen: true,
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {

        case USERTYPES.AUTHENTICATION:
            return { ...state, userToken: action.payload, user: jwt.decode(action.payload) }

        case USERTYPES.FETCH_USERS:
            return { ...state, user: action.payload }

        case USERTYPES.REGISTER_REQUEST:

            return { ...state, status: action.payload }

        case USERTYPES.FETCH_USER_BY_ID:
            return { ...state, user: action.payload }

        case USERTYPES.USERS_ERROR:
            return { ...state, errors: action.payload }

        case USERTYPES.PIN_UNPIN_SUCCESS:
            return { ...state, userPinnedReports: action.payload }

        case USERTYPES.PIN_UNPIN_FAILURE:
            return { ...state, userPinnedReports: action.payload }

        case USERTYPES.REPORT_FILTER_PROVINCE:
            return {
                ...state, reportFilterProvinceId: action.payload.id, reportFilterProvinceName: action.payload.name,
                reportFilterProvince: action.payload.province
            }

        case USERTYPES.REPORT_FILTER_DISTRICT:
            return {
                ...state, reportFilterDistrictId: action.payload.id, reportFilterDistrictName: action.payload.name,
                reportFilterDistrict: action.payload.district
            }

        case USERTYPES.REPORT_FILTER_SUB_DISTRICT:
            return {
                ...state, reportFilterSubDistrictId: action.payload.id, reportFilterSubDistrictName: action.payload.name,
                reportFilterSubDistrict: action.payload.subDistrict
            }

        case USERTYPES.REPORT_FILTER_FACILITY:
            return {
                ...state, reportFilterFacilityId: action.payload.id, reportFilterFacilityName: action.payload.name,
                reportFilterFacility: action.payload.facility
            }

        case USERTYPES.FETCH_PROVINCES:
            return { ...state, provinces: action.payload }

        case USERTYPES.FETCH_DISTRICTS:
            return { ...state, districts: action.payload }

        case USERTYPES.FETCH_SUB_DISTRICTS:
            return { ...state, subDistricts: action.payload }

        case USERTYPES.FETCH_FACILITIES:
            return { ...state, facilities: action.payload }

        case USERTYPES.CLEAR_DISTRICTS:
            return { ...state, districts: [] }

        case USERTYPES.CLEAR_SUB_DISTRICTS:
            return { ...state, subDistricts: [] }

        case USERTYPES.CLEAR_FACILITIES:
            return { ...state, facilities: [] }

        case USERTYPES.SELECT_PROVINCE:
            return { ...state, selectedProvince: action.payload }

        case USERTYPES.SELECT_DISTRICT:
            return { ...state, selectedDistrict: action.payload }

        case USERTYPES.SELECT_SUB_DISTRICT:
            return { ...state, selectedSubDistrict: action.payload }

        case USERTYPES.SELECT_FACILITY:
            return { ...state, selectedFacility: action.payload }

        case USERTYPES.CLEAR_SELECTED_PROVINCE:
            return { ...state, selectedProvince: null }

        case USERTYPES.CLEAR_SELECTED_DISTRICT:
            return { ...state, selectedDistrict: null }

        case USERTYPES.CLEAR_SELECTED_SUB_DISTRICT:
            return { ...state, selectedSubDistrict: null }

        case USERTYPES.CLEAR_SELECTED_FACILITY:
            return { ...state, selectedFacility: null }

        case USERTYPES.REPORT_START_DATE:
            return { ...state, reportFilterStartDate: action.payload }

        case USERTYPES.REPORT_END_DATE:
            return { ...state, reportFilterEndDate: action.payload }

        case USERTYPES.REPORT_GENDER:
            return { ...state, reportFilterGender: action.payload }

        case USERTYPES.REPORT_COHORT:
            return { ...state, reportFilterCohort: action.payload }

        case USERTYPES.REPORT_YEAR:
            return { ...state, reportFilterYear: action.payload }

        case USERTYPES.CLEAR_DYNAMIC_FILTERS:
            return { ...state, reportFilterStartDate: null, reportFilterEndDate: null, reportFilterGender: [], reportFilterCohort: [] , reportFilterYear: null}

        case USERTYPES.LOG_OUT:
            return initialState

        case USERTYPES.TOGGLE_DRAWER:
            return { ...state, drawerOpen: action.payload }

        case USERTYPES.REPORT_ID:
            return { ...state, reportId: action.payload }

        default:
            return state
    }
}
export default userReducer