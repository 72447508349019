import React, { Component } from 'react';
import MetabaseChartTemplate from '../../../Reports/Charts/MetabaseChartTemplate';
import Layout from '../../../Layout';
import DedupReportPages from './DedupReportPages';
import { OrgUnitFilterContext } from '../../../Reports/Filters/OrgUnitFilterContext';
import { SubmitFilters } from '../../../Reports/Filters/SubmitFilters';
import { OrgUnitFilter } from '../../../Reports/Filters/OrgUnitFilter';
import ChartsDrawer from '../ChartsDrawer';
import ChartsContent from '../ChartsContent';


class DedupReportLayout2 extends Component {

    state = {
        componentFilter: localStorage.getItem('componentFilter')
    }

    render() {
        return (
            <Layout>               
                <div className="row" style={{ height: "85vh" }}>
                    <ChartsDrawer>
                        <OrgUnitFilterContext>
                                    <OrgUnitFilter /> 
                                    <br />      
                                    <SubmitFilters />
                        </OrgUnitFilterContext>
                        <hr />
                        <DedupReportPages />
                    </ChartsDrawer>
                    <ChartsContent>
                        <div className="chart-container" style={{ height: "85vh" }} >
                            <div className="row" style={{ height: "85vh" }}>
                                    <div className="col-md-12">
                                        <MetabaseChartTemplate resourceQuestion={113} title="Facility Progress" />
                                    </div>
                            </div>
                        </div>
                    </ChartsContent>
                </div>
            </Layout>
        )
    }
}

export default DedupReportLayout2;