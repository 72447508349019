import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';

class HIVOutcomesReportPages extends Component {
    render() {
        return (
            <div className="container">
                <div className="row justify-content-end text-right">
                    <NavLink to="/charts/hivoutcomes/3" activeClassName="activeNavLink" className="report-page text-wrap">
                        Descriptive Characteristics of Patients on ART
                    </NavLink>
                </div>
                <div className="row justify-content-end text-right">
                    <NavLink to="/charts/hivoutcomes/1" activeClassName="activeNavLink" className="report-page text-wrap">
                        Retention Mortality &amp; LTFu (RML) Rates by Patient Characteristics
                    </NavLink>
                </div>
                <div className="row justify-content-end text-right">
                    <NavLink to="/charts/hivoutcomes/2" activeClassName="activeNavLink" className="report-page text-wrap">
                        Predictors of Attrition (Mortality &amp; LTFu)
                    </NavLink>
                </div>
                <div className="row justify-content-end text-right">
                    <NavLink to="/charts/hivoutcomes/4" activeClassName="activeNavLink" className="report-page text-wrap">
                        Descriptive Characteristics of Patients with a Viral Load Test after 12m on ART
                    </NavLink>
                </div>
                {/*
                <div className="row justify-content-end text-right">
                    <NavLink to="/charts/hivoutcomes/5" activeClassName="activeNavLink" className="report-page text-wrap">
                        12m Viral Suppression Rates by Patient Characteristics
                    </NavLink>
                </div>
                */}
                <div className="row justify-content-end text-right">
                    <NavLink to="/charts/hivoutcomes/6" activeClassName="activeNavLink" className="report-page text-wrap">
                        Predictors of Non-Suppression
                    </NavLink>
                </div>
            </div>
        )
    }
}

export default HIVOutcomesReportPages;