import React from 'react';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { Search } from '@material-ui/icons';
import { useGenderContext } from './GenderFilterContext';
import { useStartDateContext, useEndDateContext } from './DateRangeFilterContext';
import {
    useProvinceIdContext,
    useProvinceNameContext,
    useDistrictIdContext,
    useDistrictNameContext,
    useSubDistrictIdContext,
    useSubDistrictNameContext,
    useFacilityIdContext,
    useFacilityNameContext
} from './OrgUnitFilterContext';

import * as ACTION_TYPE from '../../../actions/types';
import { useCohortContext } from './CohortFilterContext';
import { useYearContext } from './YearFilterContext';


export const SubmitFilters = () => {

    const dispatch = useDispatch();

    const startDate = useStartDateContext();
    const endDate = useEndDateContext();
    const provId = useProvinceIdContext();
    const provName = useProvinceNameContext();
    const distId = useDistrictIdContext();
    const distName = useDistrictNameContext();
    const subDistId = useSubDistrictIdContext();
    const subDistName = useSubDistrictNameContext();
    const facId = useFacilityIdContext();
    const facName = useFacilityNameContext();
    const gender = useGenderContext();
    const cohort = useCohortContext();
    const year = useYearContext();


    const submit = () => {
        //console.log('cohorts in submit : ' + cohort);
        dispatch({
            type: ACTION_TYPE.REPORT_FILTER_PROVINCE,
            payload: { id: provId, name: provName }
        });

        dispatch({
            type: ACTION_TYPE.REPORT_FILTER_DISTRICT,
            payload: { id: distId, name: distName }
        });

        dispatch({
            type: ACTION_TYPE.REPORT_FILTER_SUB_DISTRICT,
            payload: { id: subDistId, name: subDistName }
        });

        dispatch({
            type: ACTION_TYPE.REPORT_FILTER_FACILITY,
            payload: { id: facId, name: facName }
        });

        dispatch({
            type: ACTION_TYPE.REPORT_GENDER,
            payload: gender
        })

        dispatch({
            type: ACTION_TYPE.REPORT_START_DATE,
            payload: startDate
        })

        dispatch({
            type: ACTION_TYPE.REPORT_END_DATE,
            payload: endDate
        })

        dispatch({
            type: ACTION_TYPE.REPORT_COHORT,
            payload: cohort
        })

        dispatch({
            type: ACTION_TYPE.REPORT_YEAR,
            payload: year
        })

    }
    

        return (
            <div className="container">
                <Button
                    className="btn-infohub w-100"
                    onClick={() => submit()}
                    ><Search/> Filter</Button>
            </div>
        )
    
}