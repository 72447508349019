import React, {useState, useEffect, useCallback} from 'react';
import { FormGroup } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import * as NAVIGATION_TYPES from '../../../actions/types';
import {
    useHandleProvinceIdContext,
    useHandleProvinceNameContext,
    useHandleDistrictIdContext,
    useHandleDistrictNameContext,
    useHandleSubDistrictIdContext,
    useHandleSubDistrictNameContext,
    useHandleFacilityIdContext,
    useHandleFacilityNameContext,
    useDistrictIdContext,
    useDistrictNameContext,
    useSubDistrictIdContext,
    useSubDistrictNameContext, useFacilityIdContext, useFacilityNameContext
} from './OrgUnitFilterContext';
import * as ACTION_TYPE from "../../../actions/types";

const jwt = require("jsonwebtoken");

export const OrgUnitFilter = (props) => {
    const dispatch = useDispatch();

    const handleProvinceIdChange = useHandleProvinceIdContext();
    const handleProvinceNameChange = useHandleProvinceNameContext();
    const handleDistrictIdChange = useHandleDistrictIdContext();
    const handleDistrictNameChange = useHandleDistrictNameContext();
    const handleSubDistrictIdChange = useHandleSubDistrictIdContext();
    const handleSubDistrictNameChange = useHandleSubDistrictNameContext();
    const handleFacilityIdChange = useHandleFacilityIdContext();
    const handleFacilityNameChange = useHandleFacilityNameContext();
    const subDistId = useSubDistrictIdContext();
    const subDistName = useSubDistrictNameContext();
    const facId = useFacilityIdContext();
    const facName = useFacilityNameContext();
    const distId = useDistrictIdContext();
    const distName = useDistrictNameContext();

    const userToken = useSelector(state => state.user.userToken);
    const provinceIdFilter = useSelector(state => state.user.reportFilterProvinceId);
    const districtIdFilter = useSelector(state => state.user.reportFilterDistrictId);
    const subDistrictIdFilter = useSelector(state => state.user.reportFilterSubDistrictId);
    const facilityIdFilter = useSelector(state => state.user.reportFilterFacilityId);
    const provinceNameFilter = useSelector(state => state.user.reportFilterProvinceName);
    const districtNameFilter = useSelector(state => state.user.reportFilterDistrictName);
    const subDistrictNameFilter = useSelector(state => state.user.reportFilterSubDistrictName);
    const facilityNameFilter = useSelector(state => state.user.reportFilterFacilityName);
    const provinces = useSelector(state => state.user.provinces);
    const districts = useSelector(state => state.user.districts);
    const subDistricts = useSelector(state => state.user.subDistricts);
    const facilities = useSelector(state => state.user.facilities);
    const selectedProvince = useSelector(state => state.user.selectedProvince);
    const selectedDistrict = useSelector(state => state.user.selectedDistrict);
    const selectedSubDistrict = useSelector(state => state.user.selectedSubDistrict);
    const selectedFacility = useSelector(state => state.user.selectedFacility);

    const [claimDataLoaded, setclaimDataLoaded] = useState(false);
    const [provinceId, setProvinceId] = useState(provinceIdFilter !== '0' ? provinceIdFilter : '0');
    const [districtId, setDistrictId] = useState(districtIdFilter !== '0' ? districtIdFilter : '0');
    const [subDistrictId, setSubDistrictId] = useState(subDistrictIdFilter !== '0' ? subDistrictIdFilter : '0');
    const [facilityId, setFacilityId] = useState(facilityIdFilter !== '0' ? facilityIdFilter : '0');
    const [provinceName, setProvinceName] = useState(provinceNameFilter !== null ? provinceNameFilter : null);
    const [districtName, setDistrictName] = useState(districtNameFilter !== null ? districtNameFilter : null);
    const [subDistrictName, setSubDistrictName] = useState(subDistrictNameFilter !== null ? subDistrictNameFilter : null);
    const [facilityName, setFacilityName] = useState(facilityNameFilter !== null ? facilityNameFilter : null);

    const [disableProvince, setDisableProvince] = useState(true);
    const [disableDistrict, setDisableDistrict] = useState(true);
    const [disableSubDistrict, setDisableSubDistrict] = useState(true);
    const [disableFacility, setDisableFacility] = useState(true);

    let data = '';

    const userOrgUnitClaimData = useCallback(async() => {

        let token = userToken;

        if (token !== null) {
            token = token.replace(/['"]+/g, '');
            data = jwt.decode(token);
        }

        await loadProvinces();
        await loadDistricts(provinceIdFilter !== '0' ? provinceIdFilter : data.userOrgUnitProvinceClaim);
        await loadSubDistricts(districtIdFilter !== '0' ? districtIdFilter : data.userOrgUnitDistrictClaim);
        await loadFacilities(subDistrictIdFilter !== '0' ? subDistrictIdFilter : data.userOrgUnitSubDistrictClaim);

        if (districtIdFilter && props.districtDisable) {
            handleDistrictIdChange('0');
            handleDistrictNameChange(null);
            setDistrictId('0');
            setDistrictName(null);
            dispatch({
                type: NAVIGATION_TYPES.CLEAR_SELECTED_DISTRICT,
                payload: null
            });
            dispatch({
                type: ACTION_TYPE.REPORT_FILTER_DISTRICT,
                payload: { id: distId, name: distName }
            });
        }

        if (subDistrictIdFilter && props.subDistrictDisable) {
            handleSubDistrictIdChange('0');
            handleSubDistrictNameChange(null);
            setSubDistrictId('0');
            setSubDistrictName(null);
            dispatch({
                type: NAVIGATION_TYPES.CLEAR_SELECTED_SUB_DISTRICT,
                payload: null
            });
            dispatch({
                type: ACTION_TYPE.REPORT_FILTER_SUB_DISTRICT,
                payload: { id: subDistId, name: subDistName }
            });
        }

        if (facilityIdFilter && props.facilityDisable) {
            handleFacilityIdChange('0');
            handleFacilityNameChange(null);
            setFacilityId('0');
            setFacilityName(null);
            dispatch({
                type: NAVIGATION_TYPES.CLEAR_SELECTED_FACILITY,
                payload: null
            });
            dispatch({
                type: ACTION_TYPE.REPORT_FILTER_FACILITY,
                payload: { id: facId, name: facName }
            });
        }

        if (data.provinceName !== "") {
            setProvinceName(data.provinceName);
            handleProvinceNameChange(data.provinceName);
            const province = provinces.filter(p => (p.name === data.provinceName))[0];
            dispatch({
                type: NAVIGATION_TYPES.REPORT_FILTER_PROVINCE,
                payload: { id: data.userOrgUnitProvinceClaim, name: data.provinceName, province: province }
            });

        }

        if (data.districtName !== "") {
            setDistrictName(data.districtName);
            handleDistrictNameChange(data.districtName);
            const district = districts.filter(d => (d.name === data.districtName))[0];
            dispatch({
                type: NAVIGATION_TYPES.REPORT_FILTER_DISTRICT,
                payload: { id: data.userOrgUnitDistrictClaim, name: data.districtName, district: district }
            });

        }

        if (data.subDistrictName !== "") {
            setSubDistrictName(data.subDistrictName);
            handleSubDistrictNameChange(data.subDistrictName);
            const subDistrict = subDistricts.filter(s => (s.name === data.subDistrictName))[0];
            dispatch({
                type: NAVIGATION_TYPES.REPORT_FILTER_SUB_DISTRICT,
                payload: { id: data.userOrgUnitSubDistrictClaim, name: data.subDistrictName, subDistrict: subDistrict }
            });

        }

        if (data.facilityName !== "") {
            setFacilityName(data.facilityName);
            handleFacilityNameChange(data.facilityName);
            const facility = facilities.filter(f => (f.name === data.facilityName))[0];
            dispatch({
                type: NAVIGATION_TYPES.REPORT_FILTER_FACILITY,
                payload: { id: data.userOrgUnitFacilityClaim, name: data.facilityName, facility: facility }
            });
        }

        if (data.userOrgUnitProvinceClaim === "0") {
            setDisableProvince(false);
        }

        if (data.userOrgUnitDistrictClaim === "0") {
            setDisableDistrict(false);
        }

        if (data.userOrgUnitSubDistrictClaim === "0") {
            setDisableSubDistrict(false);
        }

        if (data.userOrgUnitFacilityClaim === "0") {
            setDisableFacility(false);
        }

        setProvinceId(provinceIdFilter !== '0' ? provinceIdFilter : data.userOrgUnitProvinceClaim);
        handleProvinceIdChange(provinceIdFilter !== '0' ? provinceIdFilter : data.userOrgUnitProvinceClaim);

        setDistrictId(districtIdFilter !== '0' ? districtIdFilter : data.userOrgUnitDistrictClaim);
        handleDistrictIdChange(districtIdFilter !== '0' ? districtIdFilter : data.userOrgUnitDistrictClaim);

        setSubDistrictId(subDistrictIdFilter !== '0' ? subDistrictIdFilter : data.userOrgUnitSubDistrictClaim);
        handleSubDistrictIdChange(subDistrictIdFilter !== '0' ? subDistrictIdFilter : data.userOrgUnitSubDistrictClaim);

        setFacilityId(facilityIdFilter !== '0' ? facilityIdFilter : data.userOrgUnitFacilityClaim);
        handleFacilityIdChange(facilityIdFilter !== '0' ? facilityIdFilter : data.userOrgUnitFacilityClaim);
        setclaimDataLoaded(true);
    }, []);

    const loadProvinces = async () => {
        try {
            const response = await axios.get('/api/orgunit/provinces');
            dispatch({
                type: NAVIGATION_TYPES.FETCH_PROVINCES,
                payload: response.data
            });
            if (data.provinceName !== "") {
                const province = response.data.filter(p => (p.name === data.provinceName))[0];
                dispatch({
                    type: NAVIGATION_TYPES.SELECT_PROVINCE,
                    payload: province
                });
            }

            dispatch({
                type: NAVIGATION_TYPES.CLEAR_DISTRICTS,
                payload: null
            });
            dispatch({
                type: NAVIGATION_TYPES.CLEAR_SUB_DISTRICTS,
                payload: null
            });
            dispatch({
                type: NAVIGATION_TYPES.CLEAR_FACILITIES,
                payload: null
            });
        } catch (e) {

        }
    }

    const loadDistricts = async (provinceId) => {
        try {
            const response = await axios.get('/api/orgunit/districts/' + provinceId);
            dispatch({
                type: NAVIGATION_TYPES.FETCH_DISTRICTS,
                payload: response.data
            });
            if (data.districtName !== "") {
                const district = response.data.filter(d => (d.name === data.districtName))[0];
                dispatch({
                    type: NAVIGATION_TYPES.SELECT_DISTRICT,
                    payload: district
                });
            }

            dispatch({
                type: NAVIGATION_TYPES.CLEAR_SUB_DISTRICTS,
                payload: null
            });
            dispatch({
                type: NAVIGATION_TYPES.CLEAR_FACILITIES,
                payload: null
            });
        } catch (e) {

        }
    }

    const loadSubDistricts = async (districtId) => {
        try {
            const response = await axios.get('/api/orgunit/subdistricts/' + districtId);
            dispatch({
                type: NAVIGATION_TYPES.FETCH_SUB_DISTRICTS,
                payload: response.data
            })
            if (data.subDistrictName !== "") {
                const subDistrict = response.data.filter(s => (s.name === data.subDistrictName))[0];
                dispatch({
                    type: NAVIGATION_TYPES.SELECT_SUB_DISTRICT,
                    payload: subDistrict
                });
            }
            dispatch({
                type: NAVIGATION_TYPES.CLEAR_FACILITIES,
                payload: null
            });
        } catch (e) {

        }
    }

    const loadFacilities = async (subDistrictId) => {
        try {
            const response = await axios.get('/api/orgunit/facilities/' + subDistrictId);
            dispatch({
                type: NAVIGATION_TYPES.FETCH_FACILITIES,
                payload: response.data
            })
            if (data.facilityName !== "") {
                const facility = response.data.filter(f => (f.name === data.facilityName))[0];
                dispatch({
                    type: NAVIGATION_TYPES.SELECT_FACILITY,
                    payload: facility
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (!claimDataLoaded) {
            userOrgUnitClaimData();
        }

    }, [userOrgUnitClaimData]);

    return (
        <div className="container mt-1">
            <AvForm>
                <div className="row">
                    <div className="col-md-12">
                        <FormGroup className="pt-1">
                            <Autocomplete
                                id="province_select"
                                value={selectedProvince}
                                options={provinces}
                                getOptionLabel={option => option.name}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        dispatch({
                                            type: NAVIGATION_TYPES.SELECT_PROVINCE,
                                            payload: newValue
                                        });
                                        setProvinceId(newValue.organisationUnitID);
                                        loadDistricts(newValue.organisationUnitID);
                                        setProvinceName(newValue.name);
                                        setDistrictId('0');
                                        setDistrictName(null);
                                        setSubDistrictId('0');
                                        setSubDistrictName(null);
                                        setFacilityId('0');
                                        setFacilityName(null);

                                        handleProvinceIdChange(newValue.organisationUnitID);
                                        handleProvinceNameChange(newValue.name);
                                        handleDistrictIdChange('0');
                                        handleDistrictNameChange(null);
                                        handleSubDistrictIdChange('0');
                                        handleSubDistrictNameChange(null);
                                        handleFacilityIdChange('0');
                                        handleFacilityNameChange(null);

                                    }
                                    else {
                                        dispatch({
                                            type: NAVIGATION_TYPES.CLEAR_SELECTED_PROVINCE,
                                            payload: null
                                        });
                                        dispatch({
                                            type: NAVIGATION_TYPES.CLEAR_SELECTED_DISTRICT,
                                            payload: null
                                        });
                                        dispatch({
                                            type: NAVIGATION_TYPES.CLEAR_SELECTED_SUB_DISTRICT,
                                            payload: null
                                        });
                                        dispatch({
                                            type: NAVIGATION_TYPES.CLEAR_SELECTED_FACILITY,
                                            payload: null
                                        });
                                        setProvinceId('0');
                                        setProvinceName(null);
                                        setDistrictId('0');
                                        setDistrictName(null);
                                        setSubDistrictId('0');
                                        setSubDistrictName(null);
                                        setFacilityId('0');
                                        setFacilityName(null);

                                        handleProvinceIdChange('0');
                                        handleProvinceNameChange(null);
                                        handleDistrictIdChange('0');
                                        handleDistrictNameChange(null);
                                        handleSubDistrictIdChange('0');
                                        handleSubDistrictNameChange(null);
                                        handleFacilityIdChange('0');
                                        handleFacilityNameChange(null);
                                        dispatch({
                                            type: NAVIGATION_TYPES.CLEAR_DISTRICTS,
                                            payload: null
                                        });
                                        dispatch({
                                            type: NAVIGATION_TYPES.CLEAR_SUB_DISTRICTS,
                                            payload: null
                                        });
                                        dispatch({
                                            type: NAVIGATION_TYPES.CLEAR_FACILITIES,
                                            payload: null
                                        });
                                    }
                                }}
                                autoHighlight
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Province"
                                        variant="outlined"
                                    />
                                )}
                                disabled={props.provinceDisable ? true : disableProvince}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Autocomplete
                                id="district_select"
                                value={selectedDistrict}
                                options={districts}
                                getOptionLabel={option => option.name ? option.name : ''}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        dispatch({
                                            type: NAVIGATION_TYPES.SELECT_DISTRICT,
                                            payload: newValue
                                        });
                                        setDistrictId(newValue.organisationUnitID);
                                        loadSubDistricts(newValue.organisationUnitID);
                                        setDistrictName(newValue.name);
                                        setSubDistrictId('0');
                                        setSubDistrictName(null);
                                        setFacilityId('0');
                                        setFacilityName(null);

                                        handleDistrictIdChange(newValue.organisationUnitID);
                                        handleDistrictNameChange(newValue.name);
                                        handleSubDistrictIdChange('0');
                                        handleSubDistrictNameChange(null);
                                        handleFacilityIdChange('0');
                                        handleFacilityNameChange(null);

                                    }
                                    else {
                                        dispatch({
                                            type: NAVIGATION_TYPES.CLEAR_SELECTED_DISTRICT,
                                            payload: null
                                        });
                                        dispatch({
                                            type: NAVIGATION_TYPES.CLEAR_SELECTED_SUB_DISTRICT,
                                            payload: null
                                        });
                                        dispatch({
                                            type: NAVIGATION_TYPES.CLEAR_SELECTED_FACILITY,
                                            payload: null
                                        });
                                        setDistrictId('0');
                                        setDistrictName(null);
                                        setSubDistrictId('0');
                                        setSubDistrictName(null);
                                        setFacilityId('0');
                                        setFacilityName(null);

                                        handleDistrictIdChange('0');
                                        handleDistrictNameChange(null);
                                        handleSubDistrictIdChange('0');
                                        handleSubDistrictNameChange(null);
                                        handleFacilityIdChange('0');
                                        handleFacilityNameChange(null);
                                        dispatch({
                                            type: NAVIGATION_TYPES.CLEAR_SUB_DISTRICTS,
                                            payload: null
                                        });
                                        dispatch({
                                            type: NAVIGATION_TYPES.CLEAR_FACILITIES,
                                            payload: null
                                        });
                                    }
                                }}
                                autoHighlight
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="District"
                                        variant="outlined"
                                    />
                                )}
                                disabled={props.districtDisable? true : disableDistrict}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Autocomplete
                                id="subDistrict_select"
                                value={selectedSubDistrict}
                                options={subDistricts}
                                getOptionLabel={option => option.name ? option.name : ''}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        dispatch({
                                            type: NAVIGATION_TYPES.SELECT_SUB_DISTRICT,
                                            payload: newValue
                                        });
                                        setSubDistrictId(newValue.organisationUnitID);
                                        loadFacilities(newValue.organisationUnitID);
                                        setSubDistrictName(newValue.name)
                                        setFacilityId('0');
                                        setFacilityName(null);
                                        handleSubDistrictIdChange(newValue.organisationUnitID);
                                        handleSubDistrictNameChange(newValue.name);
                                        handleFacilityIdChange('0');
                                        handleFacilityNameChange(null);

                                    }
                                    else {
                                        dispatch({
                                            type: NAVIGATION_TYPES.CLEAR_SELECTED_SUB_DISTRICT,
                                            payload: null
                                        });
                                        dispatch({
                                            type: NAVIGATION_TYPES.CLEAR_SELECTED_FACILITY,
                                            payload: null
                                        });
                                        setSubDistrictId('0');
                                        setSubDistrictName(null);
                                        setFacilityId('0');
                                        setFacilityName(null);
                                        handleSubDistrictIdChange('0');
                                        handleSubDistrictNameChange(null);
                                        handleFacilityIdChange('0');
                                        handleFacilityNameChange(null);
                                        dispatch({
                                            type: NAVIGATION_TYPES.CLEAR_FACILITIES,
                                            payload: null
                                        });
                                    }
                                }}
                                autoHighlight
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Sub-District"
                                        variant="outlined"
                                    />
                                )}
                                disabled={props.subDistrictDisable ? true : disableSubDistrict}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Autocomplete
                                id="facility_select"
                                value={selectedFacility}
                                options={facilities}
                                getOptionLabel={option => option.name ? option.name : ''}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        dispatch({
                                            type: NAVIGATION_TYPES.SELECT_FACILITY,
                                            payload: newValue
                                        });
                                        setFacilityId(newValue.organisationUnitID);
                                        setFacilityName(newValue.name);
                                        handleFacilityIdChange(newValue.organisationUnitID);
                                        handleFacilityNameChange(newValue.name);
                                    }
                                    else {
                                        dispatch({
                                            type: NAVIGATION_TYPES.CLEAR_SELECTED_FACILITY,
                                            payload: null
                                        });
                                        setFacilityId('0');
                                        setFacilityName(null);
                                        handleFacilityIdChange('0');
                                        handleFacilityNameChange(null);
                                    }
                                }}
                                autoHighlight
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Facility"
                                        variant="outlined"
                                    />
                                )}
                                disabled={props.facilityDisable ? true : disableFacility}
                            />
                        </FormGroup>
                    </div>
                </div>
            </AvForm>
        </div>
    )
}
